import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { getEnvironmentURI } from "Libs/utils";

import Modal from "Components/Modal";
import { Link, ButtonWrapper } from "ds/Button";
import ContentPaneLayout from "Components/ContentPaneLayout";

const ErrorModal = ({
  organizationId,
  projectId,
  environmentId,
  subscriptionId,
  canEditPlan,
  project
}) => {
  const history = useHistory();

  const environmentURL = getEnvironmentURI(
    organizationId,
    projectId,
    environmentId
  );

  let billingURL = `/-/users/${organizationId}/billing/plan/${subscriptionId}`;
  const i18nPrefix = canEditPlan ? "admin" : "standard";
  const CUSTOM_SUBSCRIPTION_UPGRADE_URL =
    process.env.CUSTOM_SUBSCRIPTION_UPGRADE_URL;

  if (process.env.ENABLE_ORGANIZATION) {
    billingURL = `/${organizationId}/-/billing/plan/${subscriptionId}`;
  }

  if (
    CUSTOM_SUBSCRIPTION_UPGRADE_URL &&
    CUSTOM_SUBSCRIPTION_UPGRADE_URL.indexOf(":projectId") !== -1
  ) {
    billingURL = CUSTOM_SUBSCRIPTION_UPGRADE_URL.replace(
      ":projectId",
      projectId
    );
  } else if (CUSTOM_SUBSCRIPTION_UPGRADE_URL && project?.plan_uri) {
    billingURL = project?.plan_uri;
  }

  return (
    <Modal
      isOpen={true}
      title={<FormattedMessage id="environment.branch.error.title" />}
      modalClass="modal-small"
      closeModal={() => history.push(environmentURL)}
    >
      <ContentPaneLayout>
        <p>
          <FormattedMessage
            id={`environment.branch.error.${i18nPrefix}.description`}
          />
        </p>

        <ButtonWrapper spacing="modal" justifyContent="end">
          {canEditPlan ? (
            <>
              <Link variant="secondary" to={environmentURL}>
                <FormattedMessage id="cancel" />
              </Link>
              <Link
                variant="primary"
                to={billingURL}
                target={
                  process.env.CUSTOM_SUBSCRIPTION_UPGRADE_URL ? "_blank" : null
                }
              >
                <FormattedMessage id="project.plan.upgrade" />
              </Link>
            </>
          ) : (
            <Link variant="primary" to={environmentURL}>
              <FormattedMessage id="okay" />
            </Link>
          )}
        </ButtonWrapper>
      </ContentPaneLayout>
    </Modal>
  );
};

ErrorModal.propTypes = {
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  environmentId: PropTypes.string,
  subscriptionId: PropTypes.string,
  canEditPlan: PropTypes.bool,
  project: PropTypes.object
};

export default ErrorModal;
