import React from "react";
import PropTypes from "prop-types";

import ServiceMetrics from "./ServiceMetrics";
import useMetricsLink from "../../../../metrics/hooks/useMetricsLink";

const ServiceMetricsWrapper = ({ name }) => {
  const { type } = useMetricsLink();

  // Per service metrics are not available on dedicated environments
  if (type === "dedicated") return null;

  return <ServiceMetrics name={name} />;
};

ServiceMetricsWrapper.propTypes = {
  name: PropTypes.string
};

export default ServiceMetricsWrapper;
