import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { Map } from "immutable";

import logger from "Libs/logger";
import type Organization from "platformsh-client/types/model/Organization";

import { MapStoreStateType, StoreMapStateType } from "Reducers/types";

import { SelectorParams } from "./types";

export const getVouchers = createAsyncThunk(
  "app/vouchers",
  async ({ organization }: { organization: Organization }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const vouchers = await client.getOrganizationVouchers(organization.id);
    return vouchers;
  }
);

export const addVoucher = createAsyncThunk(
  "app/voucher/add",
  async (
    {
      organization,
      code
    }: {
      organization: Organization;
      code: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const voucher = await client.addOrganizationVoucher(
        organization.id,
        code
      );
      return voucher;
    } catch (err: any) {
      let error = err.title;
      if (err instanceof Error) {
        logger(err, {
          action: "addVoucher",
          meta: {
            organizationId: organization.id
          }
        });
        error = err.message;
      }
      return rejectWithValue(error);
    }
  }
);

const vouchers = createSlice({
  name: "vouchers",
  initialState: Map({
    data: Map()
  }) as StoreMapStateType,
  reducers: {
    initForm(state, { payload }) {
      return state
        .deleteIn(["status", payload.organizationId])
        .delete("errors");
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getVouchers.pending, (state, { meta }) => {
        const { organization } = meta.arg;
        return state
          .deleteIn(["errors", organization.name])
          .setIn(["loading", organization.name], true);
      })
      .addCase(getVouchers.fulfilled, (state, { meta, payload }) => {
        const { organization } = meta.arg;
        return state
          .setIn(["data", organization.name], payload.vouchers)
          .setIn(["loading", organization.name], false);
      })
      .addCase(getVouchers.rejected, (state, { error, meta }) => {
        const { organization } = meta.arg;
        return state
          .setIn(["errors", organization.name], error.message)
          .setIn(["loading", organization.name], false);
      });

    builder
      .addCase(addVoucher.pending, (state, { meta }) => {
        const { organization } = meta.arg;
        return state
          .deleteIn(["errors", "add", organization.name])
          .setIn(["status", organization.name], "pending")
          .setIn(["loading", "add", organization.name], true);
      })
      .addCase(addVoucher.fulfilled, (state, { meta }) => {
        const { organization } = meta.arg;
        return state
          .setIn(["status", organization.name], "added")
          .setIn(["loading", "add", organization.name], false);
      })
      .addCase(addVoucher.rejected, (state, { meta, payload }) => {
        const { organization } = meta.arg;
        return state
          .setIn(["errors", "add", organization.name], payload)
          .setIn(["status", organization.name], "rejected")
          .setIn(["loading", "add", organization.name], false);
      });
  }
});

export const { initForm } = vouchers.actions;
export default vouchers.reducer;

const selectSelf = (state: MapStoreStateType) => {
  return state.voucher;
};

const getParams = (_: unknown, params: SelectorParams) => params;

// Get vouchers
export const vouchersSelector = createSelector(
  selectSelf,
  getParams,
  (voucher, { organizationId }) => voucher?.getIn(["data", organizationId])
);
export const vouchersLoadingSelector = createSelector(
  selectSelf,
  getParams,
  (voucher, { organizationId }) => voucher?.getIn(["loading", organizationId])
);
export const vouchersErrorsSelector = createSelector(
  selectSelf,
  getParams,
  (voucher, { organizationId }) => voucher?.getIn(["errors", organizationId])
);

// Add voucher
export const voucherLoadingSelector = createSelector(
  selectSelf,
  getParams,
  (voucher, { organizationId }) =>
    voucher?.getIn(["loading", "add", organizationId], false)
);
export const voucherErrorsSelector = createSelector(
  selectSelf,
  getParams,
  (voucher, { organizationId }) =>
    voucher?.getIn(["errors", "add", organizationId])
);
export const voucherStatusSelector = createSelector(
  selectSelf,
  getParams,
  (voucher, { organizationId }) => voucher?.getIn(["status", organizationId])
);
