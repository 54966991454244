import styled from "styled-components";
import { css, SECTION, TAG, HEADER, LINK } from "Libs/themes";

export const ModalBody = styled.div`
  * {
    -webkit-font-smoothing: antialiased;
  }
  margin-bottom: 48px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));

  .project-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 8px 0;
    color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  }

  .organization-name,
  .region-name,
  .region-code {
    font-weight: 600;
    font-size: 14px;
    margin-right: 8px;
  }
  .project-info {
    margin-bottom: 10px;
  }

  .region-code {
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
  }
  .deployment {
    display: flex;
    gap: 8px;
    align-items: center;
    .deployment-link {
      font-weight: 600;
      font-size: 14px;
      color:var(--link-skye-color,var(--link-skye,var(--skye)));
    }
  }
  .caution {
    gap: 8px;
    display: flex;
    .caution-text {
      margin-top: 0;
      font-weight: 600;
      font-size: 13px;
      color:var(--section-slate-color,var(--section-slate,var(--slate)));
      line-height: 19px;
    }
  }

  .confirm-delete-input {
    margin-top: 25px;
  }
`;

export const LiveTag = styled.span`
  padding: 1px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color:var(--tag-skye-color,var(--tag-skye,var(--skye)));
  background-color:var(--tag-skye-light-background-color,var(--tag-skye-light,var(--skye-light)));
  border-radius: 2px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
