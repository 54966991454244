import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import useDecodedParams from "Hooks/useDecodedParams";
import { List } from "immutable";
// import logger from "Libs/logger";

import { withReducers } from "Hocs";
import SubNavBarContainer from "Containers/SubNavBarContainer";

import {
  getIsLoadingProjects,
  gitProjectSelector,
  loadProject
} from "Reducers/project";
import { meSelector } from "Reducers/app";
import { loadProjectCapability } from "Reducers/project/capabilities";
import { loadEnvironment } from "Reducers/environment";
import { ADMIN_ROLES } from "Constants/constants";

import ErrorBoundary from "Components/ErrorBoundary";
import PageLayout from "Components/PageLayout";
import NavBar from "Containers/NavBar";
import ProjectError from "./containers/ProjectError";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Project = ({ children, disableSubNavBar, nonResponsiveSubNavBar }) => {
  const dispatch = useDispatch();
  const { projectId, organizationId, environmentId } = useDecodedParams();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const disableFooter =
    pathname.startsWith(`/${organizationId}/${projectId}/-/settings`) ||
    pathname.startsWith(
      `/${organizationId}/${projectId}/${environmentId}/settings`
    );

  const project = useSelector(state =>
    gitProjectSelector(state, {
      organizationId,
      projectId
    })
  );

  const me = useSelector(meSelector);

  useEffect(() => {
    dispatch(loadProject(projectId));
  }, [projectId]);

  useEffect(() => {
    if (environmentId) {
      dispatch(loadEnvironment({ environmentId, projectId, organizationId }));
    }
  }, [environmentId]);

  const isLoadingProjects = useSelector(state => getIsLoadingProjects(state));

  useEffect(() => {
    project && dispatch(loadProjectCapability({ organizationId, projectId }));
  }, [project, isLoadingProjects]);

  return (
    <ProjectError projectId={projectId} organizationId={organizationId}>
      <NavBar
        push={push}
        projectId={projectId}
        organizationId={organizationId}
        environmentId={environmentId}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout className="project" footer={!disableFooter}>
        {disableSubNavBar &&
        !me
          .get("roles", new List())
          ?.toArray()
          .some(role => ADMIN_ROLES.includes(role)) ? null : (
          <SubNavBarContainer
            currentPathName={pathname}
            organizationId={organizationId}
            environmentId={environmentId}
            projectId={projectId}
            responsive={!nonResponsiveSubNavBar}
          />
        )}
        <ErrorBoundary>
          <Layout>{children}</Layout>
        </ErrorBoundary>
      </PageLayout>
    </ProjectError>
  );
};

Project.propTypes = {
  children: PropTypes.node.isRequired,
  disableSubNavBar: PropTypes.bool,
  nonResponsiveSubNavBar: PropTypes.bool
};

export default withReducers({
  project: () => import("Reducers/project"),
  invitation: () => import("Reducers/invitation"),
  environment: () => import("Reducers/environment"),
  deployment: () => import("Reducers/environment/deployment"),
  service: () => import("Reducers/environment/service"),
  activity: () => import("Reducers/activity"),
  region: () => import("Reducers/project/region"),
  plan: () => import("Reducers/plan"),
  projectWizard: () => import("Reducers/project/wizard"),
  subscription: () => import("Reducers/subscription"),
  organizationSubscription: () => import("Reducers/organization/subscription"),
  log: () => import("Reducers/log"),
  capabilities: () => import("Reducers/project/capabilities")
})(Project);
