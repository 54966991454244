import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import ProjectCertificateListField from "./components/ProjectCertificateListField";
import ContentLayout from "Components/ContentLayout";

import { withReducers } from "Hocs";

const ProjectSettingsVariable = () => {
  const { organizationId, projectId } = useParams();

  return (
    <ContentLayout className="settings">
      <ProjectCertificateListField
        projectId={projectId}
        organizationId={organizationId}
      />
    </ContentLayout>
  );
};

ProjectSettingsVariable.propTypes = {
  projectId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired
};

export default withReducers({
  projectCertificate: () => import("Reducers/project/settings/certificate")
})(ProjectSettingsVariable);
