import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { environmentMenuSelector } from "Reducers/environment";
import useDecodedParams from "Hooks/useDecodedParams";
import { withReducers } from "Hocs";
import TreeContainer from "./EnvironmentTree.styles";

const EnvironmentTree = () => {
  const { organizationId, projectId } = useDecodedParams();

  const envTree = useSelector(state =>
    environmentMenuSelector(state, { organizationId, projectId })
  );

  return envTree.length ? <Tree data={envTree} /> : null;
};

function Tree({ data }) {
  return (
    <TreeContainer>
      <ul>
        {data.map(el => (
          <Node key={el.id} element={el} />
        ))}
      </ul>
    </TreeContainer>
  );
}

Tree.propTypes = {
  data: PropTypes.array
};

function Node({ element }) {
  const { organizationId, projectId } = useDecodedParams();
  const { pathname } = useLocation();
  const encodedEnvironmentId = encodeURIComponent(element.id);
  const link = `/${organizationId}/${projectId}/${encodedEnvironmentId}/settings`;

  const isActive = pathname.startsWith(link);

  if (!element.children) {
    return (
      <li>
        <Link className={isActive ? "active" : undefined} to={link}>
          {element.id}
        </Link>
      </li>
    );
  }

  return (
    <li>
      <Link className={isActive ? "active" : undefined} to={link}>
        {element.id}
      </Link>
      <ul>
        {element.children.map(child => (
          <Node key={child.id} element={child} />
        ))}
      </ul>
    </li>
  );
}

Node.propTypes = {
  element: PropTypes.object
};

export default withReducers({
  environment: () => import("Reducers/environment")
})(EnvironmentTree);
