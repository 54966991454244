import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import ProjectDeployKey from "./containers/ProjectDeployKey";
import ContentLayout from "Components/ContentLayout";

import { withReducers } from "Hocs";

const ProjectSettingsDeployKey = () => {
  const { organizationId, projectId } = useParams();
  return (
    <ContentLayout className="settings">
      <ProjectDeployKey organizationId={organizationId} projectId={projectId} />
    </ContentLayout>
  );
};

ProjectSettingsDeployKey.propTypes = {
  projectId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired
};

export default withReducers({
  project: () => import("Reducers/project")
})(ProjectSettingsDeployKey);
