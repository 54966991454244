import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Map } from "immutable";

import logger from "Libs/logger";
import { MapStoreStateType } from "Reducers/types";

type RedeployEnvironmentActionPropType = {
  organizationId: string;
  projectId: string;
  environmentId: string;
};

export const redeploy = createAsyncThunk<
  any,
  RedeployEnvironmentActionPropType
>(
  "app/environment/actions/redeploy",
  async (
    { organizationId, projectId, environmentId },
    { getState, rejectWithValue }
  ) => {
    try {
      const environment = (getState() as MapStoreStateType).environment.getIn(
        ["data", organizationId, projectId, environmentId],
        {}
      );

      const redeploy = await environment.redeploy();
      return redeploy;
    } catch (error: any) {
      if (![404, 403].includes(error?.code)) {
        logger(error, {
          action: "redeploy",
          meta: {
            organizationId,
            environmentId,
            projectId
          }
        });
      }
      return rejectWithValue({ ...error, message: error.message });
    }
  }
);

const redeploySlice = createSlice({
  name: "redeploy",
  initialState: Map(),
  reducers: {
    clearRedeploy: state =>
      state.set("isLoading", false).remove("error").remove("data")
  },
  extraReducers: builder =>
    builder
      .addCase(redeploy.pending, state => state.set("isLoading", true))

      .addCase(redeploy.fulfilled, (state, action) =>
        state
          .set("isLoading", false)
          .remove("error")
          .set("data", action.payload)
      )
      .addCase(redeploy.rejected, (state, action) =>
        state.set("isLoading", false).set("error", action.payload)
      )
});

export const { clearRedeploy } = redeploySlice.actions;

export default redeploySlice.reducer;
