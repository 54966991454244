import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Button, ButtonWrapper } from "ds/Button";
import InputField from "Components/fields/InputField";
import Loading from "Components/Loading";

import Pane from "../../../../components/Pane";
import { QRCode, QRTitle } from "./styles";

const SetupTFA = ({ secret, qrCode, onVerify, isLoading, error }) => {
  const [verificationCode, setVerificationCode] = useState("");

  const intl = useIntl();
  const history = useHistory();

  const onSubmit = event => {
    event.preventDefault();
    onVerify(verificationCode);
  };

  const onReset = event => {
    event.preventDefault();
    setVerificationCode("");
    history.push("../security");
  };

  return (
    <Pane
      header={
        <strong>
          <FormattedMessage id="security.setup.header" />
        </strong>
      }
    >
      <InputField
        label={<FormattedMessage id="security.setup.textcode" />}
        value={secret}
        required={false}
        onChange={() => undefined}
        id="textcode"
      />
      <p>
        <FormattedMessage id="security.setup.help" />
      </p>
      <QRTitle>QR Code</QRTitle>
      {isLoading ? <Loading iconOnly /> : <QRCode src={qrCode} alt="QR Code" />}
      <form onSubmit={onSubmit} onReset={onReset}>
        <InputField
          label={intl.formatMessage({ id: "security.setup.verificationcode" })}
          placeholder={intl.formatMessage({
            id: "security.setup.verificationcode.placeholder"
          })}
          value={verificationCode}
          onChange={event => setVerificationCode(event.target.value)}
          required={true}
          isDisabled={isLoading}
          error={error}
          id="verification-code"
        />
        <ButtonWrapper spacing="modal">
          <Button disabled={isLoading} type="submit">
            <FormattedMessage id="security.setup.submit" />
          </Button>
          <Button disabled={isLoading} type="reset" variant="secondary">
            <FormattedMessage id="security.setup.reset" />
          </Button>
        </ButtonWrapper>
      </form>
    </Pane>
  );
};

SetupTFA.propTypes = {
  secret: PropTypes.string,
  qrCode: PropTypes.string,
  onVerify: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string
};

export default SetupTFA;
