import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, getCSSVarString } from "Libs/themes";

import UnstyledHeading2 from "Components/styleguide/Heading2";

export const Wrapper = styled.div``;

export const Header = styled.div``;

export const Heading = styled(UnstyledHeading2)`
  margin-bottom: 16px;
`;

export const Intro = styled.p`
  margin: 0 0 36px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const Headers = styled.div`
  display: flex;
  margin-bottom: 12px;
  padding: 0 52px 0 36px;
`;

export const HeaderItem = styled.div`
  flex: 1;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 16px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const Preview = styled.div`
  display: flex;
  padding: 0 0 0 12px;
`;

export const Data = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
`;

export const Code = styled(Data)`
  ${semiBoldAlias};
`;

const Status = styled.span`
  display: inline-block;
  padding: 1px 8px 3px 8px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
  ${semiBoldAlias};
`;

export const Redeemed = styled(Status)`
  background: #b2ebf2;
  color:var(--section-java-color,var(--section-java,var(--java)));
`;
export const Completed = styled(Status)`
  background-color:var(--section-grey-background-color,var(--section-grey,var(--grey)));
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const Orders = styled.div`
  padding: 28px 4px 32px 4px;
`;

export const Order = styled.div`
  border-top: 1px solid var(--section-stone-border-color,var(--section-stone,var(--stone)));
  display: flex;
  margin: 0 32px;
  padding: 16px 19px 16px 4px;
`;
