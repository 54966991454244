import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import Tabs from "./tabs";

const EnvironmentSettingsLayout = styled.div`
  width: 100%;
  // We do this in order to push the app version footer to the bottom
  min-height: calc(100vh - 172px);
  background: transparent;
  > div {
    background: transparent;
  }
`;

const EnvironmentSettings = ({ children, entries = [] }) => {
  const { organizationId, projectId, environmentId } = useDecodedParams();
  const { pathname } = useLocation();

  const tabs = useMemo(() => {
    const envId = encodeURIComponent(environmentId);
    const basePath = `/${organizationId}/${projectId}/${envId}`;
    const settingsPath = `${basePath}/settings`;

    return [
      {
        name: "general",
        route: settingsPath,
        permission: "#edit"
      },
      {
        name: "access",
        route: `${settingsPath}/access`,
        permission: "#manage-access"
      },
      {
        name: "variables",
        route: `${settingsPath}/variables`,
        permission: "#manage-variables"
      }
    ].filter(elt => entries.includes(elt.name));
  }, [organizationId, projectId, environmentId, entries]);

  return (
    <EnvironmentSettingsLayout className="settings-content">
      <Tabs currentPathName={pathname} tabs={tabs} />
      {children}
    </EnvironmentSettingsLayout>
  );
};

EnvironmentSettings.propTypes = {
  children: PropTypes.node,
  entries: PropTypes.array
};

export default EnvironmentSettings;
