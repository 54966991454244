import React from "react";
import { useIntl } from "react-intl";

import { EmptyVouchers as Illustration } from "Components/illustrations";
import Form from "../Form";

import * as S from "./EmptyVouchers.styles";

function EmptyVouchers() {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <Illustration style={{ width: "80px", height: "80px" }} />
      <S.Heading>{intl.formatMessage({ id: "voucher.empty.title" })}</S.Heading>
      <S.Intro>
        {intl.formatMessage(
          { id: "voucher.empty.intro" },
          {
            br: <br />
          }
        )}
      </S.Intro>
      <Form cancelable={false} reloadList={true} />
    </S.Wrapper>
  );
}

export default EmptyVouchers;
