import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";
import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import BillingListField from "../../containers/BillingListField";
import Layout from "../../components/Layout";

import { withReducers } from "Hocs";

const Page = ({ titleFilter }) => {
  const { organizationId } = useDecodedParams();

  return (
    <Layout>
      <ContentLayout id="settings-billing" className="settings-content">
        <Heading2>
          <FormattedMessage id="invoices" />
        </Heading2>
        <BillingListField
          organizationId={organizationId}
          titleFilter={titleFilter}
        />
      </ContentLayout>
    </Layout>
  );
};

Page.propTypes = {
  titleFilter: PropTypes.string
};

export default withReducers({
  project: () => import("Reducers/project"),
  organizationOrder: () => import("Reducers/organization/order")
})(Page);
