import React from "react";
import { useParams } from "react-router-dom";

import OrganizationBillingOrderDetails from "./containers/OrganizationBillingOrderDetails";

import { withReducers } from "Hocs";

const Page = () => {
  const { organizationId, orderId } = useParams();

  return (
    <OrganizationBillingOrderDetails params={{ organizationId, orderId }} />
  );
};

export default withReducers({
  project: () => import("Reducers/project"),
  organizationOrder: () => import("Reducers/organization/order"),
  organizationSubscription: () => import("Reducers/organization/subscription")
})(Page);
