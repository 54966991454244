import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { push } from "Reducers/app";
import logger from "Libs/logger";
import { MapStoreStateType } from "Reducers/types";

type MergeEnvironmentActionPropType = {
  organizationId: string;
  projectId: string;
  environmentId: string;
};

export const merge = createAsyncThunk<any, MergeEnvironmentActionPropType>(
  "Environment/Merge",
  async (
    { organizationId, projectId, environmentId },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const environment = (getState() as MapStoreStateType).environment.getIn(
        ["data", organizationId, projectId, environmentId],
        {}
      );
      const merge = await environment.merge();
      dispatch(
        push({
          path: "/:organizationDescriptionId/:projectDescriptionId/:environmentDescriptionId",
          args: {
            organizationDescriptionId: organizationId,
            projectDescriptionId: projectId,
            environmentDescriptionId: environmentId
          }
        })
      );
      return merge;
    } catch (err: any) {
      if (![404, 403].includes(err.code))
        logger(err, {
          action: "merge",
          payload: merge,
          meta: {
            organizationId,
            projectId,
            environmentId
          }
        });
      return rejectWithValue({ ...err, message: err.message });
    }
  }
);

const environmentBranchSlice = createSlice({
  name: "merge",
  initialState: Map(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(merge.pending, state =>
        state.set("loading", true).remove("errors")
      )
      .addCase(merge.fulfilled, (state, { meta, payload }) =>
        state
          .set("loading", false)
          .remove("errors")
          .setIn(
            [
              "data",
              meta.arg.organizationId,
              meta.arg.projectId,
              meta.arg.environmentId
            ],
            fromJS(payload)
          )
      )
      .addCase(merge.rejected, (state, { payload }) =>
        state.set("loading", false).set("errors", fromJS(payload))
      );
  }
});

export default environmentBranchSlice.reducer;
