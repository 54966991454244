import React from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";

import { withReducers } from "Hocs";

import PageMeta from "Components/PageMeta";
import ErrorBoundary from "Components/ErrorBoundary";
import PageLayout from "Components/PageLayout";

import NavBar from "Containers/NavBar";
import { getUrls } from "Libs/utils";
import * as S from "./Page.style";

const OrganizationSettings = ({ children }) => {
  const { organizationId } = useDecodedParams();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const intl = useIntl();

  return (
    <>
      <PageMeta title={intl.formatMessage({ id: "create_organization" })} />
      <NavBar
        insideNavConfig={[
          {
            onClick: e => e.preventDefault(),
            name: intl.formatMessage({ id: "settings" }),
            route: getUrls().createOrganizationUrl
          },
          {
            tooltip: intl.formatMessage({
              id: "organization.create.prompt.tooltip-message"
            }),
            onClick: e => e.preventDefault(),
            name: "users",
            route: `/-/settings/users`
          }
        ]}
        push={push}
        organizationId={organizationId}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout className="create-organization" footer={false}>
        <ErrorBoundary>
          <S.CreateOrganizationPageLayout>
            {children}
          </S.CreateOrganizationPageLayout>
        </ErrorBoundary>
      </PageLayout>
    </>
  );
};

OrganizationSettings.propTypes = {
  children: PropTypes.node.isRequired
};

export default withReducers({
  organization: () => import("Reducers/organization"),
  organizationProfile: () => import("Reducers/organization/profile")
})(OrganizationSettings);
