import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Map } from "immutable";

import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import { withReducers } from "Hocs";

import {
  deleteSubscription,
  loadSubscription,
  updateSubscription
} from "Reducers/subscription";
import { loadEnvironments, environmentsSelector } from "Reducers/environment";
import { getOptions } from "Reducers/project/setup";

import { PLAN_TYPES } from "Constants/constants";

import { hasSafeRole, interpolateURL, getTrial } from "Libs/utils";
import client from "Libs/platform";

import LoadingIcon from "Icons/LoadingIcon";
import Dropdown from "Components/Dropdown";
import Button from "ds/Button";
import PlanInfo from "./PlanInfo";
import Description from "Components/fields/Description";
import InfoDialog from "Components/InfoDialog";
import Error from "Components/Error";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import ModalTrial from "Components/ModalTrial";
import Redirect from "Components/Redirect";

import * as S from "./styles";

const GIG = 1024;
const storageSuffix = "GB";

const bigDevMap = {
  "": "Standard",
  PLATFORM_FEATURE_M_DEV: "Medium",
  PLATFORM_FEATURE_L_DEV: "Large",
  PLATFORM_FEATURE_XL_DEV: "Xlarge",
  PLATFORM_FEATURE_2XL_DEV: "2Xlarge",
  PLATFORM_FEATURE_4XL_DEV: "4Xlarge"
};

const generateDropdownScruct = valuesMap =>
  Object.entries(valuesMap).map(([key, value]) => ({
    value: key,
    label: value
  }));

const ProjectPlanForm = ({ hasPaymentSource, username, projectId, user }) => {
  const [modalDeleteOpen, openModalDelete] = useState(false);
  const [modalTrialOpen, openModalTrial] = useState(false);
  const [isChanged, setIschanged] = useState(false);
  const [resetFormKey, setResetFormKey] = useState(new Date().getTime());
  const [initEstimate, setInitEstimate] = useState();
  const [estimate, setEstimate] = useState();
  const [estimateSub, setEstimateSub] = useState();

  const intl = useIntl();
  const dispatch = useDispatch();

  const project = useSelector(state =>
    state.project?.getIn(["data", username, projectId])
  );
  const subscription = useSelector(state =>
    state.subscription?.getIn(["data", username, projectId])
  );
  const loading = useSelector(
    state => state.subscription?.get("loading"),
    () => false
  );
  const error = useSelector(state => {
    const error = state.subscription?.get("error");
    return error?.title || error?.message;
  });
  const deletedSubscription = useSelector(state =>
    state.subscription?.get("deleted")
  );
  const environments = useSelectorWithUrlParams(environmentsSelector, {
    organizationId: username,
    projectId
  });
  const organizations = useSelector(state =>
    state.organization?.get("data", new Map())
  );
  const availablePlansForSubscription =
    subscription?.data?.project_options?.plans;

  const planTypes = useMemo(() => {
    if (availablePlansForSubscription?.length) {
      const plansFilteredForSubscription = Object.entries(PLAN_TYPES).reduce(
        (plans, [key, value]) => {
          const plan = key === "trial/development" ? "development" : key;
          // Filter out plan types which are not available plans.
          if (availablePlansForSubscription.indexOf(key) !== -1) {
            plans[plan] = value;
          }
          return plans;
        },
        {}
      );
      return generateDropdownScruct(plansFilteredForSubscription);
    }
  }, [availablePlansForSubscription?.length]);
  const bigDevTypes = useMemo(() => generateDropdownScruct(bigDevMap), []);

  const initEstimateSub = () => {
    const { plan, environments, storage, user_licenses } = subscription;
    setEstimateSub({
      plan,
      environments,
      storage,
      user_licenses
    });
  };

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  useEffect(() => {
    if (projectId && username)
      dispatch(loadEnvironments({ projectId, organizationId: username }));
  }, [projectId, username]);

  useEffect(() => {
    const subId = project?.getSubscriptionId();
    if (subId) dispatch(loadSubscription({ username, projectId, id: subId }));
  }, [username, projectId]);

  useEffect(() => {
    if (subscription?.id) initEstimateSub();
  }, [subscription?.id]);

  useEffect(() => {
    let isCanceled = false;
    const getEstimation = async () => {
      const params = {
        plan: estimateSub.plan,
        storage: estimateSub.storage,
        environments: estimateSub.environments,
        user_licenses: estimateSub.user_licenses,
        big_dev: estimateSub.big_dev
      };
      const estimation = await client.getSubscriptionEstimate(params);
      if (isCanceled) {
        return;
      }
      setEstimate(estimation);
      if (!initEstimate) setInitEstimate(estimation);
    };
    if (estimateSub) getEstimation();

    return () => (isCanceled = true);
  }, [estimateSub]);

  const environmentOptions = () => {
    const defaultBranch = project?.default_branch || "master";
    if (environments?.size > 0) {
      const actveEnvs = environments.filter(env => {
        if (env.id === defaultBranch) {
          return false;
        }
        return env.status === "active";
      }).size;
      const totalRoundDown = actveEnvs - (actveEnvs % 3);
      const totalRoundUp = actveEnvs + 3 - (actveEnvs % 3);
      const numberOfEnvironmentsFloor =
        totalRoundDown < actveEnvs ? totalRoundUp : totalRoundDown;
      let list = [];

      for (let i = numberOfEnvironmentsFloor; i <= 21; i += 3) {
        if (i !== 0) {
          list.push({ value: i, label: i });
        }
      }
      return list;
    }
  };

  const storageOptions = () => {
    const currentStorageGig = subscription?.storage / GIG;

    let opts = [];
    for (let i = currentStorageGig; i <= 100; i += 5) {
      opts.push({
        value: i * GIG,
        label: `${i + storageSuffix}`
      });
    }

    return opts;
  };

  const handleChange = (event, field) => {
    setEstimateSub(state => {
      return { ...state, [field]: event.value };
    });
    setIschanged(true);
  };

  const handleCancel = () => {
    initEstimateSub();
    setResetFormKey(new Date().getTime());
    setIschanged(false);
  };

  const isOnTrial = (() => {
    if (hasSafeRole(user.roles)) return false;
    if (getTrial(user, project, organizations?.toJS()) && !hasPaymentSource)
      return true;
    return false;
  })();

  const canUpdate = () => {
    if (!isOnTrial) return true;
    const { current_trial } = user.data;
    const used = current_trial?.current.amount;
    const spend = current_trial?.spend.amount;
    const initCost = parseInt(initEstimate.total);
    const newCost = parseInt(estimate.total);

    return spend >= used - initCost + newCost;
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!canUpdate()) {
      openModalTrial(true);
      return false;
    }

    dispatch(updateSubscription({ updates: estimateSub, subscription }));
    if (!error) setIschanged(false);
  };

  const currentPlan = (() => {
    const keyForCurrentPlan =
      subscription?.plan === "development"
        ? "trial/development"
        : subscription?.plan;
    return {
      value: PLAN_TYPES[keyForCurrentPlan]?.toLowerCase(),
      label: PLAN_TYPES[keyForCurrentPlan]
    };
  })();

  if (!estimate) return <LoadingIcon />;

  return (
    <S.FormWrapper onSubmit={handleSubmit}>
      {error && <Error>{error}</Error>}
      {deletedSubscription && <Redirect to={"/"} />}
      {subscription && (
        <>
          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="label">
                  {intl.formatMessage({
                    id: "project.plan.type",
                    defaultMessage: "Plan type"
                  })}
                  {isOnTrial && (
                    <InfoDialog
                      align="left"
                      text={intl.formatMessage({
                        id: "project.plan.trial.info",
                        defaultMessage:
                          "Free trials are limited to development only plans, add your billing details to change your plan."
                      })}
                      linkText={intl.formatMessage({
                        id: "project.plan.trial.info.link",
                        defaultMessage: "Add billing details"
                      })}
                      to={`/-/users/${username}/billing`}
                    />
                  )}
                </div>
              </div>
              <div className="options">
                <Dropdown
                  key={resetFormKey}
                  options={planTypes}
                  defaultValue={currentPlan}
                  onChange={event => handleChange(event, "plan")}
                  clearable={false}
                  fieldType={true}
                  required={true}
                  withBorder={false}
                  disabled={isOnTrial}
                  searchable={false}
                />
                <div className="total">{estimate.plan}</div>
              </div>
            </S.Heading>
            <S.Includes
              className={
                estimateSub.plan === "development" ? "development" : ""
              }
            >
              <div className="label">
                {intl.formatMessage({
                  id: "project.plan.includes.title",
                  defaultMessage: "Includes"
                })}
              </div>
              <div className="features">
                <PlanInfo plan={estimateSub.plan} project={project} />
              </div>
            </S.Includes>
          </S.Row>

          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="label">
                  {intl.formatMessage({
                    id: "project.plan.environment.title",
                    defaultMessage: "Environments"
                  })}
                </div>
                <div className="price">
                  {intl.formatMessage(
                    {
                      id: "project.plan.environment.estimation",
                      defaultMessage: "{estimation} / 3 environments"
                    },
                    { estimation: estimate.options.environments }
                  )}
                </div>
              </div>
              <div className="options">
                <Dropdown
                  key={resetFormKey}
                  options={environmentOptions()}
                  defaultValue={{
                    value: subscription.environments,
                    label: subscription.environments
                  }}
                  onChange={event => handleChange(event, "environments")}
                  clearable={false}
                  fieldType={true}
                  required={true}
                  searchable={false}
                />
                <div className="total">{estimate.environments}</div>
              </div>
            </S.Heading>
          </S.Row>

          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="label">
                  {intl.formatMessage({ id: "big_dev_title" })}
                </div>
                <div className="price">
                  {intl.formatMessage({ id: "big_dev_desc" })}
                  <InfoDialog
                    align="right"
                    text={intl.formatMessage({
                      id: "environment_application_size_message"
                    })}
                  />
                </div>
              </div>
              <div className="options">
                <Dropdown
                  key={resetFormKey}
                  options={bigDevTypes}
                  defaultValue={(() => {
                    const devSize = subscription?.data.big_dev;
                    return {
                      value: devSize || bigDevTypes[0].value,
                      label: bigDevMap[devSize] || bigDevTypes[0].label
                    };
                  })()}
                  onChange={event => handleChange(event, "big_dev")}
                  clearable={false}
                  fieldType={true}
                  required={true}
                  searchable={false}
                />
                <div className="total">{estimate.big_dev}</div>
              </div>
            </S.Heading>
          </S.Row>

          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="label">
                  {intl.formatMessage({
                    id: "project.plan.storage.title",
                    defaultMessage: "Storage"
                  })}
                </div>
                <div className="price">
                  {intl.formatMessage(
                    {
                      id: "project.plan.storage.estimation",
                      defaultMessage: "{estimation} / 5 GB per environment"
                    },
                    {
                      estimation: estimate.options.storage
                    }
                  )}
                </div>
              </div>
              <div className="options">
                <Dropdown
                  key={resetFormKey}
                  options={storageOptions()}
                  defaultValue={{
                    value: subscription.storage,
                    label: `${subscription.storage / GIG}${storageSuffix}`
                  }}
                  onChange={event => handleChange(event, "storage")}
                  clearable={false}
                  fieldType={true}
                  required={true}
                  searchable={false}
                />
                <div className="total">{estimate.storage}</div>
              </div>
            </S.Heading>
          </S.Row>

          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="label">
                  {intl.formatMessage({
                    id: "project.plan.users.title",
                    defaultMessage: "Users"
                  })}
                </div>
                <div className="price">
                  {intl.formatMessage(
                    {
                      id: "project.plan.users.estimation",
                      defaultMessage:
                        "{estimation} / per user per month. Billed when added to your platform"
                    },
                    {
                      estimation: estimate.options.user_licenses
                    }
                  )}
                </div>
              </div>
              <div className="options">
                <div className="users">{subscription.user_licenses}</div>
                <div className="total">{estimate.user_licenses}</div>
              </div>
            </S.Heading>
          </S.Row>

          <S.Row className="total-row">
            <S.Heading className="total-wrapper">
              <div className="info" />
              <div className="options">
                <Description className="description">
                  *
                  {intl.formatMessage({
                    id: "total_monthly_cost"
                  })}
                  <InfoDialog
                    title={intl.formatMessage({
                      id: "estimated_cost"
                    })}
                    align="right"
                    text={intl.formatMessage({
                      id: "billing_system_explanation"
                    })}
                  />
                </Description>
                <div className="total">{estimate.total}</div>
              </div>
            </S.Heading>
          </S.Row>

          <S.Footer>
            {loading ? (
              <LoadingIcon />
            ) : (
              <>
                <S.DeleteButton
                  onClick={() => {
                    if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
                      window.location.href = interpolateURL(
                        process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
                        { projectId }
                      );
                    } else {
                      openModalDelete(true);
                    }
                  }}
                  variant="outline"
                >
                  {intl.formatMessage({
                    id: "settings.plan.project.delete.button",
                    defaultMessage: "Delete project"
                  })}
                </S.DeleteButton>
                {isChanged && (
                  <>
                    <Button variant="secondary" onClick={handleCancel}>
                      {intl.formatMessage({
                        id: "cancel",
                        defaultMessage: "Cancel"
                      })}
                    </Button>
                    <Button type="submit">
                      {intl.formatMessage({
                        id: "save",
                        defaultMessage: "save"
                      })}
                    </Button>
                  </>
                )}
              </>
            )}
          </S.Footer>
        </>
      )}

      <ModalConfirmDelete
        title="Delete project"
        deleteFunction={() => {
          dispatch(deleteSubscription({ subscription }));
        }}
        isOpen={modalDeleteOpen}
        closeModal={() => openModalDelete(false)}
        itemId={`subscription-${subscription?.id}`}
        size="medium"
        body={
          <>
            <p>
              {intl.formatMessage(
                {
                  id: "settings.plan.project.delete.confirm",
                  defaultMessage:
                    "Are you sure you want to delete your project <b>{sub}</b>?"
                },
                {
                  sub: subscription?.project_title,
                  // eslint-disable-next-line react/display-name
                  b: txt => <strong>{txt}</strong>
                }
              )}
            </p>
            <p>
              {intl.formatMessage({
                id: "settings.plan.project.delete.info",
                defaultMessage:
                  "Please note that deleting your project is irreversible and all data associated with this project will be deleted, including backups. Lastly, at the end of the month you will be charged for any remaining project costs."
              })}
            </p>
          </>
        }
      />

      <ModalTrial
        isOpen={modalTrialOpen}
        closeModal={() => openModalTrial(false)}
        kind="plan"
      />
    </S.FormWrapper>
  );
};

ProjectPlanForm.propTypes = {
  hasPaymentSource: PropTypes.bool,
  username: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default withReducers({
  environment: () => import("Reducers/environment"),
  subscription: () => import("Reducers/subscription"),
  setup: () => import("Reducers/project/setup")
})(ProjectPlanForm);
