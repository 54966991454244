import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import LogModal from "../../../../../../common/components/ActivityList/LogModal/LogModal";
import { selectedActivitySelector } from "Reducers/metrics/activities.selector";
import { setSelectedActivity } from "Reducers/metrics/activities.slice";

const ActivityLogModal = () => {
  const dispatch = useDispatch();

  const selectedActivity = useSelector(selectedActivitySelector);

  const closeModal = useCallback(() => {
    dispatch(setSelectedActivity(undefined));
  }, []);

  if (!selectedActivity) return null;

  return (
    <LogModal initialActivity={selectedActivity} closeModal={closeModal} />
  );
};

export default React.memo(ActivityLogModal);
