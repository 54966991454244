import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

import Screenshot from "../../../../common/components/Screenshot";
import ProjectMoreLinks from "Components/ProjectMoreLinks";
import ProjectListItem from "./ProjectListItem";

import { loadSubscription, subscriptionSelector } from "Reducers/subscription";
import { loadSubscription as loadOrganizationSubscription } from "Reducers/organization/subscription";

import {
  isProjectOwner,
  getRegionLabel,
  getOwnerInfoLabel,
  getOwnerInfoName,
  getTrial
} from "Libs/utils";

import * as S from "./styles";

const ProjectCard = ({
  children,
  gridLayout,
  organizationId,
  projectId,
  organizations
}) => {
  const [isWarning, setWarning] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const me = useSelector(state => state.app.get("me"));
  const projectFromList = useSelector(state =>
    state.project?.getIn(["list", organizationId, projectId], new Map())
  );
  const projectFromData = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );
  const project = projectFromData || projectFromList;

  const oldestSubscription = useSelector(({ subscription }) =>
    subscription?.get("oldest")
  );
  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project.subscription_id
    });
  });

  useEffect(() => {
    if (
      !process.env.ENABLE_ORGANIZATION &&
      project.subscription_id &&
      me?.get("id") === project?.owner
    ) {
      setTimeout(() =>
        dispatch(
          loadSubscription({
            organizationId,
            projectId,
            id: project.subscription_id
          })
        )
      );
    } else if (process.env.ENABLE_ORGANIZATION) {
      dispatch(
        loadOrganizationSubscription({
          organizationId,
          projectId,
          id: project.subscription_id
        })
      );
    }
  }, [
    project?.subscription_id,
    getOwnerInfoName(project, organizations.toJS()),
    me?.get("id")
  ]);

  useEffect(() => {
    if (
      process.env.ENABLE_BILLING &&
      !process.env.ENABLE_ORGANIZATION &&
      isProjectOwner(project, me.toJS(), organizations.toJS())
    ) {
      const subscriptionCreationDate = oldestSubscription?.data?.created_at;

      if (
        getTrial(me?.toJS(), project, organizations?.toJS()) &&
        subscriptionCreationDate
      ) {
        const trialDate = moment(subscriptionCreationDate);
        const now = moment();
        const diff = now.diff(trialDate);
        if (diff > moment.duration(30, "days").asMilliseconds()) {
          setWarning(true);
        }
      }
    }
  }, [oldestSubscription]);

  const region = (() => {
    return getRegionLabel(subscription?.project_region_label);
  })();

  return (
    <S.ProjectCardLayout
      className={gridLayout ? "project-card" : "project-list-item"}
    >
      {gridLayout ? (
        <Link
          to={`/${organizationId}/${project.id}`}
          id={`project-card-${project.id}`}
        >
          <S.ScreenshotWrapper>
            <Screenshot
              imagePath={`/${project.id}/${
                project.default_branch || "master"
              }/screenshot.png`}
            />
          </S.ScreenshotWrapper>

          <S.Details>
            <S.Row>
              <S.Org>
                {getOwnerInfoLabel(subscription, organizations?.toJS())}
              </S.Org>

              {subscription && (
                <ProjectMoreLinks
                  from="projects"
                  project={project}
                  subscription={subscription}
                />
              )}
            </S.Row>

            <S.ProjectName>
              {project.title || intl.formatMessage({ id: "no_title" })}
            </S.ProjectName>

            <S.Row>
              <S.Region>
                {region && (
                  <>
                    {region.title}
                    <S.RegionSuffix>{region.suffix}</S.RegionSuffix>
                  </>
                )}
              </S.Region>

              {subscription?.status === "suspended" ? (
                <S.Tag>
                  {intl.formatMessage({
                    id: "project.card.tag.suspended",
                    defaultMessage: "Suspended"
                  })}
                </S.Tag>
              ) : (
                subscription?.plan?.startsWith("trial") && (
                  <S.Trial>
                    {intl.formatMessage({
                      id: "project.card.tag.trial",
                      defaultMessage: "Free trial"
                    })}
                  </S.Trial>
                )
              )}
            </S.Row>
          </S.Details>
          {children}
        </Link>
      ) : (
        <ProjectListItem
          hasWarning={isWarning}
          organizationId={organizationId}
          project={project}
          region={region}
          subscription={subscription}
          organizations={organizations}
          me={me}
        />
      )}
    </S.ProjectCardLayout>
  );
};

ProjectCard.propTypes = {
  children: PropTypes.node,
  gridLayout: PropTypes.bool,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  organizations: PropTypes.object
};

export default React.memo(ProjectCard);
