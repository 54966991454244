import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Map } from "immutable";
import momentjs from "moment";

import {
  css,
  INPUT,
  HEADER,
  SECTION,
  getCSSVarString,
  ICON
} from "Libs/themes";
import { formatAsCurrency } from "Libs/utils";

import PaymentForm from "./PaymentForm";
import Label from "Components/fields/Label";
import PageMeta from "Components/PageMeta";
import InfoDialog from "Components/InfoDialog";
import Heading2 from "Components/styleguide/Heading2";
import Heading3 from "Components/styleguide/Heading3";
import Sticker from "ds/Stickers";

const ColumnWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .setting-line {
    margin-bottom: 30px;
    border: 1px solid rgba(152, 160, 171, 0.4);
    background: #f5f7fa;
    padding: 0 10px;
    min-height: 40px;
  }
  .new-card {
    button + button {
      margin-left: 10px;
    }
  }
  @media (min-width: 900px) {
    display: flex;
    align-items: flex-stretch;
  }
`;

const ReviewWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
  padding: 24px;
  box-sizing: border-box;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  margin-bottom: 32px;
  h2 {
    line-height: 33px;
    margin-bottom: 16px;
  }
  hr {
    margin-bottom: 24px;
  }
  label {
    margin-bottom: 6px;
  }
  .estimated-total {
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    padding: 16px;
    margin: 0 -8px -8px;
    .price {
      display: flex;
      align-items: baseline;
      h2,
      label {
        margin: 0;
      }
      label {
        margin-left: 8px;
        color:var(--section-granite-color,var(--section-granite,var(--granite)));
      }
    }
  }
  @media (min-width: 900px) {
    width: 224px;
    min-width: 224px;
    margin-bottom: 0;
  }
  @media (min-width: 1024px) {
    width: 334px;
    min-width: 334px;
  }
`;

const ContentWrapper = styled.div`
  .step-counter-wrapper {
    margin-bottom: 40px;
  }
  @media (min-width: 900px) {
    width: calc(100% - 258px);
    margin-left: 34px;
  }
  @media (min-width: 1024px) {
    width: calc(100% - 368px);
    margin-left: 34px;
  }
  form#stripe-payment-form {
    margin-bottom: 40px;
    .update_button {
      margin: 0 0 16px 10px;
      button {
        height: 40px;
        line-height: 38px;
      }
    }
    #stripe_cardsection_submit {
      display: none;
    }
  }
`;

const ReviewItem = styled.div`
  margin-bottom: 16px;
  &.review-plan .plan {
    &:first-letter {
      text-transform: uppercase;
    }
  }
  h3 {
    margin: 0;
  }
`;

const Value = styled.div`
  color: ${props => props.color ||"var(--section-slate-color,var(--section-slate,var(--slate)))"};
  font-size: 14px;
  line-height: 24px;
  &.inline {
    display: flex;
    align-items: baseline;
    label {
      margin: 0 0 0 6px;
    }
  }
  .list-item {
    margin-bottom: 5px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 16px;
  &:first-letter {
    text-transform: capitalize;
  }
  .info-dialog {
    height: 31px;
    width: 31px;
    svg path {
      height: 20px;
      width: 20px;
    }
    &:hover,
    &:focus {
      svg path {
        var(--icon-ebony-light-fill,var(--icon-ebony-light,var(--ebony-light)));
      }
    }
  }
`;

const Description = styled.div`
  font-size: 15px;
  margin-bottom: 16px;
`;

const Trial = styled(Sticker)`
  line-height: 24px;
  padding: 8px 16px;
  margin: 0 -8px 16px -8px;

  header {
    display: flex;
    font-weight: 600;
    font-size: 15px;
    color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
    span {
      margin-left: auto;
    }
  }

  p {
    display: flex;
    margin: 0;
    font-size: 13px;
    line-height: 18px;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
  }
`;

const Separator = styled.hr``;

const Billing = props => {
  const { intl, plan, plans, cancel, me } = props;
  const [estimate, setEstimate] = useState();
  const price = plans?.getIn([plan.size, "price"])?.toJS();
  const credit = estimate?.credit;

  const stripePromise = loadStripe(
    props.stripePublicKey
      ? props.stripePublicKey
      : "pk_test_6pRNASCoBOKtIshFeQd4XMUh"
  );

  useEffect(() => {
    updateEstimation();
  }, []);

  const hasVoucherBalance = (price, credit) => {
    if (credit?.amount >= (price?.amount || 0)) {
      return true;
    }
    return false;
  };

  const updateEstimation = async () => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const params = {
      plan: props.plan.size,
      storage: 1024,
      environments: 3,
      user_licenses: 1,
      format: "complex"
    };

    setEstimate(await client.getSubscriptionEstimate(params));
  };

  const getCredits = () => {
    const { me } = props;
    const { current_trial } = me.data;
    if (current_trial?.active && current_trial?.expiration) {
      return current_trial.spend?.amount - current_trial.current?.amount;
    }
    return false;
  };

  const credits = getCredits();

  return (
    <ColumnWrapper>
      <PageMeta title="Billing" />
      <ReviewWrapper className="review-wrapper">
        <div className="plan-info">
          <Title>
            Summary
            <InfoDialog
              text={intl.formatMessage({
                id: "billing_summary_info"
              })}
              linkText={intl.formatMessage({
                id: "manage_project"
              })}
              align="right"
            />
          </Title>
          <ReviewItem className="review-account">
            <Description>
              <FormattedMessage id="billing_new_project" />
            </Description>
            <Separator />
          </ReviewItem>
          {plan && (
            <React.Fragment>
              <ReviewItem className="review-plan">
                <Label>Plan size</Label>
                <Heading3 id="plan-name" className="plan" data-sku={plan.sku}>
                  {plan.size}
                </Heading3>
              </ReviewItem>
              <ReviewItem className="monthly-price">
                <Label>Monthly price</Label>
                <Value className="inline">
                  <Heading3>{price?.formatted}</Heading3>
                  <Label>{price?.currency_code}</Label>
                </Value>
              </ReviewItem>
              <ReviewItem className="review-includes">
                <Label>Includes</Label>
                <Value className="extras">
                  <div className="list-item">&bull; 1 User</div>
                  <div className="list-item">
                    &bull; 3 Development environments
                  </div>
                  <div className="list-item">
                    &bull; 5 GB Storage per environment
                  </div>
                  <div className="list-item">
                    &bull; Main dev site (future production site)
                  </div>
                </Value>
              </ReviewItem>
            </React.Fragment>
          )}
        </div>

        <div>
          {credits && (
            <Trial level="low">
              <header>
                {intl.formatMessage({
                  id: "invoice.trial.credit",
                  defaultMessage: "Free trial credit"
                })}
                <span>
                  {formatAsCurrency(
                    credits,
                    me.data?.current_trial?.current?.currency_code
                  )}
                </span>
              </header>

              <p>
                {intl.formatMessage(
                  {
                    id: "invoice.trial.expires",
                    defaultMessage: "Expires: {date}"
                  },
                  {
                    date: momentjs(me.data?.current_trial?.expiration).format(
                      "D MMMM YYYY [at] h:mm a"
                    )
                  }
                )}
              </p>
            </Trial>
          )}

          <div className="estimated-total">
            <div className="price">
              <Heading2>{price?.formatted}</Heading2>
              <Label>{price?.currency_code}</Label>
            </div>
            <Label>{intl.formatMessage({ id: "total_monthly_payment" })}</Label>
          </div>
        </div>
      </ReviewWrapper>
      <ContentWrapper>
        <Elements stripe={stripePromise}>
          <PaymentForm
            goForwardText={intl.formatMessage({
              id: "update_billing"
            })}
            hasVoucher={hasVoucherBalance(price, credit)}
            estimate={estimate}
            plan={plan}
            finalize={cancel}
          />
        </Elements>
      </ContentWrapper>
    </ColumnWrapper>
  );
};

Billing.propTypes = {
  intl: PropTypes.object,
  region: PropTypes.object,
  plan: PropTypes.object,
  plans: PropTypes.object,
  me: PropTypes.object,
  info: PropTypes.object,
  template: PropTypes.object,
  account: PropTypes.string,
  accountId: PropTypes.string,
  theme: PropTypes.object,
  stripePublicKey: PropTypes.string,
  cancel: PropTypes.func
};

const mapStateToProps = state => ({
  me: state.app?.get("me", new Map())?.toJS(),
  options: state.setup.getIn(["options", "data"], new Map()),
  plans: state.plan.getIn(["data"], new Map()),
  stripePublicKey:
    state.app && state.app.getIn(["me", "data", "stripe", "public_key"])
});

export default connect(mapStateToProps)(injectIntl(Billing));
