import styled from "styled-components";

import { css, SECTION } from "Libs/themes";

import UnstyledLayout from "Components/ContentLayout";
import UnstyledHeading2 from "Components/styleguide/Heading2";

export const Layout = styled(UnstyledLayout)`
  margin-top: 16px;
`;

export const Header = styled.div``;

export const Heading = styled(UnstyledHeading2)`
  margin-bottom: 16px;
`;

export const Intro = styled.p`
  margin: 0 0 36px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const Headers = styled.div`
  display: flex;
  margin-bottom: 12px;
  padding: 0 52px 0 36px;
`;

export const FormWrapper = styled.section`
  padding: 32px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
`;
