import React from "react";
import { useParams } from "react-router-dom";

import OrganizationBillingOrderDetails from "./containers/OrganizationBillingOrderDetails";

import { withReducers } from "Hocs";

const Page = () => {
  const { username, orderId } = useParams();

  return <OrganizationBillingOrderDetails params={{ username, orderId }} />;
};

export default withReducers({
  project: () => import("Reducers/project"),
  order: () => import("Reducers/user/order"),
  subscription: () => import("Reducers/subscription")
})(Page);
