import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { projectSelector } from "Reducers/project";

import LockIcon from "Icons/LockIcon";
import Screenshot from "../../../../common/components/Screenshot";
import ProjectMoreLinks from "Components/ProjectMoreLinks";
import ProjectListItem from "./ProjectListItem";

import { getRegionLabel, getOwnerInfoLabel, hasSafeRole } from "Libs/utils";

import * as S from "./ProjectCard.styles";

const ProjectCard = ({
  children,
  gridLayout,
  organizationId,
  subscription,
  organizations,
  user
}) => {
  const intl = useIntl();
  let isWarning = false;

  const me = useSelector(state => state.app.get("me"));

  const project = useSelector(state =>
    projectSelector(state, {
      organizationId,
      projectId: subscription?.project_id
    })
  );

  const region = useMemo(
    () => getRegionLabel(subscription?.project_region_label),
    [subscription]
  );

  const hasGitProjectAccess = useMemo(
    () => project || hasSafeRole(user?.roles),
    [project, user]
  );

  const Container = hasGitProjectAccess ? Link : S.NoAccessWrapper;

  return (
    <S.ProjectCardLayout
      hasGitProjectAccess={hasGitProjectAccess}
      className={gridLayout ? "project-card" : "project-list-item"}
    >
      {gridLayout ? (
        <Container
          to={`/${organizationId}/${subscription.project_id}`}
          id={`project-card-${subscription.project_id}`}
        >
          <S.ScreenshotWrapper>
            <Screenshot
              imagePath={`/${subscription.project_id}/${
                /*project?.default_branch ||*/ "master"
              }/screenshot.png`}
            />
          </S.ScreenshotWrapper>

          <S.Details>
            <S.Row>
              <S.Org>
                {getOwnerInfoLabel(subscription, organizations?.toJS())}
              </S.Org>

              {subscription && (
                <ProjectMoreLinks
                  from="projects"
                  project={project}
                  subscription={subscription}
                />
              )}
            </S.Row>

            <S.ProjectName>
              {subscription?.project_title ||
                intl.formatMessage({ id: "no_title" })}
            </S.ProjectName>

            <S.Row>
              <S.Region>
                {region && (
                  <>
                    {region.title}
                    <S.RegionSuffix>{region.suffix}</S.RegionSuffix>
                  </>
                )}
              </S.Region>

              {!hasGitProjectAccess && (
                <S.NoAccessTag>
                  <div style={{ width: 22, height: 13, marginRight: 4 }}>
                    <LockIcon />
                  </div>
                  {intl.formatMessage({
                    id: "project.card.tag.no-access",
                    defaultMessage: "No Access"
                  })}
                </S.NoAccessTag>
              )}

              {hasGitProjectAccess && subscription?.status !== "active" ? (
                <S.Tag>
                  {intl.formatMessage({
                    id: "project.card.tag.suspended",
                    defaultMessage: "Suspended"
                  })}
                </S.Tag>
              ) : (
                hasGitProjectAccess &&
                process.env.ENABLE_BILLING &&
                subscription?.plan?.startsWith("trial") && (
                  <S.Trial>
                    {intl.formatMessage({
                      id: "project.card.tag.trial",
                      defaultMessage: "Free trial"
                    })}
                  </S.Trial>
                )
              )}
            </S.Row>
          </S.Details>
          {children}
        </Container>
      ) : (
        <ProjectListItem
          hasWarning={isWarning}
          organizationId={organizationId}
          subscription={subscription}
          region={region}
          organizations={organizations}
          me={me}
        />
      )}
    </S.ProjectCardLayout>
  );
};

ProjectCard.propTypes = {
  children: PropTypes.node,
  gridLayout: PropTypes.bool,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  organizations: PropTypes.object,
  user: PropTypes.object,
  subscription: PropTypes.object
};

export default ProjectCard;
