import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Map } from "immutable";

import { withReducers } from "Hocs";
import useDecodedParams from "Hooks/useDecodedParams";
import useQueryStrings from "Hooks/useQueryStrings";

import { getCatalog, saveConfig } from "Reducers/project/setup";
import { getOrganizationCatalog } from "Reducers/organization/setup";
import { canCreateProjectOrganizationsSelector } from "Reducers/organization";

import { toQueryString } from "Libs/utils";

import Breadcrumbs from "../../components/Breadcrumbs";

const STEPS = ["type", "template", "info", "building"];

import * as S from "./ProjectSetup.styles";

const ProjectSetup = ({ children, goToStep }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const { step } = useParams();
  const { organizationId: organizationNameParams } = useDecodedParams();

  const [steps, setSteps] = useState(STEPS);
  const [currentStep, setCurrentStep] = useState(STEPS[0]);

  const config = useSelector(({ setup }) => setup?.get("config", Map()));

  const { catalog, query, template } = useQueryStrings([
    "catalog",
    "query",
    "template"
  ]);

  const organizations = useSelector(state =>
    canCreateProjectOrganizationsSelector(state)?.toArray()
  );

  const firstOrganization = organizations.length && organizations[0];
  const organizationIdParams = organizations?.find(
    o => o.name === organizationNameParams
  )?.id;

  const organizationId = config?.organization?.value || organizationIdParams;

  useEffect(() => {
    if (
      process.env.ENABLE_ORGANIZATION &&
      organizationNameParams &&
      organizationNameParams !== "projects" &&
      !organizationId
    ) {
      // This org does not exist or user does not have access, let's redirect
      replace(getUrlWithStepAndQuery("/projects/create-project"));
    } else {
      if (
        process.env.ENABLE_ORGANIZATION &&
        organizationNameParams &&
        organizationNameParams !== "projects"
      ) {
        dispatch(
          getOrganizationCatalog({
            organizationId: organizationNameParams,
            catalogUrl: catalog
          })
        );
      } else {
        dispatch(
          getCatalog({
            catalogUrl: catalog
          })
        );
      }

      if (template) {
        dispatch(saveConfig({ template }));
        goToStep(`info?template=${template}`, firstOrganization);
      }
    }
  }, [organizationNameParams]);

  useEffect(() => {
    updateSteps();
  }, [pathname]);

  useEffect(() => {
    setCurrentStep(step ? step : steps[0]);
  }, [step]);

  const getUrlWithStepAndQuery = url => {
    if (step) url = `${url}/${step}`;
    const queryString = toQueryString({ query, template });
    if (queryString) url = `${url}?${queryString}`;
    return url;
  };

  const updateSteps = () => {
    let skip = [];

    if (template) {
      skip.push("type");
      skip.push("template");
    }

    if (step !== "template" && !config.has("template")) {
      skip.push("template");
    }

    setSteps(STEPS.filter(step => !skip.includes(step)));
  };

  const hasOrganizationNamedProjects = useMemo(
    () => organizations.some(org => org.name === "projects"),
    [organizations, organizationNameParams]
  );

  // If the user has multiple organizations, calling accounts directly
  // will no longer work to get the catalog etc...
  useEffect(() => {
    if (
      organizationNameParams === "projects" &&
      !hasOrganizationNamedProjects &&
      firstOrganization
    ) {
      // To avoid recursion, don't enter here if the user has an organization named projects
      // Redirect to the first organization in the list
      // the user can change his organization later in the setup
      if (!template) {
        // Routes with ?template query will be handle by gotoStep()
        replace(
          getUrlWithStepAndQuery(`/${firstOrganization?.name}/create-project`)
        );
      }
    }
  }, [organizationNameParams, firstOrganization]);

  return (
    <S.Layout>
      <S.Wrapper className="step-wrapper col-10">
        <S.CloseButton to="/">
          <FormattedMessage id="cancel" />
        </S.CloseButton>

        <Breadcrumbs
          steps={steps}
          currentStep={currentStep}
          goToStep={goToStep}
        />

        <S.Step>{children}</S.Step>
      </S.Wrapper>
    </S.Layout>
  );
};

ProjectSetup.propTypes = {
  goToStep: PropTypes.func,
  children: PropTypes.node
};

export default withReducers({
  region: () => import("Reducers/project/region"),
  setup: () => import("Reducers/project/setup"),
  organizationSetup: () => import("Reducers/organization/setup"),
  organization: () => import("Reducers/organization")
})(ProjectSetup);
