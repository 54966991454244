import styled from "styled-components";
import { semiBoldAlias, breakpoints } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

import Heading2 from "Components/styleguide/Heading2";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  text-align: left;
  .settings-item .setting-line .action .open-text:not(:empty) {
    min-width: 40px;
  }

  @media (min-width: ${breakpoints[1]}) {
  }
`;

export const Heading = styled(Heading2)`
  font-size: 18px;
`;

export const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  .user {
    width: 75%;
    display: flex;
    flex-align: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .display-name {
      font-weight: bold;
      font-size: 16px;
      width: 50%;
    }
    .email {
      font-weight: normal;
      font-size: 15px;
    }
  }
  .role {
    display: inline-block;
    align-items: center;
    font-size: 13px;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
    ${semiBoldAlias};
    &:first-letter {
      text-transform: uppercase;
    }
    &.account-owner {
      display: inline-flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .lock-icon {
    height: 24px;
    width: 24px;
    idsplay: inline-flex;
    align-items: stretch;
    margin-left: 16px;
    svg {
      width: 100%;
    }
  }
`;
