import { fromJS, Map } from "immutable";
import {
  createAsyncThunk,
  createSlice,
  createSelector
} from "@reduxjs/toolkit";

import logger from "Libs/logger";
import getThirdParties from "./thirdParties";

import type Integration from "platformsh-client/types/model/Integration";
import type Project from "platformsh-client/types/model/Project";
import {
  MapStoreStateType,
  SetErrorMessageAction,
  StoreMapStateType
} from "Reducers/types";

import {
  APIError,
  AddIntegrationParams,
  IntegrationErrors,
  UpdateIntegrationParams,
  DeleteIntegrationParams,
  GitRepoParams,
  SortedIntegrations,
  Elt,
  SelectorParams,
  Config
} from "./types";

const CONFIG: Record<string, Config> = {
  github: {
    tokenUrl: "/user",
    repoUrl: "/user/repos?per_page=100&page=1"
  },
  gitlab: {
    tokenUrl: "/api/v4/user",
    repoUrl: "/api/v4/projects?membership=1&per_page=100&page=1"
  }
};

const isAPIError = (error: any): error is APIError =>
  typeof error.message === "string" && error.detail;

export const getIntegrations = createAsyncThunk(
  "app/integrations",
  async ({ projectId }: { projectId: string }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const integrations = await client.getIntegrations(projectId);
    return integrations;
  }
);

export const getIntegration = createAsyncThunk(
  "app/integration",
  async ({
    projectId,
    integrationId
  }: {
    projectId: string;
    integrationId: string;
  }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const integration = await client.getIntegration(projectId, integrationId);
    return integration;
  }
);

export const addIntegration = createAsyncThunk<
  Integration,
  AddIntegrationParams,
  { rejectValue: IntegrationErrors }
>(
  "app/integration/add",
  async ({ project, type, data }, { rejectWithValue }) => {
    if (!project) return false;

    try {
      const result = await project.addIntegration(type, data);
      const integration = await result?.getEntity();
      return integration;
    } catch (err) {
      let errors = "An error occurred while attempting to add integration.";

      if (isAPIError(err)) {
        logger(err, {
          action: "addIntegration",
          meta: {
            projectId: project.id
          }
        });

        if (typeof err.detail === "object") {
          // The error returned by git in the type field
          errors = err.detail.type;
        } else {
          errors = err.detail;
        }
      }
      return rejectWithValue({ errors });
    }
  }
);

export const updateIntegration = createAsyncThunk<
  Integration,
  UpdateIntegrationParams,
  { rejectValue: IntegrationErrors }
>(
  "app/integration/update",
  async ({ projectId, integration, data }, { rejectWithValue }) => {
    const fields = Object.assign(
      {},
      { projectId },
      { integrationId: integration.id },
      data
    );
    try {
      const result = await integration.update(fields);
      const newIntegration = await result?.getEntity();
      return newIntegration;
    } catch (err) {
      let errors = "An error occurred while attempting to update integration.";
      if (isAPIError(err)) {
        logger(
          {
            errMessage: err.message,
            integrationId: integration.id,
            projectId
          },
          {
            action: "updateIntegration"
          }
        );
        if (typeof err.detail === "object") {
          // The error returned by git in the type field
          errors = err.detail.type;
        } else {
          errors = err.detail;
        }
      }
      return rejectWithValue({ errors });
    }
  }
);

export const deleteIntegration = createAsyncThunk<
  Integration,
  DeleteIntegrationParams,
  { rejectValue: IntegrationErrors }
>("app/integration/delete", async ({ projectId, integration }) => {
  await integration.delete().catch(err => {
    const errMessage = JSON.parse(err);
    logger(
      {
        errMessage,
        integrationId: integration.id,
        projectId
      },
      {
        action: "deleteIntegration"
      }
    );
    throw new Error(errMessage.error);
  });
  return integration;
});

/**
 * Transform string returns by the api like:
 * <https://gitlab.com/api/v4/users/....>; rel="first", <https://gitlab.com/api/v4/users/...>; rel="next", <https://gitlab.com/api/v4/users/...>; rel="last"
 *
 * to object
 * { first: "url", next: "url", last: "url" }
 */
const getLinks = (str: string | null) => {
  if (!str) return [];
  return str.split(", ").reduce((acc: { [key: string]: any }, cu: string) => {
    const [link, val] = cu.split("; ");
    const key = val.substring(val.indexOf('"') + 1, val.lastIndexOf('"'));
    acc[key] = link.replace(/[<>]/g, "");
    return acc;
  }, []);
};

const fetchNextProjectData = async (
  url: string,
  token: string,
  projectsArray: Project[] = []
): Promise<Project | Project[]> => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const projectData = await response.json();
  projectsArray.push(...projectData);

  const links = getLinks(response.headers.get("Link"));
  if (links.next) {
    return fetchNextProjectData(links.next, token, projectsArray);
  }
  return projectsArray;
};

export const getGitRepositoriesIntegration = createAsyncThunk<
  Integration,
  GitRepoParams,
  { rejectValue: IntegrationErrors }
>(
  "app/integration/git",
  async ({ baseUrl, token, type }, { rejectWithValue }) => {
    if (!["gitlab", "github"].includes(type)) return;

    const apiUrl =
      baseUrl?.replace(/\/$/, "") ||
      (type === "github" ? process.env.GITHUB_URL : process.env.GITLAB_URL);
    const config = CONFIG[type];

    try {
      const resUser = await fetch(`${apiUrl}${config.tokenUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (resUser?.status === 401) throw new Error("Bad Credentials");

      const userData = await resUser.json();
      const projectData = await fetchNextProjectData(
        `${apiUrl}${config.repoUrl}`,
        token
      );
      return [userData, projectData].reduce((accumulated, current) => {
        if (Array.isArray(current)) {
          accumulated["repositories"] = current.map(repository => {
            const repo =
              type === "github"
                ? repository.full_name
                : repository.path_with_namespace;
            return { label: repo, value: repo };
          });
        } else {
          accumulated["user"] = {
            name: current.name || current.login || current.username,
            avatarUrl: current?.avatar_url
          };
        }
        return accumulated;
      }, {});
    } catch (err) {
      let errors =
        "An error occurred while attempting to get Git repositories.";
      if (isAPIError(err)) {
        logger(
          { errorMessage: err.message, token: token },
          { action: "getGitRepositoriesIntegration" }
        );
        errors = err.message;
      }
      return rejectWithValue({ errors });
    }
  }
);

export const setError = (
  state: StoreMapStateType,
  action: SetErrorMessageAction
) => state.set("errors", action.error.message).set("loading", false);

const integrations = createSlice({
  name: "integrations",
  initialState: Map({ data: Map(), loading: "idle" }) as StoreMapStateType,
  reducers: {
    initForm(state, action) {
      return state
        .delete("errors")
        .set("status", "idle")
        .set("lastEdited", action.payload.id);
    },
    initGit(state) {
      return state.delete("get-git-data");
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getIntegrations.pending, state => state.set("loading", true))
      .addCase(getIntegrations.fulfilled, (state, action) => {
        const { projectId } = action.meta.arg;
        return state
          .setIn(
            ["data"],
            fromJS(
              action.payload.reduce(
                (
                  projectIntegrations: Record<string, Integration>,
                  integration: Integration
                ) => {
                  if (!projectIntegrations[projectId]) {
                    projectIntegrations[projectId] = {} as Integration;
                  }
                  projectIntegrations[projectId][
                    integration.id as keyof Integration
                  ] = fromJS(integration);
                  return projectIntegrations;
                },
                {}
              )
            )
          )
          .set("loading", false)
          .set("status", "idle")
          .delete("lastEdited");
      })

      .addCase(getIntegrations.rejected, (state, action) =>
        setError(state, action)
      )
      .addCase(getIntegration.pending, state =>
        state.set("loading", true).delete("errors")
      )
      .addCase(getIntegration.fulfilled, (state, action) => {
        const { projectId, integrationId } = action.meta.arg;
        return state
          .setIn(["data", projectId, integrationId], fromJS(action.payload))
          .set("loading", false);
      })
      .addCase(getIntegration.rejected, (state, action) =>
        setError(state, action)
      )

      .addCase(getGitRepositoriesIntegration.pending, state =>
        state
          .set("loading", true)
          .delete("errors")
          .set("get-git-data", "pending")
      )
      .addCase(getGitRepositoriesIntegration.fulfilled, (state, action) => {
        const { token } = action.meta.arg;
        return state
          .setIn(["data", token], fromJS(action.payload))
          .set("loading", false)
          .set("get-git-data", "fulfilled");
      })

      .addCase(getGitRepositoriesIntegration.rejected, (state, action) =>
        state
          .set("errors", action.payload?.errors)
          .set("get-git-data", "rejected")
      )
      .addCase(addIntegration.pending, state =>
        state.set("status", "pending").delete("errors")
      )
      .addCase(addIntegration.fulfilled, (state, action) => {
        const { project } = action.meta.arg;
        return state
          .setIn(
            ["data", project.id, action.payload.id],
            fromJS(action.payload)
          )
          .set("status", "added")
          .set("lastEdited", action.payload.id);
      })
      .addCase(addIntegration.rejected, (state, action) =>
        state.set("errors", action.payload?.errors).set("status", "rejected")
      )
      .addCase(updateIntegration.pending, state =>
        state.set("status", "pending").delete("errors")
      )
      .addCase(updateIntegration.fulfilled, (state, action) => {
        const { projectId } = action.meta.arg;
        return state
          .setIn(["data", projectId, action.payload.id], fromJS(action.payload))
          .set("status", "updated");
      })
      .addCase(updateIntegration.rejected, (state, action) =>
        state.set("errors", action.payload?.errors).set("status", "rejected")
      )
      .addCase(deleteIntegration.pending, state =>
        state.set("status", "pending").delete("errors")
      )

      .addCase(deleteIntegration.fulfilled, (state, action) => {
        const { projectId } = action.meta.arg;
        return state
          .deleteIn(["data", projectId, action.payload.id])
          .set("status", "deleted");
      })
      .addCase(deleteIntegration.rejected, (state, action) =>
        state.set("errors", action.payload?.errors).set("status", "rejected")
      );
  }
});

export const { initForm, initGit } = integrations.actions;
export default integrations.reducer;

const selectSelf = (state: MapStoreStateType) => {
  return state.integration;
};

const getParams = (_: unknown, params: SelectorParams) => params;

const getCategory = (obj: Elt) => {
  return getThirdParties().find(elt => elt.type === obj.type)?.category;
};

export const integrationsSelector = createSelector(
  selectSelf,
  getParams,
  (integration, { projectId }) => {
    return integration
      ?.getIn(["data", projectId], Map())
      .toArray()
      .map((elt: Elt) =>
        Object.assign({}, elt.data, {
          category: getCategory(elt)
        })
      )
      .filter((elt: Elt) => elt.category)
      .sort((a: SortedIntegrations, b: SortedIntegrations) => {
        const catA = a.category;
        const catB = b.category;
        return catA < catB ? -1 : catA > catB ? 1 : 0;
      });
  }
);

export const integrationsLoadingSelector = createSelector(
  selectSelf,
  integration => {
    return integration?.get("loading", false);
  }
);
