import React, { useMemo } from "react";
import { Route, useParams, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import { isEnvironmentTypeProjectSelector } from "Reducers/project";

import Page from "./Page";
import GeneralSettingsPage from "./general/Page";
import AccessSettingsPage from "./access/Page";
import VariablesSettings from "./variables/Routes";

const Routes = () => {
  const match = useRouteMatch();
  const { organizationId, projectId } = useParams();

  const isEnvironmentTypeProject = useSelector(state =>
    isEnvironmentTypeProjectSelector(state, { organizationId, projectId })
  );

  // Don't show access tab if isEnvironmentTypeProject is undefined because the reducer hasn't resolved yet
  const showAccessPage =
    !process.env.ENVIRONMENT_TYPE_ENABLED && isEnvironmentTypeProject === false;

  const entries = useMemo(() => {
    let tabs = ["general", "variables"];
    if (showAccessPage) tabs.push("access");
    return tabs;
  }, [showAccessPage]);

  return (
    <Page entries={entries}>
      {showAccessPage && (
        <Route exact path={`${match.path}/access`}>
          <AccessSettingsPage />
        </Route>
      )}
      <Route path={`${match.path}/variables`}>
        <VariablesSettings />
      </Route>
      <Route exact path={`${match.path}`}>
        <GeneralSettingsPage />
      </Route>
    </Page>
  );
};

export default Routes;
