import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LiveMessage } from "react-aria-live";

import useDecodedParams from "Hooks/useDecodedParams";

import { withReducers } from "Hocs";
import ContentLayout from "Components/ContentLayout";
import useLocalForage from "Hooks/useLocalForage";
import {
  loadingListSelector,
  loadSubscriptions
} from "Reducers/organization/subscription";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import Projects from "./containers/Projects";
import ProjectFilterBar from "../../common/components/ProjectFilterBar";

import ProjectsSkeleton from "../../common/components/ProjectsSkeleton";

const ORGANIZATION_PROJECT_SORT_TYPES = [
  { name: "name", id: "project_title", ascend: "A-Z", descend: "Z-A" },
  {
    name: "date",
    id: "created_at",
    ascend: "recent last",
    descend: "recent first"
  },
  { name: "plan", id: "plan", ascend: "D-XXL", descend: "XXL-D" },
  { name: "id", id: "project_id", ascend: "0 - 1", descend: "1 - 0" }
];

const OrganizationProjects = () => {
  const { organizationId } = useDecodedParams();

  const [layout, toggleLayout, , layoutIsLoading] = useLocalForage(
    "projects_grid_layout"
  );
  const [sortType, setSortType, , sortIsLoading] = useLocalForage(
    "projects_sorting_type_organization"
  );
  const [sortOrder, setSortOrder, , orderIsLoading] = useLocalForage(
    "projects_sorting_order_organization"
  );

  const dispatch = useDispatch();

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  useEffect(() => {
    if (organization) {
      dispatch(
        loadSubscriptions({
          organizationId,
          search: sortType && {
            sort: `${sortOrder === "descend" ? "-" : ""}${sortType}`
          }
        })
      );
    }
  }, [organizationId, organization, sortType, sortOrder]);

  const isLoadingList = useSelector(loadingListSelector);

  const [hasProjects, setHasProjects] = useState(false);

  const isLoading = layoutIsLoading && sortIsLoading && orderIsLoading;

  if (isLoading || isLoadingList) {
    return (
      <ContentLayout>
        <ProjectsSkeleton />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout>
      {hasProjects && (
        <ProjectFilterBar
          sortTypes={ORGANIZATION_PROJECT_SORT_TYPES}
          toggleLayout={value => toggleLayout(value ? "grid" : "list")}
          gridLayout={layout !== "list"}
          organization={organizationId}
          sortType={sortType || "name"}
          setSortType={setSortType}
          sortOrder={sortOrder || "ascend"}
          setSortOrder={setSortOrder}
        />
      )}
      {layout !== "list" ? (
        <LiveMessage
          message="project list displayed as a grid"
          aria-live="polite"
        />
      ) : (
        <LiveMessage
          message="project list displayed as a list"
          aria-live="polite"
        />
      )}
      <Projects
        organizationId={organizationId}
        gridLayout={layout !== "list"}
        sortType={sortType}
        setSortType={setSortType}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        setHasProjects={setHasProjects}
      />
    </ContentLayout>
  );
};

export default withReducers({
  project: () => import("Reducers/project"),
  plan: () => import("Reducers/plan"),
  region: () => import("Reducers/project/region"),
  subscription: () => import("Reducers/subscription"),
  search: () => import("Reducers/app/search"),
  organizationSubscription: () => import("Reducers/organization/subscription")
})(OrganizationProjects);
