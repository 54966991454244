import React from "react";

import { withReducers } from "Hocs";

import Vouchers from "./containers/Vouchers";
import Layout from "../components/Layout";
import ContentLayout from "Components/ContentLayout";
import { useParams } from "react-router-dom";

const Page = () => {
  const { username } = useParams();
  return (
    <Layout>
      <ContentLayout id="settings-billing" className="settings-content">
        <Vouchers userId={username} />
      </ContentLayout>
    </Layout>
  );
};

export default withReducers({
  setup: () => import("Reducers/project/setup"),
  plan: () => import("Reducers/plan"),
  order: () => import("Reducers/user/order"),
  profile: () => import("Reducers/profile")
})(Page);
