import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import useMediaQuery from "Hooks/useMediaQuery";

import { withReducers } from "Hocs";
import { getSystemStatus } from "Reducers/systemStatus";

import Loading from "Components/Loading";
import StatusBanner from "../SystemStatusBanner";

import * as S from "./styles";

const SystemStatus = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onDesktop = useMediaQuery("(min-width: 768px)");

  const status = useSelector(state => state.systemStatus?.get("status"));
  const activities = useSelector(state => {
    const maintenances = state.systemStatus
      ?.get("maintenances", [])
      ?.filter(elt => elt.status === "in_progress");
    const incidents = state.systemStatus
      ?.get("incidents", [])
      ?.filter(elt => elt.status !== "resolved");
    return [...maintenances, ...incidents];
  });
  const loading = useSelector(state => state.systemStatus?.get("loading"));

  useEffect(() => {
    if (loading === "idle") dispatch(getSystemStatus());
  }, []);

  if (!status) return null;

  // Display widget on mobile only when an incident is in progress
  if (!onDesktop && status.indicator === "none") return null;

  return (
    <S.Wrapper id="status">
      <S.Header>
        {intl.formatMessage({
          id: "system_status.title",
          defaultMessage: "System status"
        })}

        {status?.url && (
          <a href={status.url} target="_blank">
            {intl.formatMessage({
              id: "system_status.link",
              defaultMessage: "Visit status page"
            })}
          </a>
        )}
      </S.Header>

      {loading !== false ? (
        <Loading />
      ) : (
        <>
          <StatusBanner />
          {status?.indicator !== "none" && activities.length > 0 && (
            <S.ActivityList>
              <dt>
                {intl.formatMessage({
                  id: "system_status.activities",
                  defaultMessage: "Current activity"
                })}
              </dt>
              {activities.map(elt => (
                <dd key={elt.id}>
                  <a
                    href={elt.shortlink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {elt.name}
                  </a>
                </dd>
              ))}
            </S.ActivityList>
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default withReducers({
  systemStatus: () => import("Reducers/systemStatus")
})(SystemStatus);
