import React from "react";
import { useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import EnvironmentBackupPane from "./containers/EnvironmentBackupPane";

import { withReducers } from "Hocs";

const EnvironmentActionsBackup = () => {
  const { push } = useHistory();
  const params = useDecodedParams();
  return <EnvironmentBackupPane push={push} {...params} />;
};

export default withReducers({
  backup: () => import("Reducers/environment/actions/backup")
})(EnvironmentActionsBackup);
