import { useEffect, useMemo } from "react";

import {
  explodeMetrics,
  groupByTimestamp,
  mergeSameTimestampData
} from "../utils/transformMetrics";
import sortAlphabetically from "../utils/sortAlphabetically";
import objectToFields from "../utils/objectToFields";

import useMetricsLink from "./useMetricsLink";
import useMetrics from "./useMetrics";

const GRID_FIELDS = {
  "cpu.average.value":
    "SUM((`cpu.user` + `cpu.kernel`) / `interval`, 'service')",
  "cpu.average.percentage":
    "SUM(100 * (`cpu.user` + `cpu.kernel`) / ( `interval` * `cpu.cores`), 'service')",
  "cpu.max": "`cpu.cores`",
  "memory.average.value":
    "SUM(`memory.apps` + `memory.kernel` + `memory.buffers`, 'service')",
  "memory.average.percentage":
    "SUM(100 * (`memory.apps` + `memory.kernel` + `memory.buffers`) / `memory.limit`, 'service')",
  "memory.max": "`memory.limit`",
  "disk.average.value": "`disk.space.used`",
  "disk.average.percentage": "(`disk.space.used`/`disk.space.limit`)*100",
  "disk.max": "AVG(`disk.space.limit`, 'mountpoint', 'service')"
};

const DEDICATION_FIELDS = {
  "cpu.average.value":
    "AVG(SUM((`cpu.user` + `cpu.kernel`) / `interval`, 'hostname', 'service', 'instance'), 'service', 'instance')",
  "cpu.average.percentage":
    "AVG(100 * SUM((`cpu.user` + `cpu.kernel`) / (`interval` * `cpu.cores`), 'hostname', 'service', 'instance'), 'service', 'instance')",
  "cpu.max": "`cpu.cores`",
  "memory.average.value":
    "AVG(SUM(`memory.apps` + `memory.kernel` + `memory.buffers`, 'hostname', 'service', 'instance'), 'service', 'instance')",
  "memory.average.percentage":
    "AVG(SUM(100 * (`memory.apps` + `memory.kernel` + `memory.buffers`) / `memory.limit`, 'hostname', 'service', 'instance'), 'service', 'instance')",
  "memory.max": "`memory.limit`",
  "disk.average.value":
    "AVG(`disk.space.used`, 'mountpoint', 'service', 'instance')",
  "disk.average.percentage":
    "AVG((`disk.space.used`/`disk.space.limit`)*100, 'mountpoint', 'service', 'instance')",
  "disk.max": "AVG(`disk.space.limit`, 'mountpoint', 'service', 'instance')"
};

const INTERVAL = 60 * 10;
const GRID_RANGE = 60 * 60 * 8;
const G3_RANGE = 60 * 60 * 24;

const getServiceMetrics = metrics => {
  if (!metrics?.length) {
    return undefined;
  }
  const mountpoints = sortAlphabetically(
    Array.from(
      new Set(
        metrics
          .map(({ mountpoint }) => mountpoint)
          .filter(mountpoint => mountpoint != null && mountpoint !== "/tmp")
      )
    )
  ).reduce((mountpoints, mountpoint) => {
    const mountpointMetrics = groupByTimestamp(
      metrics.filter(point => point.mountpoint === mountpoint)
    ).map(mergeSameTimestampData);

    if (mountpointMetrics.length) {
      return {
        ...mountpoints,
        [mountpoint]: mountpointMetrics
      };
    }

    return mountpoints;
  }, {});
  const cpu = groupByTimestamp(
    metrics.filter(point => point.type === "cpu")
  ).map(mergeSameTimestampData);
  const memory = groupByTimestamp(
    metrics.filter(point => point.type === "memory")
  ).map(mergeSameTimestampData);

  return {
    cpu,
    memory,
    ...mountpoints
  };
};

const useService = name => {
  const { collection, type, href } = useMetricsLink();
  const query = useMemo(
    () => ({
      interval: `${INTERVAL}s`,
      fields: objectToFields(type === "grid" ? GRID_FIELDS : DEDICATION_FIELDS),
      stream: {
        collection: collection,
        stream: "metrics"
      },
      filters: [{ key: "service", value: name }]
    }),
    [collection, name, type]
  );

  const range = useMemo(
    () => (type === "grid" ? GRID_RANGE : G3_RANGE),
    [type]
  );

  const [data, isLoading, metricsError] = useMetrics(href, query, range);

  useEffect(() => {
    if (metricsError) {
      throw metricsError;
    }
  }, [metricsError]);

  const parsedData = useMemo(() => {
    if (data?.metrics?.length) {
      return getServiceMetrics(explodeMetrics(data));
    }
    return [];
  }, [data]);

  return [parsedData, isLoading, range / (60 * 60), type];
};

export default useService;
