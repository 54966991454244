import React from "react";
import { useParams } from "react-router-dom";

import ContentLayout from "Components/ContentLayout";

import List from "./containers/List";

import Layout from "../../components/Layout";

import { withReducers } from "Hocs";

const OrganizationBilling = () => {
  const { organizationId } = useParams();

  return (
    <Layout>
      <ContentLayout id="settings-billing" className="settings-content">
        <List organizationId={organizationId} />
      </ContentLayout>
    </Layout>
  );
};

export default withReducers({
  project: () => import("Reducers/project"),
  organizationSubscription: () => import("Reducers/organization/subscription")
})(OrganizationBilling);
