import React from "react";
import { useIntl } from "react-intl";

import { withReducers } from "Hocs";
import useDecodedParams from "Hooks/useDecodedParams";

import { BackLink } from "ds/Button";

import ContentLayout from "Components/ContentLayout";
import Form from "../components/Form";

import * as S from "./Form.styles";

const FormPage = () => {
  const intl = useIntl();
  const { organizationId } = useDecodedParams();

  return (
    <S.Layout>
      <ContentLayout>
        <S.Header>
          <BackLink
            to={`/${organizationId}/-/billing/vouchers`}
            from="vouchers"
            css={{ "text-transform": "none" }}
          />
          <S.Heading>
            {intl.formatMessage({ id: "voucher.form.title" })}
          </S.Heading>
          <S.Intro>{intl.formatMessage({ id: "vouchers.intro" })}</S.Intro>
        </S.Header>

        <S.FormWrapper>
          <Form />
        </S.FormWrapper>
      </ContentLayout>
    </S.Layout>
  );
};

export default withReducers({
  voucher: () => import("Reducers/voucher")
})(FormPage);
