import React from "react";

import { withReducers } from "Hocs";

import List from "./containers/List";

const Page = () => <List />;

export default withReducers({
  tickets: () => import("Reducers/tickets/list")
})(Page);
