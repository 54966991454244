import React, { FC, ReactNode } from "react";

import * as S from "./Radio.styles";

export interface Props {
  /** Field value */
  value: string;
  /** The label value describing the option */
  label: ReactNode;
  id: string;
  /** Sets the initial selected value on the RadioGroup */
  defaultValue: string;
  /** Marks this as a required field */
  required?: boolean;
  /** Field disabled */
  disabled?: boolean;
  /** If false, field is invalid;*/
  isvalid?: boolean;
}

/**
 * How to import:  `import * as Radio from 'ds/Radio';`
 *
 * Component is based on Radix primitive: https://www.radix-ui.com/docs/primitives/components/radio-group
 */

export const Input: FC<Props> = ({
  value,
  label,
  defaultValue,
  id,
  disabled,
  required,
  isvalid = true,
  ...props
}) => {
  return (
    <S.Wrapper>
      <S.Item
        {...props}
        value={value}
        id={id}
        defaultValue={defaultValue}
        disabled={disabled}
        required={required}
        isvalid={isvalid}
        tabIndex={0}
      >
        <S.Indicator isvalid={isvalid} />
      </S.Item>
      <S.Label htmlFor={id} disabled={disabled}>
        {label}
      </S.Label>
    </S.Wrapper>
  );
};

export default Input;
