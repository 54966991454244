import styled from "styled-components";
import LiveBtn from "Components/LiveButton";
import { SettingsIcon as BaseSettingsIcon } from "@platformsh/ui-kit";
import { css, ICON } from "Libs/themes";

export const LiveButton = styled(LiveBtn)``;

export const ColumnWrapper = styled.div`
  .services {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 360px;
    h2 {
      margin: 0;
      padding: 32px 32px 38px;
    }
    > div {
      height: 100%;
      padding: 0 32px 14px;
    }
  }
  .project-info {
    h2 {
      margin-bottom: 0;
    }

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
    }
  }
  > section {
    overflow: hidden;
  }
  .project-setup-image {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  @media (min-width: 600px) {
    > section {
      overflow: visible;
    }
  }
  @media (min-width: 768px) {
    > aside {
      display: flex;
      width: 100%;
      section {
        &:first-child {
          margin-right: 32px;
        }
      }
    }
  }
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    > aside.col-4 {
      flex-grow: 1;
      margin-right: 32px;
      display: block;
      width: inherit;
      max-width: 437px;
      min-width: auto;
    }
    > div {
      flex-grow: 2;
      width: 63%;
      max-width: calc(100% - 464px);
      box-sizing: border-box;
    }
  }
`;

export const BoxLayout = styled.div`
  &.activity {
    padding: 32px 32px 16px 32px;
    h2 {
      margin: 0 0 31px;
      line-height: 22px;
    }
  }
`;

export const RowLayout = styled.div`
  display: grid;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SettingsButton = styled.button`
  border: none;
  background: none;
  display: flex;
`;

export const SettingsIcon = styled(BaseSettingsIcon)`
  cursor: pointer;
  fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
`;
