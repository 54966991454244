import { WarningIcon } from "@platformsh/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import type Subscription from "platformsh-client/types/model/Subscription";
import { useIntl } from "react-intl";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { Button, ButtonWrapper } from "ds/Button";
import InputField from "Components/fields/InputField";
import ModalWrapper from "Components/Modal";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import LoadingIcon from "Icons/LoadingIcon";
import { loadingSelector } from "Reducers/organization/subscription";
import {
  capitalize,
  checkGoLive,
  getRegionLabel,
  interpolateURL
} from "Libs/utils";
import { environmentSelector, loadEnvironment } from "Reducers/environment";
import { organizationSelector } from "Reducers/organization";
import { gitProjectSelector, loadProject } from "Reducers/project";

import { LiveTag, LoadingWrapper, ModalBody } from "./styles";

type DeleteSubscriptionModalPropType = {
  subscription: Subscription;
  isOpen: boolean;
  onClose: () => void;
  onDelete: (event: any) => void;
};

const DeleteSubscriptionModal = (prop: DeleteSubscriptionModalPropType) => {
  const { subscription, isOpen, onClose, onDelete } = prop;

  const formatMessage = useIntl().formatMessage;
  const [confirmSubscriptionName, setConfirmSubscriptionName] = useState("");
  const dispatch = useDispatch();

  const isLoadingSubscription = useSelector(loadingSelector);

  const { project_region_label, project_title, owner, project_id } =
    subscription;
  const { title, suffix } = getRegionLabel(project_region_label);
  const { project, organization } = useSelector(state => {
    const organization = organizationSelector(state, { organizationId: owner });
    return {
      project: gitProjectSelector(state, {
        organizationId: organization?.name,
        projectId: project_id
      }),
      organization
    };
  });

  const { environmentId, organizationId, projectId } = {
    environmentId: project?.default_branch,
    organizationId: organization?.name,
    projectId: project.id
  };

  const mainEnv = useSelectorWithUrlParams(environmentSelector, {
    environmentId,
    organizationId,
    projectId
  });

  useEffect(() => {
    if (project)
      dispatch(
        //@ts-ignore TODO fix when loadEnvironment is moved to .ts
        loadEnvironment({ organizationId, projectId, environmentId })
      );
  }, [project]);

  const mainUrl = useMemo(() => {
    const url = project.default_domain || mainEnv?.data.edge_hostname;
    if (url) return `https://${url}`;
  }, [mainEnv, project]);

  useEffect(() => {
    project_id && dispatch(loadProject(project_id, false));
  }, [project_id]);

  const allowDelete = !!(
    project_title && confirmSubscriptionName === project_title
  );

  const handleDelete = (event: any) => {
    event.preventDefault();
    if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
      window.location.href = interpolateURL(
        process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
        { projectId }
      );
    }
    onDelete(event);
  };

  return (
    <ModalWrapper
      modalClass={`modal-medium`}
      isOpen={isOpen}
      closeModal={onClose}
      title={"Delete project"}
    >
      {subscription.id ? (
        <>
          <ModalBody
            className="modal-body"
            data-testid="delete-subscription-body"
          >
            <div>
              <p>
                {formatMessage(
                  {
                    id: "settings.plan.project.delete.confirm"
                  },
                  {
                    projectTitle: project_title,
                    strong: txt => (
                      <strong className="project-title">{txt}</strong>
                    ),
                    br: <br />
                  }
                )}
              </p>
            </div>
            <h2 className="project-title">{project_title}</h2>

            <div className="project-info">
              <span className="organization-name">{organization?.label}</span>
              <span className="region-name">{title} </span>
              <span className="region-code">
                {suffix ? `[${suffix}]` : null}
              </span>
            </div>

            {mainUrl && (
              <div className="deployment">
                <a
                  className="deployment-link"
                  href={mainUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {mainUrl}
                </a>
                {checkGoLive(project, organization) === "live" && (
                  <LiveTag>{formatMessage({ id: "live" })}</LiveTag>
                )}
              </div>
            )}

            <div className="confirm-delete-input">
              <InputField
                autocomplete="off"
                id={"confirm-input"}
                label={formatMessage({
                  id: "subscription.input.delete.confirm-name-label"
                })}
                placeholder={formatMessage({
                  id: "project.settings.basic.form.name"
                })}
                requiredMessage={formatMessage({
                  id: "project.settings.basic.form.name"
                })}
                required
                value={confirmSubscriptionName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setConfirmSubscriptionName(event.target.value)
                }
              />
            </div>
            <div className="caution">
              <div>
                <WarningIcon />
              </div>
              <p className="caution-text">
                {formatMessage({ id: "subscription.delete.caution" })}
              </p>
            </div>
          </ModalBody>
          <ButtonWrapper spacing="modal" justifyContent="end">
            {isLoadingSubscription !== "idle" && isLoadingSubscription ? (
              <LoadingIcon size={34} />
            ) : (
              <>
                <Button
                  variant="secondary"
                  aria-label={formatMessage({ id: "cancel" })}
                  onClick={onClose}
                >
                  {capitalize(formatMessage({ id: "cancel" }))}
                </Button>
                <Button
                  data-testid="confirm-delete-button"
                  disabled={!allowDelete}
                  aria-label={capitalize(formatMessage({ id: "delete" }))}
                  onClick={handleDelete}
                >
                  {capitalize(
                    formatMessage({ id: "subscription.button.delete" })
                  )}
                </Button>
              </>
            )}
          </ButtonWrapper>
        </>
      ) : (
        <LoadingWrapper>
          <LoadingIcon />
        </LoadingWrapper>
      )}
    </ModalWrapper>
  );
};

export default DeleteSubscriptionModal;
