import styled from "styled-components";

import { css, SECTION } from "Libs/themes";

import Heading2 from "Components/styleguide/Heading2";

export const Wrapper = styled.div`
  margin-top: 8px;
  min-height: calc(100vh - 312px);
  padding: 100px 0 0 0;
  border-radius: 2px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  text-align: center;
`;

export const Heading = styled(Heading2)`
  margin-top: 16px;
  margin-bottom: 0;
`;

export const Intro = styled.p`
  margin: 16px 0 40px 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;
