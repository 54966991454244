import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { withReducers } from "Hocs";
import {
  updateSubscription,
  errorsSelector
} from "Reducers/organization/subscription";

import {
  BACKUP_DESCRIPTIONS,
  BACKUP_TYPES,
  GIG,
  STORAGE_SUFFIX
} from "Constants/constants";

import logger from "Libs/logger";
import Redirect from "Components/Redirect";
import LoadingIcon from "Icons/LoadingIcon";

import { getUrls } from "Libs/utils";

import BackupsDropdown from "./BackupsDropdown/BackupsDropdown";
import PricingTable from "./PricingTable/PricingTable";
import Dropdown from "Components/Dropdown";
import Button from "ds/Button";
import PlanInfo from "./PlanInfo";
import Description from "Components/fields/Description";
import ModalTrial from "Components/ModalTrial";

import * as S from "./styles";

const bigDevMap = {
  "": "Standard",
  PLATFORM_FEATURE_M_DEV: "Medium",
  PLATFORM_FEATURE_L_DEV: "Large",
  PLATFORM_FEATURE_XL_DEV: "Xlarge",
  PLATFORM_FEATURE_2XL_DEV: "2Xlarge",
  PLATFORM_FEATURE_4XL_DEV: "4Xlarge"
};

const environmentOptions = [3, 6, 9, 12, 15, 18, 21].map(value => ({
  value,
  label: value
}));

const generateDropdownScruct = valuesMap =>
  Object.entries(valuesMap).map(([key, value]) => ({
    value: key,
    label: value
  }));

/**
 * Displays the amount field for each category
 * @param {string} amount - estimated amount for the category
 * @param {bool} includedText - if true, will display "Included" text instead of $0 amount (doesn't apply to Plan or Total fields)
 * @param {bool} showFootnote - indicates whether to display asterisk (*) when value updated (Backups, storage, and plan fields only)
 * @returns JSX fragment
 */
const amountField = (amount = "", includedText, showFootnote) => {
  if (!amount) return null;
  const digits = amount?.replace(/\D+/g, "");
  const zeroAmount = digits * 1 === 0;
  const includedFormat = includedText && zeroAmount;
  return (
    <div className={`total ${includedFormat ? "included" : ""}`}>
      {includedFormat ? "Included" : amount}
      {showFootnote && !includedFormat && "*"}
    </div>
  );
};

const ProjectPlanForm = ({
  subscription,
  organizationNameId,
  organizationProfile,
  setErrors,
  isOnTrial,
  isLoadingSubscription,
  currency
}) => {
  const [modalTrialOpen, openModalTrial] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [planChanged, setPlanChanged] = useState(false);
  const [storageChanged, setStorageChanged] = useState(false);
  const [backupsChanged, setBackupsChanged] = useState(false);
  const [resetFormKey, setResetFormKey] = useState(new Date().getTime());
  const [initEstimate, setInitEstimate] = useState();
  const [estimate, setEstimate] = useState();
  const [estimateSub, setEstimateSub] = useState();

  const intl = useIntl();
  const dispatch = useDispatch();

  const error = useSelector(state => {
    const error = errorsSelector(state);
    return error?.title || error?.message;
  });

  useEffect(() => {
    if (error) setErrors?.(error);
  }, [error]);

  const deletedSubscription = useSelector(state =>
    state.organizationSubscription?.get("deleted")
  );

  const bigDevTypes = useMemo(() => generateDropdownScruct(bigDevMap), []);

  const {
    backups,
    plan,
    environments,
    storage,
    user_licenses,
    big_dev,
    project_options
  } = subscription;

  const formatBackupDropdown = options =>
    options.map(key => {
      const backupLabel = BACKUP_TYPES[key]?.label || "";
      return {
        value: key,
        label: (
          <p>
            <b>{backupLabel}</b>
            <br />
            {intl.formatMessage(
              {
                id: `backups.dropdown.${backupLabel}.plan`,
                defaultMessage: BACKUP_DESCRIPTIONS[backupLabel]
              },
              {
                br: <br />
              }
            )}
          </p>
        ),
        selectedLabel: backupLabel
      };
    });

  // Display the purchased sellable if any; otherwise display default and sellable in dropdown
  const currentBackupPlan = subscription?.data?.backups
    ? [subscription.data.backups]
    : [];
  const defaultBackupOption = project_options?.features?.backups?.default || [];
  const sellableBackupOption =
    project_options?.features?.backups?.available || [];

  const backupOptions =
    currentBackupPlan.length > 0
      ? formatBackupDropdown(currentBackupPlan)
      : formatBackupDropdown(defaultBackupOption?.concat(sellableBackupOption));

  const initialBackup = backupOptions[0];
  const pricePerGig = estimate?.options?.backups;
  const numberManual = BACKUP_TYPES[initialBackup?.value]?.manual || 2;
  const numberAutomated = BACKUP_TYPES[initialBackup?.value]?.automated || 2;

  const initEstimateSub = () => {
    setEstimateSub({
      backups,
      plan,
      environments,
      storage,
      user_licenses,
      big_dev
    });
  };

  const planOptions = Object.keys(project_options?.plan_titles || {}).map(
    value => ({
      value,
      label: project_options.plan_titles[value]
    })
  );

  useEffect(() => {
    if (subscription?.id) initEstimateSub();
  }, [subscription?.id]);

  useEffect(() => {
    let isCanceled = false;
    const getEstimation = async () => {
      const parameters = {
        plan: estimateSub.plan,
        storage: estimateSub.storage,
        environments: estimateSub.environments,
        user_licenses: estimateSub.user_licenses,
        big_dev: estimateSub.big_dev || "",
        backups: estimateSub.backups || ""
      };
      try {
        const estimation = await subscription.getEstimate(parameters);
        setErrors?.();
        if (isCanceled) {
          return;
        }
        setEstimate(estimation);
        if (!initEstimate) setInitEstimate(estimation);
      } catch (error) {
        setErrors?.(error.title || error.detail);
        logger(error, {
          action: "subscription plan estimation",
          parameters
        });
      }
    };
    if (estimateSub) getEstimation();

    return () => (isCanceled = true);
  }, [estimateSub]);

  const storageOptions = () => {
    const currentStorageGig = subscription?.storage / GIG;

    let opts = [];
    for (let i = currentStorageGig; i <= 100; i += 5) {
      opts.push({
        value: i * GIG,
        label: `${i + STORAGE_SUFFIX}`
      });
    }

    return opts;
  };

  const handleChange = (event, field, setFieldChangedFunction) => {
    setEstimateSub(state => {
      return { ...state, [field]: event.value };
    });
    setIsChanged(true);
    setFieldChangedFunction && setFieldChangedFunction(true);
  };

  const resetChangedFields = () => {
    setIsChanged(false);
    setPlanChanged(false);
    setBackupsChanged(false);
    setStorageChanged(false);
  };

  const handleCancel = () => {
    initEstimateSub();
    setResetFormKey(new Date().getTime());
    resetChangedFields();
  };

  const canUpdate = () => {
    if (!isOnTrial) return true;
    const { current_trial } = organizationProfile;
    const used = current_trial?.current.amount;
    const spend = current_trial?.spend.amount;
    const initCost = parseInt(initEstimate.total);
    const newCost = parseInt(estimate.total);

    return spend >= used - initCost + newCost;
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!canUpdate()) {
      openModalTrial(true);
      return false;
    }

    dispatch(updateSubscription({ updates: estimateSub, subscription }));
    if (!error) resetChangedFields();
  };

  const currentPlan = planOptions.find(
    ({ value }) => value === subscription?.plan
  );

  if (!estimate) return <LoadingIcon />;

  const canEdit = subscription?.hasLink("update");

  return (
    <S.FormWrapper onSubmit={handleSubmit}>
      {deletedSubscription && (
        <Redirect
          to={
            getUrls({ organizationName: organizationNameId })
              .organizationBillingPlanUrl
          }
        />
      )}
      {subscription && (
        <>
          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="plan-type options">
                  <Dropdown
                    key={resetFormKey}
                    options={planOptions}
                    defaultValue={currentPlan}
                    onChange={event =>
                      handleChange(event, "plan", setPlanChanged)
                    }
                    clearable={false}
                    fieldType={true}
                    required={true}
                    withBorder={false}
                    disabled={isOnTrial || !canEdit}
                    searchable={false}
                  />
                  {amountField(estimate.plan, false, planChanged)}
                </div>
                <div className="label">
                  {intl.formatMessage({
                    id: "project.plan.type",
                    defaultMessage: "Plan"
                  })}
                  {isOnTrial && (
                    <S.StyledInfoDialog
                      align="left"
                      text={intl.formatMessage({
                        id: "project.plan.trial.info",
                        defaultMessage:
                          "Free trials are limited to development only plans, add your billing details to change your plan."
                      })}
                      linkText={intl.formatMessage({
                        id: "project.plan.trial.info.link",
                        defaultMessage: "Add billing details"
                      })}
                      to={`/${organizationNameId}/-/billing`}
                    />
                  )}
                </div>
              </div>
            </S.Heading>
            <S.Includes
              className={
                estimateSub.plan === "development" ? "development" : ""
              }
            >
              <div className="label">
                {intl.formatMessage({
                  id: "project.plan.includes.title",
                  defaultMessage: "Includes"
                })}
              </div>
              <div className="features">
                <PlanInfo plan={estimateSub.plan} />
              </div>
            </S.Includes>
          </S.Row>

          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="environments options">
                  <Dropdown
                    key={resetFormKey}
                    options={environmentOptions}
                    defaultValue={{
                      value: subscription.environments,
                      label: subscription.environments
                    }}
                    onChange={event => handleChange(event, "environments")}
                    clearable={false}
                    fieldType={true}
                    required={true}
                    disabled={!canEdit}
                    searchable={false}
                  />
                  {amountField(estimate.environments, true)}
                </div>
                <div className="label">
                  {intl.formatMessage({
                    id: "project.plan.environment.title",
                    defaultMessage: "Environments"
                  })}
                </div>
                <div className="price">
                  {intl.formatMessage(
                    {
                      id: "project.plan.environment.estimation",
                      defaultMessage: `${estimate?.options?.environments} / 3 environments`
                    },
                    { estimation: estimate?.options?.environments }
                  )}
                </div>
              </div>
            </S.Heading>
          </S.Row>

          {/* Environments application size */}
          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="app-size options">
                  <Dropdown
                    key={resetFormKey}
                    options={bigDevTypes}
                    defaultValue={(() => {
                      const devSize = subscription?.big_dev;
                      return {
                        value: devSize || bigDevTypes[0].value,
                        label: bigDevMap[devSize] || bigDevTypes[0].label
                      };
                    })()}
                    onChange={event => handleChange(event, "big_dev")}
                    clearable={false}
                    fieldType={true}
                    required={true}
                    disabled={!canEdit}
                    searchable={false}
                  />
                  {amountField(estimate.big_dev, true)}
                </div>
                <div className="label">
                  {intl.formatMessage({ id: "big_dev_title" })}
                </div>
                <div className="price">
                  {intl.formatMessage({ id: "big_dev_desc" })}
                  <S.StyledInfoDialog
                    className="app-size-info"
                    align="right"
                    text={intl.formatMessage({
                      id: "environment_application_size_message"
                    })}
                  />
                </div>
              </div>
            </S.Heading>
          </S.Row>

          {/* Storage */}
          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="storage options">
                  <Dropdown
                    key={resetFormKey}
                    options={storageOptions()}
                    defaultValue={{
                      value: subscription.storage,
                      label: `${subscription.storage / GIG}${STORAGE_SUFFIX}`
                    }}
                    onChange={event =>
                      handleChange(event, "storage", setStorageChanged)
                    }
                    clearable={false}
                    fieldType={true}
                    required={true}
                    disabled={!canEdit}
                    searchable={false}
                  />
                  {amountField(estimate.storage, true, storageChanged)}
                </div>
                <div className="label">
                  {intl.formatMessage({
                    id: "project.plan.storage.title",
                    defaultMessage: "Storage"
                  })}
                </div>
                <div className="price">
                  {intl.formatMessage(
                    {
                      id: "project.plan.storage.estimation",
                      defaultMessage: `${estimate?.options?.storage} / 5 GB per environment`
                    },
                    {
                      estimation: estimate?.options?.storage
                    }
                  )}
                </div>
              </div>
            </S.Heading>
          </S.Row>

          {/* Backups -- section will be hidden behind feature flag for now */}
          {process.env.ENABLE_AUTOMATED_BACKUPS &&
            !!sellableBackupOption?.length > 0 && (
              <S.Row>
                <S.Heading>
                  <div className="info">
                    <div className="backup options">
                      {!!pricePerGig && (
                        <BackupsDropdown
                          currency={currency}
                          estimate={estimate}
                          estimateSub={estimateSub}
                          pricePerGig={pricePerGig}
                        />
                      )}
                      <Dropdown
                        className="backupDropdown"
                        align="left"
                        key={resetFormKey}
                        options={backupOptions}
                        defaultValue={backupOptions[0]}
                        formatOptionLabel={(option, { context }) => {
                          return context === "menu"
                            ? option.label
                            : option.selectedLabel;
                        }}
                        onChange={event =>
                          handleChange(event, "backups", setBackupsChanged)
                        }
                        clearable={false}
                        fieldType={true}
                        required={true}
                        disabled={!canEdit}
                        searchable={false}
                      />
                      {amountField(estimate.backups, true, backupsChanged)}
                    </div>
                    <div className="label">
                      {intl.formatMessage({
                        id: "project.plan.backups.title",
                        defaultMessage: "Backups"
                      })}
                    </div>
                    <div className="price">
                      {intl.formatMessage(
                        {
                          id: "project.plan.backups.estimation",
                          defaultMessage: `${numberAutomated} automated + ${numberManual} manual included`
                        },
                        {
                          numberAutomated,
                          numberManual
                        }
                      )}
                      <S.StyledInfoDialog
                        className="backups-info"
                        align="center"
                      >
                        <S.TableContainer>
                          <PricingTable />
                        </S.TableContainer>
                      </S.StyledInfoDialog>
                    </div>
                  </div>
                </S.Heading>
              </S.Row>
            )}

          {/* Users */}
          <S.Row>
            <S.Heading>
              <div className="info">
                <div className="users options">
                  <div className="users">{subscription.user_licenses}</div>
                  {amountField(estimate.user_licenses, true)}
                </div>
                <div className="label">
                  {intl.formatMessage({
                    id: "project.plan.users.title",
                    defaultMessage: "Users"
                  })}
                </div>
                <div className="price">
                  {intl.formatMessage(
                    {
                      id: "project.plan.users.estimation",
                      defaultMessage: `${estimate?.options?.user_licenses} / per user per month. Billed when added to your platform`
                    },
                    {
                      estimation: estimate?.options?.user_licenses
                    }
                  )}
                </div>
              </div>
            </S.Heading>
          </S.Row>

          <S.Row className="total-row">
            <S.Heading className="total-wrapper">
              <div className="options">
                <Description className="description">
                  {intl.formatMessage({
                    id: "total_monthly_cost",
                    defaultMessage: "Total monthly cost"
                  })}
                  <S.StyledInfoDialog
                    title={intl.formatMessage({
                      id: "estimated_cost",
                      defaultMessage: "Estimated cost"
                    })}
                    align="right"
                    text={intl.formatMessage({
                      id: "billing_system_explanation",
                      defaultMessage:
                        "Billing is based on actual usage at the end of each month. Prices displayed include VAT when applicable."
                    })}
                  />
                </Description>
                <div className="total">{estimate.total}</div>
              </div>
            </S.Heading>
          </S.Row>

          <>
            {isLoadingSubscription ? (
              <LoadingIcon />
            ) : (
              isChanged &&
              canEdit && (
                <S.Footer>
                  <Button variant="secondary" onClick={handleCancel}>
                    {intl.formatMessage({
                      id: "cancel",
                      defaultMessage: "Cancel"
                    })}
                  </Button>
                  <Button type="submit">
                    {intl.formatMessage({
                      id: "save",
                      defaultMessage: "Save"
                    })}
                  </Button>
                </S.Footer>
              )
            )}
          </>
        </>
      )}

      <ModalTrial
        isOpen={modalTrialOpen}
        organizationNameId={organizationNameId}
        closeModal={() => openModalTrial(false)}
        kind="plan"
      />
    </S.FormWrapper>
  );
};

ProjectPlanForm.propTypes = {
  currency: PropTypes.string,
  hasPaymentSource: PropTypes.bool,
  organizationNameId: PropTypes.string.isRequired,
  organizationProfile: PropTypes.object,
  setErrors: PropTypes.func,
  isOnTrial: PropTypes.bool,
  subscription: PropTypes.object.isRequired,
  isLoadingSubscription: PropTypes.bool,
  tier: PropTypes.string
};

export default withReducers({
  environment: () => import("Reducers/environment"),
  organizationSubscription: () => import("Reducers/organization/subscription"),
  organizationSetup: () => import("Reducers/organization/setup")
})(ProjectPlanForm);
