import React from "react";
import { useHistory } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import EnvironmentRedeployPane from "./containers/EnvironmentRedeployPane";

import { withReducers } from "Hocs";

const EnvironmentActionsRedeploy = () => {
  const { push } = useHistory();
  const params = useDecodedParams();
  return <EnvironmentRedeployPane push={push} {...params} />;
};

export default withReducers({
  redeploy: () => import("Reducers/environment/actions/redeploy")
})(EnvironmentActionsRedeploy);
