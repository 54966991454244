import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import ProjectPage from "./Page";
import OverviewPage from "./overview/Page";
import IntegrationSetup from "./settings/integrations/Setup/Page";
import SettingsRoutes from "./settings/Routes";
import EnvironmentRoutes from "./environment/Routes";
import ProjectInvitationWaitingScreen from "./containers/ProjectInvitationWaitingScreen";
import PluginApp from "./app/Page";

const Routes = () => {
  const { path } = useRouteMatch();
  const { organizationId, projectId } = useParams();

  return (
    <Switch>
      <Route path={`${path}/-/settings/integrations/setup`}>
        <ProjectPage>
          <IntegrationSetup />
        </ProjectPage>
      </Route>

      <Route path={`${path}/-/settings`}>
        <ProjectPage>
          <SettingsRoutes />
        </ProjectPage>
      </Route>

      <Route path={`${path}/-/wait`}>
        <ProjectInvitationWaitingScreen />
      </Route>

      <Route path={`${path}/-/app/:appId`}>
        <ProjectPage>
          <PluginApp
            route="/:organizationId/:projectId/"
            type="tab"
            organizationId={organizationId}
            projectId={projectId}
          />
        </ProjectPage>
      </Route>

      <Route path={`${path}/-/log/:activityId`}>
        <ProjectPage>
          <OverviewPage />
        </ProjectPage>
      </Route>

      <Route path={`${path}/:environmentId`}>
        <ProjectPage NonResponsiveSubNavBar>
          <EnvironmentRoutes />
        </ProjectPage>
      </Route>

      <Route path={path}>
        <ProjectPage disableSubNavBar>
          <OverviewPage />
        </ProjectPage>
      </Route>
    </Switch>
  );
};

export default Routes;
