import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { StoreMapStateType } from "Reducers/types";

import client from "Libs/platform";
import type Order from "platformsh-client/types/model/Order";
import logger from "Libs/logger";

type LoadOrdersThunkArgType = { orgId: string };
type LoadOrderThunkArgType = { orderId: string };

type LoadOrdersThunkReturnType = object;
type HtmlObject = { code: unknown };

const getHtmlCode = (e: unknown): number | undefined => {
  if (
    (e as HtmlObject).code !== undefined &&
    typeof (e as HtmlObject).code === "number"
  ) {
    return <number>(e as HtmlObject).code;
  }
  return undefined;
};

export const loadOrders = createAsyncThunk<
  LoadOrdersThunkReturnType,
  LoadOrdersThunkArgType
>("app/orders/load_orders", async ({ orgId }, { rejectWithValue }) => {
  try {
    const orders = await client.getOrders(orgId);
    return orders.reduce((groupedOrders: StoreMapStateType, order: Order) => {
      if (order.status === "recurring_open") {
        return groupedOrders.setIn(["recurring", order.id], order);
      } else {
        return groupedOrders.setIn(["previous", order.id], order);
      }
    }, Map());
  } catch (err: unknown) {
    return rejectWithValue({ errors: err });
  }
});

export const loadOrder = createAsyncThunk<
  LoadOrdersThunkReturnType,
  LoadOrderThunkArgType
>("app/orders/load_order", async ({ orderId }, { rejectWithValue }) => {
  try {
    return await client.getOrder(orderId);
  } catch (err: unknown) {
    const htmlCode = getHtmlCode(err);
    if (typeof htmlCode === "number" && ![404, 403].includes(htmlCode)) {
      logger(err as Error, {
        action: "loadOrders",
        orderId
      });
    }
    return rejectWithValue({ errors: err });
  }
});

export const loadRecurringOrder = createAsyncThunk<
  LoadOrdersThunkReturnType,
  LoadOrdersThunkArgType
>("app/orders/load_recurring_order", async ({ orgId }, { rejectWithValue }) => {
  try {
    const orders = await client.getOrders(orgId);

    return orders[0];
  } catch (err: unknown) {
    const htmlCode = getHtmlCode(err);
    if (typeof htmlCode === "number" && ![404, 403].includes(htmlCode)) {
      logger(err as Error, {
        action: "loadOrders",
        orgId
      });
    }
    return rejectWithValue({ errors: err });
  }
});

const orders = createSlice({
  name: "app/orders",
  initialState: Map() as StoreMapStateType,
  reducers: {},
  extraReducers: builder => {
    // LOAD ORDER
    builder.addCase(loadOrder.pending, (state, action) => {
      return state.setIn(["byId", action.meta.arg.orderId, "loading"], true);
    });
    builder.addCase(loadOrder.fulfilled, (state, action) => {
      return state
        .setIn(
          ["byId", action.meta.arg.orderId, "data"],
          fromJS(action.payload)
        )
        .setIn(["byId", action.meta.arg.orderId, "loading"], false);
    });
    builder.addCase(loadOrder.rejected, (state, action) => {
      return state.set("loading", false).set("errors", action.payload);
    });

    // LOAD ORDERS
    builder.addCase(loadOrders.pending, state => {
      return state.set("loading", true);
    });
    builder.addCase(loadOrders.fulfilled, (state, action) => {
      return state
        .setIn(["data", action.meta.arg.orgId], fromJS(action.payload))
        .set("loading", false);
    });
    builder.addCase(loadOrders.rejected, (state, action) => {
      return state.set("loading", false).set("errors", action.payload);
    });

    // LOAD RECURRING ORDER
    builder.addCase(loadRecurringOrder.pending, state => {
      return state.setIn(["recurring", "loading"], true);
    });
    builder.addCase(loadRecurringOrder.fulfilled, (state, action) => {
      return state
        .setIn(["recurring", "data"], fromJS(action.payload))
        .setIn(["recurring", "loading"], false);
    });
    builder.addCase(loadRecurringOrder.rejected, (state, action) => {
      return state
        .setIn(["recurring", "loading"], false)
        .setIn(["recurring", "errors"], action.payload);
    });
  }
});

export default orders.reducer;
