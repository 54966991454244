import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import logger from "Libs/logger";
import { hasHtml, isJson } from "Libs/utils";
import { updateProject } from "Reducers/project";

/**
 * Load project's domains
 *
 * @param {string} organizationId
 * @param {object} project
 *
 */
export const loadDomains = createAsyncThunk(
  "app/project/domains",
  async ({ organizationId, project }, { rejectWithValue }) => {
    if (!project) return false;

    try {
      const domains = await project.getDomains();
      return domains;
    } catch (err) {
      if (err.code / 100 === 4 && !hasHtml(err)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load domains.";
        logger(errorMessage, {
          action: "loadDomains",
          meta: {
            organizationId,
            projectId: project.id
          }
        });
        return rejectWithValue({ errors: err.detail });
      }
    }
  }
);

/**
 * Add new domain
 *
 * @param {object} data
 * @param {string} organizationId
 * @param {object} project
 *
 */
export const addDomain = createAsyncThunk(
  "app/project/domain/add",
  async ({ data, organizationId, project }, { dispatch, rejectWithValue }) => {
    if (!project) return false;

    try {
      const result = await project.addDomain(data.name);

      if ((result.data && result.data.code / 100) === 3) {
        return rejectWithValue({ error: result.detail });
      }

      const domain = await result?.getEntity();
      if (data.isDefault) {
        dispatch(
          updateProject(organizationId, project.id, {
            default_domain: domain.name
          })
        );
      }
      return domain;
    } catch (err) {
      logger(
        { errorMessage: err.message, projectId: project.id },
        { action: "projectAddDomain" }
      );
      const errors =
        err.detail === "This domain is already claimed by another service"
          ? "This domain is already claimed by another project. If this is incorrect or you are trying to add a subdomain, please open a ticket with support."
          : err.detail;

      return rejectWithValue({ errors });
    }
  }
);

/**
 * Make it the default
 *
 * @param {object} domain
 * @param {string} organizationId
 * @param {object} project
 */
export const makeDefaultDomain = createAsyncThunk(
  "app/project/domain/default",
  async (
    { domain, organizationId, project },
    { dispatch, rejectWithValue }
  ) => {
    try {
      if (domain.id === project.default_domain) return domain;
      dispatch(
        updateProject(organizationId, project.id, {
          default_domain: domain.id
        })
      );
      return domain;
    } catch (err) {
      logger(
        { errorMessage: err.message, projectId: project.id },
        { action: "projectUpdateDomain" }
      );
      const errors =
        err.detail === "This domain is already claimed by another service"
          ? "This domain is already claimed by another project. If this is incorrect or you are trying to add a subdomain, please open a ticket with support."
          : err.detail;

      return rejectWithValue({ errors });
    }
  }
);

/**
 * Update a domain
 *
 * @param {object} data
 * @param {object} domain
 * @param {string} organizationId
 * @param {object} project
 *
 */
export const updateDomain = createAsyncThunk(
  "app/project/domain/update",
  async (
    { data, domain, organizationId, project },
    { dispatch, rejectWithValue }
  ) => {
    try {
      if (domain.id === project.default_domain && !data.isDefault) {
        dispatch(
          updateProject(organizationId, project.id, {
            default_domain: null
          })
        );
      }

      if (domain.id !== project.default_domain && data.isDefault) {
        dispatch(
          updateProject(organizationId, project.id, {
            default_domain: domain.id
          })
        );
      }

      return domain;
    } catch (err) {
      logger(
        { errorMessage: err.message, projectId: project.id },
        { action: "projectUpdateDomain" }
      );
      const errors =
        err.detail === "This domain is already claimed by another service"
          ? "This domain is already claimed by another project. If this is incorrect or you are trying to add a subdomain, please open a ticket with support."
          : err.detail;

      return rejectWithValue({ errors });
    }
  }
);

/**
 * Delete a domain
 *
 * @param {object} domain
 * @param {string} newDefault
 * @param {string} organizationId
 * @param {object} project
 *
 */
export const deleteDomain = createAsyncThunk(
  "app/project/domain/delete",
  async (
    { domain, newDefault, organizationId, project },
    { dispatch, rejectWithValue }
  ) => {
    if (!domain) return false;

    try {
      if (project.default_domain === domain.id && newDefault) {
        dispatch(
          updateProject(organizationId, project.id, {
            default_domain: newDefault
          })
        );
      }
      await domain.delete();
      return domain;
    } catch (err) {
      logger(
        { errMessage: err.message, domainId: domain.id },
        { action: "projectDeleteDomain" }
      );
      return rejectWithValue({ errors: err });
    }
  }
);

const domains = createSlice({
  name: "app/project/domains",
  initialState: Map({ data: Map() }),
  reducers: {
    initDomain(state, { payload }) {
      const { domainId, projectId } = payload;
      return state
        .deleteIn(["errors", "domain", projectId, "new"])
        .deleteIn(["status", "domain", projectId, "new"])
        .deleteIn(["errors", "domain", projectId, domainId])
        .deleteIn(["status", "domain", projectId, domainId]);
    }
  },
  extraReducers: {
    // LOAD LIST
    [loadDomains.pending]: (state, { meta }) => {
      const { project } = meta.arg;
      return state.setIn(["loading", "project", project.id], true);
    },
    [loadDomains.fulfilled]: (state, { meta, payload }) => {
      const { organizationId, project } = meta.arg;
      return state
        .deleteIn(["errors", "project", project.id])
        .setIn(["loading", "project", project.id], false)
        .set(
          "data",
          payload?.reduce((list, domain) => {
            return list.setIn([organizationId, project.id, domain.id], domain);
          }, Map())
        );
    },
    [loadDomains.rejected]: (state, { meta, payload }) => {
      const { project } = meta.arg;
      return state
        .setIn(["loading", "project", project.id], false)
        .setIn(["errors", "project", project.id], fromJS(payload.errors));
    },

    // ADD
    [addDomain.pending]: (state, { meta }) => {
      const { project } = meta.arg;
      return state.setIn(["status", "domain", project.id, "new"], "pending");
    },
    [addDomain.fulfilled]: (state, { meta, payload }) => {
      const { organizationId, project } = meta.arg;
      return state
        .deleteIn(["errors", "domain", project.id, "new"])
        .setIn(["data", organizationId, project.id, payload.id], payload)
        .setIn(["lastUpdated", "domain", project.id], payload.id)
        .setIn(["status", "domain", project.id, "new"], "added");
    },
    [addDomain.rejected]: (state, { meta, payload }) => {
      const { project } = meta.arg;
      return state
        .setIn(["status", "domain", project.id, "new"], "rejected")
        .setIn(["errors", "domain", project.id, "new"], payload.errors);
    },

    // MAKE IT THE DEFAULT
    [makeDefaultDomain.pending]: (state, { meta }) => {
      const { domain, project } = meta.arg;
      return state.setIn(
        ["status", "domain", project.id, domain.id],
        "pending"
      );
    },
    [makeDefaultDomain.fulfilled]: (state, { meta, payload }) => {
      const { domain, organizationId, project } = meta.arg;
      return state
        .deleteIn(["errors", "domain", project.id, domain.id])
        .setIn(
          ["data", organizationId, project.id, payload.id],
          fromJS(payload)
        )
        .setIn(["lastUpdated", "domain", project.id], payload.id)
        .setIn(["status", "domain", project.id, domain.id], "updated");
    },
    [makeDefaultDomain.rejected]: (state, { meta, payload }) => {
      const { domain, project } = meta.arg;
      return state
        .setIn(["errors", "domain", project.id, domain.id], payload.errors)
        .setIn(["status", "domain", project.id, domain.id], "rejected");
    },

    // UPDATE
    [updateDomain.pending]: (state, { meta }) => {
      const { domain, project } = meta.arg;
      return state.setIn(
        ["status", "domain", project.id, domain.id],
        "pending"
      );
    },
    [updateDomain.fulfilled]: (state, { meta, payload }) => {
      const { domain, organizationId, project } = meta.arg;
      return state
        .deleteIn(["errors", "domain", project.id, domain.id])
        .setIn(
          ["data", organizationId, project.id, payload.id],
          fromJS(payload)
        )
        .setIn(["lastUpdated", "domain", project.id], payload.id)
        .setIn(["status", "domain", project.id, domain.id], "updated");
    },
    [updateDomain.rejected]: (state, { meta, payload }) => {
      const { domain, project } = meta.arg;
      return state
        .setIn(["errors", "domain", project.id, domain.id], payload.errors)
        .setIn(["status", "domain", project.id, domain.id], "rejected");
    },

    // DELETE
    [deleteDomain.pending]: (state, { meta }) => {
      const { domain, project } = meta.arg;
      return state.setIn(
        ["status", "domain", project.id, domain.id],
        "pending"
      );
    },
    [deleteDomain.fulfilled]: (state, { meta }) => {
      const { domain, organizationId, project } = meta.arg;
      return state
        .deleteIn(["errors", "domain", project.id, domain.id])
        .deleteIn(["data", organizationId, project.id, domain.id])
        .setIn(["lastUpdated", "domain", project.id], domain.id)
        .setIn(["status", "domain", project.id, domain.id], "deleted");
    },
    [deleteDomain.rejected]: (state, { meta, payload }) => {
      const { domain, project } = meta.arg;
      return state
        .setIn(["errors", "domain", project.id, domain.id], payload.errors)
        .setIn(["status", "domain", project.id, domain.id], "rejected");
    }
  }
});
export const { initDomain } = domains.actions;
export default domains.reducer;

const selectDomain = ({ projectDomain }) => projectDomain || Map();
const getParams = (_, params) => params;

// Project's Domains
export const domainsSelector = createSelector(
  selectDomain,
  getParams,
  (projectDomain, { organizationId, projectId }) =>
    projectDomain.getIn(["data", organizationId, projectId])
);

export const domainsLoadingSelector = createSelector(
  selectDomain,
  getParams,
  (projectDomain, { projectId }) =>
    projectDomain.getIn(["loading", "project", projectId])
);

export const domainsErrorSelector = createSelector(
  selectDomain,
  getParams,
  (projectDomain, { projectId }) =>
    projectDomain.getIn(["errors", "project", projectId])
);

// Domain
export const domainLoadingSelector = createSelector(
  selectDomain,
  getParams,
  (projectDomain, { domainId, projectId }) =>
    projectDomain.getIn(["loading", "domain", projectId, domainId])
);

export const domainStatusSelector = createSelector(
  selectDomain,
  getParams,
  (projectDomain, { domainId, projectId }) =>
    projectDomain.getIn(["status", "domain", projectId, domainId])
);

export const domainErrorSelector = createSelector(
  selectDomain,
  getParams,
  (projectDomain, { domainId, projectId }) =>
    projectDomain.getIn(["errors", "domain", projectId, domainId])
);

export const lastDomainUpdatedSelector = createSelector(
  selectDomain,
  getParams,
  (projectDomain, { projectId }) =>
    projectDomain.getIn(["lastUpdated", "domain", projectId])
);
