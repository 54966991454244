import React, { useState, useRef, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import countryList from "react-select-country-list";

import { capitalize } from "Libs/utils";
import InputField from "Components/fields/InputField";
import Loading from "Components/Loading";
import InfoDialog from "Components/InfoDialog";
import InfoIcon from "Icons/InfoIcon";
import { Button, ButtonWrapper } from "ds/Button";
import Error from "Components/Error";

import * as S from "./styles";

const FIELD_NAME = {
  country: "country",
  label: "label",
  name: "name",
  securityContact: "security_contact",
  companyName: "company_name",
  websiteUrl: "website_url"
};

const isValidOrgName = name =>
  /^(?![-])[a-z0-9-]{3,39}$/.test(name) &&
  name?.indexOf("--") === -1 &&
  name.charAt(name.length - 1) !== "-";

const BasicSettings = ({
  onSave,
  onDelete,
  organization,
  organizationProfile,
  isLoading = false,
  me,
  errors
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const initialOrganizationFormValues = {
    name: organization?.name,
    label: organization?.label,
    country: organization?.country
  };

  const initialProfileFormValue = {
    security_contact: organizationProfile?.security_contact,
    // TODO uncommnet when API adds website_url
    // website_url: organizationProfile?.website_url,
    company_name: organizationProfile?.company_name
  };

  const [organizationFormValues, setOrganizationFormValue] = useState(
    initialOrganizationFormValues
  );

  const [organizationProfileFormValues, setOrgainzationProfileFormValues] =
    useState(initialProfileFormValue);

  const intl = useIntl();
  const theme = useTheme();
  const inputNameRef = useRef();
  let inputLabelRef = useRef();

  let getInputLabelRef = ref => {
    if (!ref) {
      return false;
    }
    inputLabelRef.current = ref;
  };

  useEffect(() => {
    setOrganizationFormValue(initialOrganizationFormValues);
  }, [organization]);

  useEffect(() => {
    setOrgainzationProfileFormValues(initialProfileFormValue);
  }, [organizationProfile]);

  const updatedFormValues = {
    ...organizationFormValues,
    ...organizationProfileFormValues
  };

  const initialFormValues = {
    ...initialOrganizationFormValues,
    ...initialProfileFormValue
  };

  const formIsChanged = useMemo(
    () =>
      Object.keys(initialFormValues).some(
        fieldName =>
          initialFormValues[fieldName] !== updatedFormValues[fieldName]
      ),
    [updatedFormValues]
  );

  const validateFormField = (fieldName, formValues) => {
    switch (fieldName) {
      case FIELD_NAME.label:
        if (!formValues[FIELD_NAME.label])
          return (
            <FormattedMessage
              id="field.is_required"
              values={{ label: fieldName }}
            />
          );
        break;
      case FIELD_NAME.name:
        if (!isValidOrgName(formValues[FIELD_NAME.name]))
          return (
            <FormattedMessage id="organization.input.name.validation-message" />
          );
        break;
      case FIELD_NAME.country:
        if (!formValues[FIELD_NAME.country]) {
          return (
            <FormattedMessage
              id="field.is_required"
              values={{ label: fieldName }}
            />
          );
        }
        break;
    }
  };

  // Validate and return error in each form fields
  const validateForm = formValues => {
    let errors = {};
    for (let key in FIELD_NAME) {
      const fieldName = FIELD_NAME[key];
      errors[fieldName] = validateFormField(fieldName, formValues);
    }
    return errors;
  };

  const onOrganizationChange = (value, fieldName) => {
    const form = { ...organizationFormValues, [fieldName]: value };
    setOrganizationFormValue(form);
    const fieldError = validateFormField(fieldName, form);
    setFormErrors({ ...formErrors, [fieldName]: fieldError });
  };

  const onProfileFieldChange = (value, fieldName) => {
    const form = {
      ...organizationProfileFormValues,
      [fieldName]: value
    };
    setOrgainzationProfileFormValues(form);
    const fieldError = validateFormField(fieldName, form);
    setFormErrors({ ...formErrors, [fieldName]: fieldError });
  };

  const onResetForm = () => {
    setOrganizationFormValue(initialOrganizationFormValues);
    setOrgainzationProfileFormValues(initialProfileFormValue);
    setFormErrors({});
    setIsEdit(false);
  };

  const canEditOrganization =
    organization &&
    ((me && organization.owner_id === me.get("uuid")) ||
      organization?.hasLink("update"));

  const canDelete = organization?.hasLink("delete");
  const countryOptions = useMemo(() => countryList().getData(), []);

  const hasErrors = formValues => {
    const errors = validateForm(formValues);
    return Object.keys(errors).some(field => errors[field]) ? errors : false;
  };

  const formHasErrors = hasErrors({
    ...organizationFormValues,
    ...organizationProfileFormValues
  });

  return (
    <S.Layout
      onSubmit={e => {
        e.preventDefault();
        const errors = hasErrors({
          ...organizationFormValues,
          ...organizationProfileFormValues
        });
        setFormErrors(errors);
        if (canEditOrganization && !errors)
          onSave(organizationFormValues, organizationProfileFormValues);
      }}
    >
      <S.OrgniazationLabelValue>
        {isEdit ? (
          <S.LabelInputField>
            <InputField
              id={FIELD_NAME.label}
              getRef={getInputLabelRef}
              onChange={e => onOrganizationChange(e.target.value, "label")}
              value={organizationFormValues?.label}
            />
          </S.LabelInputField>
        ) : (
          <S.OrganizationLabel data-testid="label">
            {organization?.label}
          </S.OrganizationLabel>
        )}
        {!isEdit && canEditOrganization && (
          <S.EditLink
            variant="link"
            id="edit-organization-label"
            type="button"
            onClick={() => {
              setIsEdit(!isEdit);
              // Waiting for the input to mount and the ref to be setted
              setTimeout(() => {
                inputLabelRef.current.focus();
              }, 200);
            }}
          >
            Edit
          </S.EditLink>
        )}
      </S.OrgniazationLabelValue>
      <S.LabelInfo>
        <S.Label>{intl.formatMessage({ id: "organization_label" })}</S.Label>
        <InfoDialog
          icon={<InfoIcon />}
          iconColor={theme.envTreeInactiveIcon}
          text={intl.formatMessage({
            id: "settings.details.informations"
          })}
        />
      </S.LabelInfo>
      <S.Separator />
      <S.SectionTitle>
        <FormattedMessage id="settings.details" />
      </S.SectionTitle>
      <S.Form>
        <InputField
          id={`organization-name`}
          label={intl.formatMessage({ id: "organization_url" })}
          error={formErrors[FIELD_NAME.name]}
        >
          <S.NameInputField>
            <input
              required
              id={FIELD_NAME.name}
              ref={inputNameRef}
              value={organizationFormValues?.name}
              onChange={e => onOrganizationChange(e.target.value, "name")}
              disabled={!canEditOrganization}
            />
          </S.NameInputField>
          <S.NameInputPrefix onClick={() => inputNameRef.current.focus()}>
            {`https://${window.location.host}/`}
            <S.NameInputPrefixValue>
              {organizationFormValues?.name || "[your-url]"}
            </S.NameInputPrefixValue>
          </S.NameInputPrefix>
        </InputField>
        <S.CountryDropDown
          options={countryOptions}
          value={
            organizationFormValues.country
              ? {
                  value: organizationFormValues.country,
                  label: countryOptions.find(
                    c => c.value === organizationFormValues.country
                  )?.label
                }
              : null
          }
          onChange={option =>
            onOrganizationChange(option.value, FIELD_NAME.country)
          }
          clearable={false}
          fieldType={true}
          disabled={!canEditOrganization}
          isRequired
          label={intl.formatMessage({ id: "select_country" })}
          defaultText={intl.formatMessage({ id: "select_country" })}
          className="field-country"
          error={formErrors[FIELD_NAME.country]}
          inputProps={{
            autoComplete: "off",
            autoCorrect: "off",
            spellCheck: "off"
          }}
        />
        <InputField
          id={FIELD_NAME.companyName}
          onChange={e =>
            onProfileFieldChange(e.target.value, FIELD_NAME.companyName)
          }
          label={intl.formatMessage({ id: FIELD_NAME.companyName })}
          value={organizationProfileFormValues[FIELD_NAME.companyName]}
          required
          disabled={!canEditOrganization}
        />

        {/* TODO uncomment when API adds website_url */}
        {/* <InputField
          label={intl.formatMessage({ id: "Company url" })}
          error={formErrors[FIELD_NAME.websiteUrl]}
        >
          <S.CompanyUrlField>
            <span className="url-protocol">https://www.</span>
            <input
              required
              value={organizationFormValues[FIELD_NAME.websiteUrl]}
              onChange={e =>
                onProfileFieldChange(e.target.value, FIELD_NAME.websiteUrl)
              }
                disabled={!canEditOrganization}
            />
          </S.CompanyUrlField>
        </InputField> */}

        <InputField
          onChange={e =>
            onProfileFieldChange(e.target.value, FIELD_NAME.securityContact)
          }
          id={FIELD_NAME.securityContact}
          label={intl.formatMessage({ id: FIELD_NAME.securityContact })}
          value={organizationProfileFormValues[FIELD_NAME.securityContact]}
          type="email"
          required
          placeholder="Email Address"
          disabled={!canEditOrganization}
        />
      </S.Form>
      {errors && <Error>{errors}</Error>}

      {formIsChanged && canEditOrganization ? (
        <ButtonWrapper spacing="modal">
          {isLoading ? (
            <Loading />
          ) : (
            <Button
              disabled={formHasErrors}
              type="submit"
              id="save_organization_general_settings_button"
            >
              {capitalize(intl.formatMessage({ id: "save" }))}
            </Button>
          )}
          <Button
            id="cancel-edit-organization-btn"
            onClick={onResetForm}
            variant="secondary"
          >
            {capitalize(intl.formatMessage({ id: "cancel" }))}
          </Button>
        </ButtonWrapper>
      ) : isLoading ? (
        <Loading />
      ) : canDelete ? (
        <ButtonWrapper spacing="modal">
          <S.DeleteButton
            variant="outline"
            id="delete-organization-btn"
            type="button"
            aria-label={intl.formatMessage({
              id: "delete"
            })}
            onClick={onDelete}
          >
            {capitalize(intl.formatMessage({ id: "delete_organization" }))}
          </S.DeleteButton>
        </ButtonWrapper>
      ) : null}
    </S.Layout>
  );
};

BasicSettings.propTypes = {
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  organization: PropTypes.object,
  organizationProfile: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.string,
  me: PropTypes.object
};

export default BasicSettings;
