import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Map } from "immutable";
import moment from "moment";
import { LiveMessage } from "react-aria-live";

import { withReducers } from "Hocs";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { environmentSelector } from "Reducers/environment";

import { getRegions, regionSelector } from "Reducers/project/region";
import {
  getOrganizationRegions,
  organizationRegionSelector
} from "Reducers/organization/region";
import { openProjectWizard } from "Reducers/project/wizard";
import { subscriptionSelector } from "Reducers/subscription";

import { getCSSVarString, ICON } from "Libs/themes";
import { capitalize, httpStatusDisplay, getRegionLabel } from "Libs/utils";

import { LinkIcon } from "@platformsh/ui-kit";
import { Button, Link } from "ds/Button";
import AccessibleTooltip from "Components/AccessibleTooltip";
import EnvironmentImpact from "Containers/EnvironmentImpact";
import ErrorBoundary from "Components/ErrorBoundary";
import Grid from "Components/Grid";
import Heading2 from "Components/styleguide/Heading2";
import IconLinkBroken from "Icons/IconLinkBroken";
import InfoDialog from "Components/InfoDialog";
import LeafIcon from "Components/icons/Leaf";

import PageMeta from "Components/PageMeta";

import * as S from "./Overview.styles";

const EnvironmentOverview = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { environmentId, organizationId, projectId } = useDecodedParams();

  const project = useSelector(({ project }) =>
    project?.getIn(["data", organizationId, projectId], Map())
  );
  const environment = useSelectorWithUrlParams(environmentSelector);
  const currentDeployment = useSelector(({ deployment }) =>
    deployment?.getIn(
      ["data", organizationId, projectId, environmentId, "current"],
      Map()
    )
  );
  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project?.subscription_id
    });
  });
  const region = useSelector(state => {
    if (process.env.ENABLE_ORGANIZATION && organizationId) {
      return organizationRegionSelector(state, {
        organizationId,
        regionId: project.data?.region
      });
    }
    return regionSelector(state, { regionId: project.data?.region });
  });

  useEffect(() => {
    if (process.env.ENABLE_ORGANIZATION && organizationId) {
      dispatch(getOrganizationRegions({ organizationId }));
    } else {
      dispatch(getRegions());
    }
  }, [organizationId]);

  useEffect(() => {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "%d second",
        ss: "%d seconds",
        m: "%d minute",
        mm: "%d minutes",
        h: "%d hour",
        hh: "%d hours",
        d: "%d day",
        dd: "%d days",
        M: "%d month",
        MM: "%d months",
        y: "%d year",
        yy: "%d years"
      }
    });
  }, []);

  const formatDate = date => {
    return moment(date).format("D MMM Y, kk:mm");
  };

  const httpAccess = useMemo(
    () => httpStatusDisplay(environment),
    [environment]
  );
  const regionLabel = useMemo(
    () => getRegionLabel(subscription?.project_region_label),
    [subscription]
  );
  const envUrl = useMemo(() => {
    if (environment?.data.has_deployment !== true) return;
    const routes = currentDeployment?.get("routes");
    let primaryRoute = "";

    if (routes) {
      // If there is no primary:true, try to be smart and find the first link that is not a redirect
      // If we're still not finding a link, we take the first in the list
      primaryRoute =
        routes.findKey(route => route.get("primary") === true) ||
        routes.findKey(route => route.get("type") !== "redirect") ||
        routes.keySeq().get(0);
    }

    return primaryRoute;
  }, [currentDeployment]);

  const openWizard = () => {
    dispatch(openProjectWizard({ organizationId, projectId }));
  };

  if (!environment) return null;

  return (
    <S.Layout>
      <S.Header>
        <PageMeta title={`${environment.title} | ${project?.title}`} />
        <Heading2 id="environment-info-heading">{environment.title}</Heading2>
      </S.Header>

      <S.Env>
        {environment.type
          ? capitalize(environment.type)
          : !environment.is_main && "Development"}
      </S.Env>

      {!project.default_domain && (
        <S.Wizard>
          <Button variant="link" onClick={openWizard}>
            {intl.formatMessage({ id: "project.overview.wizard" })}
          </Button>
        </S.Wizard>
      )}

      <ErrorBoundary>
        <LiveMessage
          message={`${environment.title} overview`}
          aria-live="polite"
        />

        <S.GridWrapper>
          <Grid>
            <S.ItemWrapper>
              <S.Time aria-labelledby="environment-overview-activity">
                {formatDate(environment.last_active_at)}
              </S.Time>
              <S.Label id="environment-overview-activity">
                {intl.formatMessage({ id: "last_activity" })}
              </S.Label>
            </S.ItemWrapper>

            <S.ItemWrapper className="environment-backup">
              {environment?.last_backup_at ? (
                <S.Time aria-labelledby="environment-overview-backup">
                  {formatDate(environment.last_backup_at)}
                </S.Time>
              ) : (
                <S.Time
                  className="no-backup"
                  aria-labelledby="environment-overview-backup"
                >
                  {intl.formatMessage({ id: "no_backup" })}
                </S.Time>
              )}
              <S.Label id="environment-overview-backup">
                {intl.formatMessage({ id: "last_backup" })}
              </S.Label>
            </S.ItemWrapper>
          </Grid>

          <Grid>
            <S.ItemWrapper>
              <S.HttpAccessStatus
                status={httpAccess ? "enabled" : "disabled"}
                aria-labelledby="environment-overview-http-access"
                isProduction={environment.type === "production"}
              >
                {intl.formatMessage({
                  id: httpAccess ? "enabled" : "disabled"
                })}
              </S.HttpAccessStatus>
              <S.Label id="environment-overview-http-access">
                {intl.formatMessage({ id: "http_access" })}
              </S.Label>
            </S.ItemWrapper>

            <S.ItemWrapper>
              <S.Value aria-labelledby="environment-overview-machine-name">
                {environment.machine_name}
              </S.Value>
              <S.Label id="environment-overview-machine-name">
                {intl.formatMessage({ id: "machine_name" })}
              </S.Label>
            </S.ItemWrapper>
          </Grid>

          <Grid>
            <S.ItemWrapper>
              <S.Status
                status={environment.status}
                aria-labelledby="environment-overview-status"
              >
                {capitalize(
                  intl.formatMessage({
                    id: `environment.status.${environment.status}`
                  })
                )}
              </S.Status>
              <S.Label id="environment-overview-status">
                {capitalize(intl.formatMessage({ id: "status" }))}{" "}
                <InfoDialog
                  title={capitalize(intl.formatMessage({ id: "status" }))}
                  text={intl.formatMessage({ id: "status.dialog.edit" })}
                  to={`/${organizationId}/${projectId}/${encodeURIComponent(
                    environmentId
                  )}/settings`}
                  linkText={intl.formatMessage({ id: "view_settings" })}
                  to2={intl.formatMessage({
                    id: "links.documentation.inactive_environment"
                  })}
                  linkText2={intl.formatMessage({ id: "learnmore" })}
                />
              </S.Label>
            </S.ItemWrapper>

            <S.ItemWrapper>
              <S.Value aria-labelledby="environment-overview-is-hidden-from-search-engines">
                {intl.formatMessage({
                  id: environment.restrict_robots ? "hidden" : "visible"
                })}
              </S.Value>
              <S.Label id="environment-overview-is-hidden-from-search-engines">
                {intl.formatMessage({ id: "search_engine_index" })}
              </S.Label>
            </S.ItemWrapper>
          </Grid>

          <Grid>
            <S.ItemWrapper>
              <S.RegionValue aria-labelledby="environment-overview-region">
                <S.ActionDropdown
                  ariaLabel={intl.formatMessage({
                    id: "environments.links_tooltip"
                  })}
                  handlerType="hover"
                  icon={
                    <LeafIcon
                      color={"var(--icon-granite-fill,var(--icon-granite,var(--granite)))"}
                    />
                  }
                  id="environmentImpact"
                  tooltipWidth={280}
                  withArrow={false}
                  withBorder={false}
                >
                  <EnvironmentImpact inModal={true} region={region} />
                </S.ActionDropdown>

                <span>
                  {regionLabel?.title}
                  <S.RegionSuffix>{regionLabel?.suffix}</S.RegionSuffix>
                  {region?.data?.provider?.name &&
                    ` - ${region.data.provider.name}`}
                </span>
              </S.RegionValue>
              <S.Label id="environment-overview-region">
                {intl.formatMessage({ id: "region" })}
              </S.Label>
            </S.ItemWrapper>

            {environment.type !== "production" &&
              environment.data.deployment_state?.crons.enabled && (
                <S.ItemWrapper>
                  <S.Value aria-labelledby="environment-overview-crons">
                    {intl.formatMessage({
                      id: `environment.overview.crons.status.${environment.data.deployment_state?.crons?.status}`
                    })}{" "}
                    {environment?.hasLink("#redeploy") &&
                      environment.data.deployment_state?.crons.status ==
                        "sleeping" && (
                        <Link
                          to={`/${organizationId}/${projectId}/${encodeURIComponent(
                            environmentId
                          )}/actions/redeploy`}
                        >
                          {intl.formatMessage({ id: "redeploy" })}
                        </Link>
                      )}
                  </S.Value>
                  <S.Label id="environment-overview-crons">
                    {intl.formatMessage({ id: "environment.overview.crons" })}
                    {environment.data.deployment_state?.crons.status ===
                      "sleeping" && (
                      <InfoDialog
                        text={intl.formatMessage({
                          id: "environment.overview.crons.sleeping"
                        })}
                        linkText={intl.formatMessage({ id: "learnmore" })}
                        to={
                          "https://docs.platform.sh/configuration/app/cron.html#paused-crons"
                        }
                      />
                    )}
                  </S.Label>
                </S.ItemWrapper>
              )}
          </Grid>
        </S.GridWrapper>

        {environment.status === "inactive" ? (
          <S.EnvUrl status={environment.status}>
            <IconLinkBroken />
            <S.InactiveMessage>
              {intl.formatMessage({ id: "environment.status.inactive.title" })}
            </S.InactiveMessage>
            <InfoDialog
              title={intl.formatMessage({
                id: "environment.status.inactive.url"
              })}
              text={intl.formatMessage({
                id: "environment.status.inactive.update"
              })}
              to={`/${organizationId}/${projectId}/${encodeURIComponent(
                environmentId
              )}/settings`}
              linkText={intl.formatMessage({ id: "view_settings" })}
              to2={intl.formatMessage({
                id: "links.documentation.inactive_environment"
              })}
              linkText2={intl.formatMessage({ id: "learnmore" })}
            />
          </S.EnvUrl>
        ) : (
          <>
            {envUrl ? (
              <S.EnvUrl>
                <LinkIcon />
                <S.Truncate>
                  <AccessibleTooltip
                    as="a"
                    href={envUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    tooltipProps={{
                      id: "environment-url-full",
                      children: envUrl
                    }}
                  >
                    <S.ExternalEnvLink>{envUrl}</S.ExternalEnvLink>
                  </AccessibleTooltip>
                </S.Truncate>
              </S.EnvUrl>
            ) : (
              <S.NoUrl>
                {intl.formatMessage({ id: "project.details.no_url" })}
              </S.NoUrl>
            )}
          </>
        )}
      </ErrorBoundary>
    </S.Layout>
  );
};

export default withReducers({
  deployment: () => import("Reducers/environment/deployment"),
  environment: () => import("Reducers/environment"),
  project: () => import("Reducers/project")
})(EnvironmentOverview);
