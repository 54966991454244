import styled from "styled-components";
import { SettingsIcon as BaseSettingsIcon } from "@platformsh/ui-kit";

import UnstyledListGroup from "Components/ListGroup";
import Heading2 from "Components/styleguide/Heading2";
import { css, ICON } from "Libs/themes";

export const ListGroup = styled(UnstyledListGroup)`
  position: relative;
`;

export const ColumnWrapper = styled.div`
  @media (min-width: 600px) {
    > section {
      overflow: visible;
    }
  }
  @media (min-width: 768px) {
    > aside {
      display: flex;
      width: 100%;
      section {
        &:first-child {
          margin-right: 32px;
        }
      }
    }
  }
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    > aside.col-4 {
      flex-grow: 1;
      margin-right: 32px;
      display: block;
      width: inherit;
      max-width: 437px;
      min-width: auto;
    }
    > div {
      flex-grow: 2;
      width: 63%;
      max-width: calc(100% - 464px);
      box-sizing: border-box;
    }
  }
`;

export const OverviewBlock = styled.div`
  padding: 32px;
  box-sizing: border-box;
`;

export const ServicesBlock = styled.div`
  height: 360px;
  ${Heading2} {
    margin: 0;
    padding: 32px 32px 38px;
  }

  > div {
    padding: 0 32px 14px;
    min-width: auto;
    width: auto;
  }
`;

export const SettingsIcon = styled(BaseSettingsIcon)`
  margin-top: 4px;
  cursor: pointer;
  fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
`;

export const EnvMenuFlex = styled.div`
  display: flex;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;
