import styled, { css as styledCSS } from "styled-components";
import { Link as Unstyledlink } from "react-router-dom";

import { semiBoldAlias } from "Libs/theme";
import { css, BUTTON, SECTION, HEADER } from "Libs/themes";

import UnstyledActionDropdown from "Components/ActionDropdown";
import UnstyledAvatar from "Components/Avatar";
import Heading3 from "Components/styleguide/Heading3";
import URLOverlay from "../../components/URLOverlay";

export const Wrapper = styled.div``;

export const Header = styled.header`
  display: flex;
  max-width: 100%;
  height: 200px;
  margin: 0;
`;

export const Url = styled(URLOverlay)`
  width: 100%;
`;

export const Details = styled.div`
  min-height: 250px;
  padding: 32px 32px 0 32px;
  box-sizing: border-box;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
`;

export const ProjectName = styled(Heading3)`
  margin: 0 0 1px 0;
  line-height: 32px;
`;

export const IdRegion = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-size: 13px;
  line-height: 16px;
  ${semiBoldAlias};
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const ActionDropdown = styled(UnstyledActionDropdown)`
  button {
    width: 24px;
    min-width: auto;
    height: auto;
    margin-right: 8px;
    padding: 0 !important;

    &:focus,
    &:hover,
    &.open {
      border: none;
      outline: none;
      box-shadow: none;
      svg {
        transform: none;
        path {
          fill:var(--button-slate-fill,var(--button-slate,var(--slate)));
        }
      }
    }
  }
  .tooltip {
    left: 0;
  }
`;

export const Wizard = styled.div`
  display: flex;
  margin: 16px 0 0 0;
`;

export const Plan = styled.div`
  margin: 18px 0 0 0;
  ${semiBoldAlias};
  font-size: 14px;
  line-height: 16px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));

  a {
    margin-left: 8px;
    font-size: 13px;
    text-decoration: none;
    ${semiBoldAlias};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Info = styled.div`
  display: block;
  margin-top: 16px;
`;

export const Label = styled.label`
  display: block;
  color: ${props => (props.theme.name === "contrast" ? "#4A495E" : "#98a0ab")};
  font-size: 14px;
  line-height: 16px;
  ${semiBoldAlias};
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Value = styled.span`
  visibility: hidden;
  display: block;
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 18px;
  ${semiBoldAlias};
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Infos = styled.div`
  display: grid;
  box-sizing: border-box;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;

  ${props =>
    props.displayValues &&
    styledCSS`
      ${Value} {
        visibility: visible;
      }
    `};
`;

export const Users = styled.div`
  display: flex;
  margin-top: 24px;

  span {
    margin: 0 0 0 5px;
    font-size: 12px;
    line-height: 24px;
    ${semiBoldAlias};
    color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  }
`;

export const UsersFullList = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  top: 24px;
  left: 0;
  width: 224px;
  height: 201px;
  margin: 0;
  padding: 9px 0 9px 16px;
  box-sizing: border-box;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  box-shadow: box-shadow:var(--section-box-shadow-box-shadow,var(--section-box-shadow,var(--box-shadow)))
    box-shadow-color:var(--section-granite-shadow-box-shadow-color,var(--section-granite-shadow,var(--granite-shadow)));
  border-radius: 2px;
  list-style-type: none;

  .rcs-inner-handle {
    background-color:var(--section-dark-tint-background-color,var(--section-dark-tint,var(--dark-tint)));
    width: 4px;
    border-radius: 4px;
    opacity: 1;
  }
`;

export const User = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  & + & {
    margin-top: 8px;
  }
`;

export const UsersMore = styled.div`
  position: relative;
  &:hover {
    ${UsersFullList} {
      display: block;
    }

    > span {
      color: #ffffff;
      background: var(--button-secondary-foreground);
    }
  }
  > span {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    border-radius: 2px;
    background: var(--button-secondary-disabled-foreground);
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    color: var(--text_body);
    text-align: center;
    cursor: pointer;
  }
`;

export const Avatar = styled(UnstyledAvatar)`
  margin-right: 2px;
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;

export const NoEnv = styled.div`
  display: flex;
  margin: 24px 8px 8px 8px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 2px;
  background-color:var(--no-env-section-background-color,var(--section-ice-background-color,var(--section-ice,var(--ice))));
  font-size: 14px;
  line-height: 24px;
  ${semiBoldAlias};
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const MainUrl = styled(NoEnv)`
  background-color:var(--main-url-section-background-color,var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light))));
`;

export const DomainLink = styled(Unstyledlink)`
  display: flex;
  line-height: 1;
  svg {
    fill: var(--link);
  }
`;

export const Truncate = styled.div`
  flex: 1;
  margin-right: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color:var(--section-skye-color,var(--section-skye,var(--skye)));
  > div {
    min-width: 0;
    display: unset;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
