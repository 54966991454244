import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import { getEnvironmentURI } from "Libs/utils";
import useDecodedParams from "Hooks/useDecodedParams";
import useMediaQuery from "Hooks/useMediaQuery";
import { withProjectRedirect, withReducers } from "Hocs";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import {
  environmentSelector,
  environmentLoadingSelector
} from "Reducers/environment";
import { loadDeployment as loadDeploymentAction } from "Reducers/environment/deployment";
import { loadProjectWizard } from "Reducers/project/wizard";
import { setEnvironmentsContext } from "Reducers/settingsMenu";

import ActivitySection from "../../../../common/containers/ActivitySection";
import ContentLayout from "Components/ContentLayout";
import ContentWizard from "../../containers/ContentWizard";
import EnvironmentOverview from "../overview/Page";
import GoLiveButton from "Containers/NavBar/Environment/GoLiveButton";
import MoreEnvironmentAction, {
  MORE_ACTION_ID
} from "Containers/NavBar/Environment/moreActions/MoreEnvironmentAction";
import Heading2 from "Components/styleguide/Heading2";
import { SourceOperations } from "Containers/NavBar/Environment/sourceOperations";
import EnvironmentBackupModal from "Containers/NavBar/Environment/BackupModal";

import InfoDropButtons from "../../../../common/containers/InfoDropButtons";
import OverviewSkeleton from "../overview/containers/Overview/OverviewSkeleton";
import ServicesDisplay from "../../../../common/containers/ServicesDisplay";
import ServicesDisplaySkeleton from "../../../../common/components/ServicesDisplaySkeleton";

import * as S from "./Details.styles";

const Details = ({ children }) => {
  const { environmentId, organizationId, projectId } = useDecodedParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [areServicesReady, setAreServicesReady] = useState(false);
  const [openBackupModal, setBackupModalState] = useState(false);
  const [openSourceOperation, setOpenSourceOperation] = useState(false);
  const onMobile = useMediaQuery("(max-width: 768px)");

  const user = useSelector(state => state.app?.get("me").toJS());
  const environment = useSelectorWithUrlParams(environmentSelector);
  const envLoading = useSelectorWithUrlParams(environmentLoadingSelector);
  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );

  const loadDeployment = () =>
    dispatch(loadDeploymentAction(organizationId, projectId, environmentId));

  useEffect(() => {
    if (environmentId) {
      loadDeployment();
    }
  }, [environmentId]);

  useEffect(() => {
    if (!project?.id || !environment?.id) return;
    dispatch(
      loadProjectWizard({
        user,
        organizationId,
        projectId,
        intl,
        hasCode:
          typeof environment?.hasPermission === "function" &&
          environment.hasPermission("#initialize")
      })
    );
  }, [project, environment]);

  const toggleBackupModal = () => {
    setBackupModalState(!openBackupModal);
  };

  const environmentUri = getEnvironmentURI(
    organizationId,
    projectId,
    environmentId
  );

  const handleMergeClicked = e => {
    if (environment.hasLink("#merge")) {
      e.preventDefault();
      document.activeElement.blur();
      history.push(`${environmentUri}/actions/merge`);
    }
  };

  const handleBranchClicked = e => {
    if (environment.hasLink("#branch")) {
      e.stopPropagation();
      document.activeElement.blur();
      history.push(`${environmentUri}/actions/branch`);
    }
  };

  const handleSyncClicked = e => {
    if (environment.hasLink("#synchronize")) {
      e.stopPropagation();
      document.activeElement.blur();
      history.push(`${environmentUri}/actions/sync`);
    }
  };

  const onClickMoreAction = ({ id, event }) => {
    switch (id) {
      case MORE_ACTION_ID.BACKUP:
        if (environment.hasLink("#backup")) {
          event.preventDefault();
          document.activeElement.blur();
          toggleBackupModal();
        }
        break;
      case MORE_ACTION_ID.BRANCH:
        handleBranchClicked(event);
        break;
      case MORE_ACTION_ID.MERGE:
        handleMergeClicked(event);
        break;
      case MORE_ACTION_ID.REDEPLOY:
        environment.hasLink("#redeploy") &&
          history.push(`${environmentUri}/actions/redeploy`);
        event.preventDefault();
        break;
      case MORE_ACTION_ID.SOURCE_OPS:
        setOpenSourceOperation(!openSourceOperation);
        event.preventDefault();
        break;
      case MORE_ACTION_ID.SYNC:
        event.preventDefault();
        handleSyncClicked(event);
        break;
      case MORE_ACTION_ID.DELETE_ENVIRONMENT:
      //TODO DELETE delete environment
    }
  };

  if (!environment) return null;

  // Redirect to settings page if env doesn't exist and name matches
  // settings pages [settings, integrations. plan, wait]
  if (!envLoading && Object.entries(environment).length === 0) {
    if (["settings", "integrations", "plan", "wait"].includes(environmentId)) {
      const url = `/${organizationId}/${projectId}/-/${environmentId}`;
      return <Redirect to={url} />;
    }
  }

  const settingsLink = `/${organizationId}/${projectId}/${environmentId}/settings`;

  const handleSettingsClicked = e => {
    e.stopPropagation();
    dispatch(setEnvironmentsContext(environmentId));
    history.push(settingsLink);
  };

  return (
    <ContentLayout>
      <S.EnvMenuFlex>
        {onMobile && (
          <div style={{ marginRight: "12px" }}>
            <GoLiveButton />
          </div>
        )}
        {project && (
          <InfoDropButtons
            className="buttons"
            router={history}
            project={project}
          />
        )}
        {onMobile && (
          <div
            css={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}
          >
            <S.SettingsIcon onClick={handleSettingsClicked} />

            <MoreEnvironmentAction
              onClick={onClickMoreAction}
              environment={environment}
            />
          </div>
        )}
      </S.EnvMenuFlex>

      <S.ColumnWrapper>
        <aside className="col-4">
          <S.ListGroup
            className="environment-info"
            aria-labelledby="environment-heading"
          >
            {envLoading ? (
              <OverviewSkeleton />
            ) : (
              <S.ListGroup labelledby="environment-info-heading">
                <S.OverviewBlock>
                  {children || <EnvironmentOverview />}
                </S.OverviewBlock>
              </S.ListGroup>
            )}
          </S.ListGroup>

          <S.ListGroup aria-labelledby="environment-services-heading">
            {!areServicesReady && <ServicesDisplaySkeleton />}
            <S.ServicesBlock>
              <Heading2 id="environment-services-heading">
                {intl.formatMessage({ id: "apps_services" })}
              </Heading2>
              <ServicesDisplay
                height="250px"
                minHeight="250px"
                treePositionY={10}
                maxHeight={220}
                hasInfoTooltip
                onLoadEnd={() => setAreServicesReady(true)}
              />
            </S.ServicesBlock>
          </S.ListGroup>
        </aside>

        <div>
          <ContentWizard project={project} user={user} />
          <ActivitySection context="environment" />
        </div>
      </S.ColumnWrapper>

      <EnvironmentBackupModal
        isOpen={openBackupModal}
        closeModal={toggleBackupModal}
      />
      {process.env.ENABLE_SOURCE_OPERATION && (
        <SourceOperations
          organizationId={organizationId}
          projectId={projectId}
          environmentId={environmentId}
          isOpen={openSourceOperation}
          onClose={() => setOpenSourceOperation(false)}
        />
      )}
    </ContentLayout>
  );
};

Details.propTypes = {
  children: PropTypes.node
};

export default withReducers({
  environment: () => import("Reducers/environment"),
  deployment: () => import("Reducers/environment/deployment"),
  service: () => import("Reducers/environment/service")
})(withProjectRedirect(Details));
