import styled from "styled-components";

import { getCSSVarString, LINK, css } from "Libs/themes";

export const TabsLayout = styled.div`
  display: flex;
  width: 100%;
  max-width: 82vw;
  overflow: visible;
  position: absolute;
  top: 56px;
  z-index: 5;
`;

export const Tab = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 80%;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1.6px;
  a:not(.button) {
    text-decoration: none;
    color:var(--link-ebony-light-color,var(--link-ebony-light,var(--ebony-light)));
    &.active {
      color: ${props =>
        props.active &&"var(--link-night-color,var(--link-night,var(--night)))"};
    }

    &.active:before {
      content: "";
      display: inline-block;
      width: calc(100% - 17px);
      position: absolute;
      bottom: -6px;
      left: 8px;
      height: 2px;
      background-color:var(--link-night-background-color,var(--link-night,var(--night)));
    }
  }
  &.right {
    margin-left: auto;
    padding-right: 0;
  }
  &:first-child {
    padding-left: 0;

    & a:not(button).active:before {
      left: 0;
      width: calc(100% - 8px);
    }
  }
`;
