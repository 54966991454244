import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import useMediaQuery from "Hooks/useMediaQuery";

import { withReducers } from "Hocs";
import { getSystemStatus } from "Reducers/systemStatus";

import SuccessIcon from "Icons/SuccessStateIcon";
import WarningIcon from "Icons/WarningIcon";

import * as S from "./styles";

const SystemStatusBanner = ({ anchor = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onDesktop = useMediaQuery("(min-width: 768px)");

  const status = useSelector(state => state.systemStatus?.get("status"));
  const loading = useSelector(state => state.systemStatus?.get("loading"));

  useEffect(() => {
    if (loading === "idle") dispatch(getSystemStatus());
  }, []);

  if (!status) return null;

  // Display banner on mobile only when an incident is in progress
  if (!onDesktop && status.indicator === S.SYSTEM_STATUS.NONE) return null;

  return (
    <S.Wrapper level={status.indicator}>
      {status.indicator === S.SYSTEM_STATUS.SUCCESS ||
      status.indicator === S.SYSTEM_STATUS.NONE ? (
        <SuccessIcon />
      ) : (
        <WarningIcon />
      )}
      {status.description}

      {!onDesktop && anchor && (
        <S.Link href="#status">
          {intl.formatMessage({
            id: "system_status.anchor",
            defaultMessage: "View status"
          })}
        </S.Link>
      )}
    </S.Wrapper>
  );
};

SystemStatusBanner.propTypes = {
  anchor: PropTypes.bool
};

export default withReducers({
  systemStatus: () => import("Reducers/systemStatus")
})(SystemStatusBanner);
