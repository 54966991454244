import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { capitalize } from "Libs/utils";

import { Button, ButtonWrapper } from "ds/Button";
import ModalWrapper from "Components/Modal";

import * as S from "./DeleteModal.styles";

const DeleteModal = ({
  closeModal,
  deleteDomain,
  domain,
  domains,
  isDefault,
  isOpen
}) => {
  const intl = useIntl();

  const [defaultDomain, setDefaultDomain] = useState();

  useEffect(() => {
    if (domainList) setDefaultDomain(domainList[0]?.value);
  }, [domainList]);

  const title = intl.formatMessage({
    id: "settings.domains.modal.delete.title"
  });

  const domainList = useMemo(() => {
    return domains
      .filter(elt => elt.id !== domain.id)
      .map(elt => {
        return { label: elt.name, value: elt.id, domain: elt };
      });
  }, [domains]);

  const handleChange = selected => {
    setDefaultDomain(selected.domain);
  };

  const handleConfirm = e => {
    e.preventDefault();
    let newDefault;
    if (isDefault && domains.length > 1) {
      newDefault = defaultDomain;
    }
    deleteDomain(domain, newDefault);
    closeModal();
  };

  return (
    <ModalWrapper
      closeModal={closeModal}
      id="delete-domain-modal"
      isOpen={isOpen}
      modalClass="modal-small"
      overflowX="visible"
      overflowY="visible"
      title={title}
    >
      <S.ModalBody className="modal-body">
        {intl.formatMessage(
          {
            id: `settings.domains.modal.delete.body${
              isDefault
                ? `.default.${domains.length === 2 ? "switch" : "select"}`
                : ""
            }`
          },
          {
            b: (...chunks) => <b>{chunks}</b>, // eslint-disable-line react/display-name
            domain: domain.id,
            otherDomain: domains.find(elt => elt.id !== domain.id)?.id
          }
        )}
      </S.ModalBody>

      {isDefault && domains.length > 2 && (
        <S.Dropdown
          clearable={false}
          defaultValue={domainList[0]}
          label={intl.formatMessage({
            id: "settings.domains.modal.delete.select"
          })}
          onChange={handleChange}
          options={domainList}
          required={true}
          searchable
        />
      )}

      <ButtonWrapper spacing="modal" justifyContent="end">
        <Button variant="secondary" onClick={closeModal}>
          {capitalize(intl.formatMessage({ id: "cancel" }))}
        </Button>
        <Button
          id="delete-confirm-modal-button"
          type="submit"
          onClick={handleConfirm}
        >
          {capitalize(
            intl.formatMessage({
              id: "settings.domains.modal.delete.confirm"
            })
          )}
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

DeleteModal.propTypes = {
  closeModal: PropTypes.func,
  deleteDomain: PropTypes.func,
  domain: PropTypes.object,
  domains: PropTypes.array,
  isDefault: PropTypes.bool,
  isOpen: PropTypes.bool
};

export default DeleteModal;
