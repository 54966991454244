import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import useDecodedParams from "Hooks/useDecodedParams";
import ChevronIcon from "Components/icons/ChevronIcon";
import {
  toggleProjectPanel,
  selectProjectPanelExpanded
} from "Reducers/settingsMenu";
import IntegrationsMenu from "./IntegrationsMenu";
import { MenuChildrenContainer, MenuHeader, Submenu } from "./Menu.styles";
import { OverriddenLink } from "./ProjectSettingsMenu.styles";

function getCurrentSection(pathname) {
  const regex = new RegExp(`/-/settings/?([A-z]*)?(/|$)`);
  const trail = pathname.match(regex);

  // We are not in a project settings page
  if (trail === null) {
    return;
  }

  // We are on a settings section, but not in general
  if (trail[1]) {
    return trail[1];
  }

  // we are on the general project settings section
  if (trail[0]) {
    return "general";
  }
}

const ProjectSettingsMenu = () => {
  const expanded = useSelector(selectProjectPanelExpanded);
  const { projectId, organizationId } = useDecodedParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();

  const currentSection = getCurrentSection(pathname);

  const rootRoute = `/${organizationId}/${projectId}/-/settings`;

  return (
    <MenuChildrenContainer expanded={expanded}>
      <MenuHeader
        onClick={() => dispatch(toggleProjectPanel())}
        data-testid="menu-settings-project-btn"
      >
        <h1>{intl.formatMessage({ id: "settings.menu.project_settings" })}</h1>
        <ChevronIcon color="currentColor" isOpen={expanded} animate={true} />
      </MenuHeader>
      {expanded && (
        <Submenu>
          <li>
            <OverriddenLink
              isActive={currentSection === "general"}
              to={rootRoute}
              data-testid="menu-settings-general-btn"
            >
              {intl.formatMessage({ id: "settings.menu.general" })}
            </OverriddenLink>
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/access`}
              isActive={currentSection === "access"}
              data-testid="menu-settings-access-btn"
            >
              {intl.formatMessage({ id: "settings.menu.access" })}
            </OverriddenLink>
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/certificates`}
              isActive={currentSection === "certificates"}
              data-testid="menu-settings-certificates-btn"
            >
              {intl.formatMessage({ id: "settings.menu.certificates" })}
            </OverriddenLink>
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/domains`}
              isActive={currentSection === "domains"}
              data-testid="menu-settings-domains-btn"
            >
              {intl.formatMessage({ id: "settings.menu.domains" })}
            </OverriddenLink>
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/deploykey`}
              isActive={currentSection === "deploykey"}
              data-testid="menu-settings-deploykey-btn"
            >
              {intl.formatMessage({ id: "settings.menu.deploy_key" })}
            </OverriddenLink>
          </li>
          <li>
            <IntegrationsMenu isActive={currentSection === "integrations"} />
          </li>
          <li>
            <OverriddenLink
              to={`${rootRoute}/variables`}
              isActive={currentSection === "variables"}
              data-testid="menu-settings-variables-btn"
            >
              {intl.formatMessage({ id: "settings.menu.variables" })}
            </OverriddenLink>
          </li>
        </Submenu>
      )}
    </MenuChildrenContainer>
  );
};

export default ProjectSettingsMenu;
