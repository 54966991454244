import styled from "styled-components";

import Dropdown from "Components/Dropdown";
import DefaultLabel from "Components/fields/Label";
import BaseButton from "ds/Button";

import { css, SECTION, getCSSVarString, HEADER, INPUT } from "Libs/themes";

export const Layout = styled.form`
  display: flex;
  flex-direction: column;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 21px 40px;
  flex: 1;
  border-radius: 2px;
  margin-left: -24px;
  margin-right: -24px;
  height: 100%;

  @media (min-width: 1920px) {
    padding-left: 60px;
  }

  @media (min-width: 960px) {
    padding-bottom: 0;
    margin: 0;
    max-width: 276px;
    min-width: 276px;
    overflow-y: scroll;
    height: calc(100vh - 120px);
  }
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  text-transform: capitalize;
  ${props => props.withTopSpacing && "margin-top: 32px"};
  ${props =>
    props.border &&
    `
    border-${props.border}: 1px solid ${getCSSVarString(
      SECTION,
      "skye-light",
      "border-color"
    )};
    margin-${props.border}: 31px;
    padding-${props.border}: 30px;
  `};
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  .field {
    width: 100%;
  }
`;

export const Info = styled.div`
  font-size: 14px;
  line-height: 24px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const LabelInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const OrgniazationLabelValue = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(DefaultLabel)`
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  margin-bottom: 0px;
  margin-right: 12px;
`;

export const OrganizationLabel = styled.h3`
  margin: 0px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
`;

export const Separator = styled.hr`
  margin: 24px 0px;
`;

export const DeleteButton = styled(BaseButton)`
  max-width: 190px;
`;

export const EditLink = styled(BaseButton)`
  margin-left: 16px;
`;

const InputField = styled.div`
  input {
    background: none;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  input:focus {
    outline: none;
    box-shadow: none;
    background: none;
    border: none;
  }

  input.changed {
    outline: none;
    border: none;
    box-shadow: none;
    background: none;
  }
`;

export const LabelInputField = styled(InputField)`
  input {
    padding: 0px;
  }
  input:focus {
    padding: 0px;
  }
  input.changed {
    padding: 0px;
  }
  .input-field {
    margin-bottom: 0;
  }
`;

export const NameInputField = styled(InputField)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));

  input {
    background: none;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    padding-left: 16px;
    &:focus {
      border: 1px solid var(--input-granite-border-color,var(--input-granite,var(--granite)));
      background-color: none;
      box-shadow: none;
      outline: none;
      background: none;
    }
  }
`;

export const NameInputPrefix = styled.div`
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 8px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const NameInputPrefixValue = styled.span`
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const CountryDropDown = styled(Dropdown)`
  .select-box {
    width: 100%;
  }
  .input-wrapper {
    width: 100%;
  }

  margin-bottom: 16px;
`;

export const CompanyUrlField = styled(NameInputField)`
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  input {
    padding: 0 !important;
    &:focus {
      padding: 0 !important;
    }
  }
  .url-protocol {
    font-size: 14px;
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
  }
`;
