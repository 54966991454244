import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LiveMessage } from "react-aria-live";

import { useParams } from "react-router-dom";

import { withReducers } from "Hocs";
import ContentLayout from "Components/ContentLayout";
import useLocalForage from "Hooks/useLocalForage";

import Projects from "./containers/Projects";
import ProjectFilterBar from "../../common/components/ProjectFilterBar";

import ProjectsSkeleton from "../../common/components/ProjectsSkeleton";

const MyProjects = () => {
  const [layout, toggleLayout, , layoutIsLoading] = useLocalForage(
    "projects_grid_layout"
  );
  const [sortType, setSortType, , sortIsLoading] = useLocalForage(
    "projects_sorting_type"
  );
  const [sortOrder, setSortOrder, , orderIsLoading] = useLocalForage(
    "projects_sorting_order"
  );

  const isLoadingList = useSelector(state =>
    state.project.get("loadingList", false)
  );
  const isLoaded = useSelector(state => state.project.get("loadedList", false));

  const [hasProjects, setHasProjects] = useState(false);

  const { organizationId } = useParams();

  const isLoading = layoutIsLoading && sortIsLoading && orderIsLoading;

  if (isLoading || isLoadingList || !isLoaded) {
    return (
      <ContentLayout>
        <ProjectsSkeleton />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout>
      {hasProjects && (
        <ProjectFilterBar
          toggleLayout={value => toggleLayout(value ? "grid" : "list")}
          gridLayout={layout !== "list"}
          organization={organizationId}
          sortType={sortType || "project_title"}
          setSortType={setSortType}
          sortOrder={sortOrder || "ascend"}
          setSortOrder={setSortOrder}
        />
      )}
      {layout !== "list" ? (
        <LiveMessage
          message="project list displayed as a grid"
          aria-live="polite"
        />
      ) : (
        <LiveMessage
          message="project list displayed as a list"
          aria-live="polite"
        />
      )}
      <Projects
        organizationId={organizationId}
        gridLayout={layout !== "list"}
        sortType={sortType}
        setSortType={setSortType}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        setHasProjects={setHasProjects}
      />
    </ContentLayout>
  );
};

export default withReducers({
  project: () => import("Reducers/project"),
  plan: () => import("Reducers/plan"),
  region: () => import("Reducers/project/region"),
  subscription: () => import("Reducers/subscription"),
  organizationSubscription: () => import("Reducers/organization/subscription"),
  search: () => import("Reducers/app/search")
})(MyProjects);
