import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { push } from "Reducers/app";
import logger from "Libs/logger";
import { MapStoreStateType } from "Reducers/types";

type SyncThunkPropType = {
  organizationId: string;
  projectId: string;
  environmentId: string;
  options: {
    syncData: boolean;
    syncCode: boolean;
  };
};

const sync = createAsyncThunk<any, SyncThunkPropType>(
  "Environment/Sync",
  async (
    { options, organizationId, projectId, environmentId },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const environment = (getState() as MapStoreStateType).environment.getIn([
        "data",
        organizationId,
        projectId,
        environmentId
      ]);
      const sync = await environment.synchronize(
        options.syncData,
        options.syncCode
      );
      dispatch(
        push({
          path: "/:organizationDescriptionId/:projectDescriptionId/:environmentDescriptionId",
          args: {
            organizationDescriptionId: organizationId,
            projectDescriptionId: projectId,
            environmentDescriptionId: environmentId
          }
        })
      );
      return sync;
    } catch (err: any) {
      logger(err, {
        action: "sync",
        meta: {
          organizationId,
          environmentId,
          projectId
        }
      });
      return rejectWithValue(err);
    }
  }
);

const syncSlice = createSlice({
  name: "sync",
  initialState: Map(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(sync.pending, state =>
        state.set("loading", true).remove("errors")
      )
      .addCase(sync.fulfilled, (state, { meta: { arg }, payload }) =>
        state
          .setIn(
            ["data", arg.organizationId, arg.projectId, arg.environmentId],
            fromJS(payload)
          )
          .set("loading", false)
          .remove("errors")
      )
      .addCase(sync.rejected, (state, { payload }) =>
        state.set("loading", false).set("errors", fromJS(payload))
      );
  }
});

export default syncSlice.reducer;
export { sync };
