import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import { HelpIcon } from "@platformsh/ui-kit";
import { css, SECTION } from "Libs/themes";

import { Link } from "ds/Button";
import Heading2 from "Components/styleguide/Heading2";
import ListGroup from "Components/ListGroup";
import Loading from "Components/Loading";
import CopyableArea from "Components/CopyableArea";
import SettingLine from "Components/SettingLine";
import PageDescription from "Components/PageDescription";
import PageMeta from "Components/PageMeta";

const Layout = styled.div`
  word-break: break-all;
  word-wrap: break-word;
  width: 100%;
`;

const ProjectDeployKeyLayout = styled.div`
  word-break: break-all;
  word-wrap: break-word;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Info = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

const Help = styled.p`
  margin: 16px 0 24px 0;
  line-height: 24px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 14px;
  svg {
    margin: 0 8px 0 0;
    vertical-align: middle;
  }
  span {
    text-transform: lowercase;
  }
`;

function ProjectDeployKey({ clientSshKey, isLoading, project, projectId }) {
  const intl = useIntl();

  return (
    <Layout>
      <LiveMessage message={`${projectId} deploy key`} aria-live="polite" />
      <PageMeta title={`Deploy key | ${project?.title}`} />
      <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
        {intl.formatMessage({ id: "deploy_key" })}
      </Heading2>
      <PageDescription>
        {intl.formatMessage({ id: "deploy_key_description" })}
      </PageDescription>
      <Help>
        <HelpIcon />
        {intl.formatMessage({ id: "settings.deployKey.help.a" })}{" "}
        <Link
          href={intl.formatMessage({ id: "links.documentation.deploy_key" })}
        >
          <span>{intl.formatMessage({ id: "settings.deployKey.help.b" })}</span>
        </Link>{" "}
      </Help>

      <ListGroup aria-labelledby="settings-heading">
        {isLoading && <Loading />}
        <SettingLine
          disableBoxShadow
          info={
            <span className="deploy-key-field">
              {intl.formatMessage({ id: "ssh_deploy_key" })}
            </span>
          }
          noToggle={true}
          isOpen={true}
        >
          <ProjectDeployKeyLayout>
            <CopyableArea
              withCopyText
              id="project-deploy-key"
              content={clientSshKey}
            >
              {clientSshKey}
            </CopyableArea>
          </ProjectDeployKeyLayout>
        </SettingLine>
      </ListGroup>
    </Layout>
  );
}

const mapStateToProps = (state, props) => {
  const project = state.project.getIn(
    ["data", props.organizationId, props.projectId],
    new Map()
  );

  return {
    clientSshKey: project.repository && project.repository.client_ssh_key,
    isLoading: state.project.get("loading"),
    project: state.project.getIn([
      "data",
      props.organizationId,
      props.projectId
    ])
  };
};

ProjectDeployKey.propTypes = {
  clientSshKey: PropTypes.string,
  isLoading: PropTypes.bool,
  projectId: PropTypes.string,
  project: PropTypes.object
};

export default connect(mapStateToProps)(ProjectDeployKey);
