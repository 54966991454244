import React from "react";
import PropTypes from "prop-types";

import ContentLayout from "Components/ContentLayout";

import { withReducers } from "Hocs";

const OrganizationSecurityPage = ({ children }) => (
  <ContentLayout id="settings-members" className="settings">
    {children}
  </ContentLayout>
);

OrganizationSecurityPage.propTypes = {
  children: PropTypes.node
};

export default withReducers({
  security: () => import("Reducers/organization/settings/security")
})(OrganizationSecurityPage);
