import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import countryList from "react-select-country-list";

import InputField from "Components/fields/InputField";
import Loading from "Components/Loading";
import InfoDialog from "Components/InfoDialog";
import InfoIcon from "Icons/InfoIcon";
import { Button, ButtonWrapper } from "ds/Button";
import { CreateOrganizationNamespace } from "Reducers/organization";
import getIsLoadingState, { getErrorState } from "Reducers/utils";
import Error from "Components/Error";

import * as S from "./Form.style";
import { useHistory } from "react-router-dom";
import { isUpdatingOrganizationProfileSelector } from "Reducers/organization/profile";

const FIELD_NAME = {
  country: "country",
  label: "label",
  name: "name",
  securityContact: "security_contact",
  companyName: "company_name"
};

const isValidOrgName = name =>
  /^(?![-])[a-z0-9-]{3,39}$/.test(name) &&
  name?.indexOf("--") === -1 &&
  name.charAt(name.length - 1) !== "-";

const CreateOrganizationForm = ({ onSubmit, createdOrganization }) => {
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const history = useHistory();
  const formatMessage = useIntl().formatMessage;
  const theme = useTheme();

  const onFieldChange = (value, fieldName) => {
    const form = { ...formValues, [fieldName]: value };
    setFormValues(form);
    const fieldError = validateFormField(fieldName, form);
    setFormErrors({ ...formErrors, [fieldName]: fieldError });
  };

  const validateFormField = (fieldName, formValues) => {
    switch (fieldName) {
      case FIELD_NAME.label:
        if (!formValues[FIELD_NAME.label])
          return (
            <FormattedMessage
              id="field.is_required"
              values={{ label: fieldName }}
            />
          );
        break;
      case FIELD_NAME.name:
        if (!isValidOrgName(formValues[FIELD_NAME.name]))
          return (
            <FormattedMessage id="organization.input.name.validation-message" />
          );
        break;
      case FIELD_NAME.country:
        if (!formValues[FIELD_NAME.country]) {
          return (
            <FormattedMessage
              id="field.is_required"
              values={{ label: fieldName }}
            />
          );
        }
        break;
    }
  };

  // Validate and return error in each form fields
  const validateForm = formValues => {
    let errors = {};
    for (let key in FIELD_NAME) {
      const fieldName = FIELD_NAME[key];
      errors[fieldName] = validateFormField(fieldName, formValues);
    }
    return errors;
  };

  const { isCreatingOrg, error, isUpdatingProfile } = useSelector(state => ({
    isCreatingOrg: getIsLoadingState(
      state.organization,
      CreateOrganizationNamespace
    ),
    isUpdatingProfile: isUpdatingOrganizationProfileSelector(
      state,
      createdOrganization?.id
    ),
    error: getErrorState(state.organization, CreateOrganizationNamespace)
  }));

  const hasErrors = formValues => {
    const errors = validateForm(formValues);
    return Object.keys(errors).some(field => errors[field]) ? errors : false;
  };

  const onSubmitForm = event => {
    event.preventDefault();
    const errors = hasErrors(formValues);
    setFormErrors(errors);
    if (!errors) {
      onSubmit(
        {
          name: formValues[FIELD_NAME.name],
          label: formValues[FIELD_NAME.label],
          country: formValues[FIELD_NAME.country]?.value
        },
        {
          security_contact: formValues[FIELD_NAME.securityContact],
          company_name: formValues[FIELD_NAME.companyName]
        },
        event
      );
    }
  };

  return (
    <S.Layout>
      <S.Form onSubmit={onSubmitForm}>
        <S.LabelInputField>
          <InputField
            id={FIELD_NAME.label}
            onChange={e => onFieldChange(e.target.value, FIELD_NAME.label)}
            value={formValues?.label}
            placeholder={formatMessage({
              id: "organization.input.label.placeholder"
            })}
          />
        </S.LabelInputField>
        <S.LabelInfo>
          <S.Label>{formatMessage({ id: "organization_label" })}</S.Label>
          <InfoDialog
            icon={<InfoIcon />}
            iconColor={theme.envTreeInactiveIcon}
            text={formatMessage({
              id: "settings.details.informations"
            })}
          />
        </S.LabelInfo>
        <S.Separator />
        <S.SectionTitle>
          <FormattedMessage id="settings.details" />
        </S.SectionTitle>
        <InputField
          id={`organization-name`}
          label={formatMessage({ id: "organization_url" })}
          error={formErrors[FIELD_NAME.name]}
        >
          <S.NameInputField>
            <input
              placeholder={formatMessage({
                id: "organization.input.url.placeholder"
              })}
              name={FIELD_NAME.name}
              id={FIELD_NAME.name}
              required
              value={formValues?.name}
              onChange={e => onFieldChange(e.target.value, FIELD_NAME.name)}
            />
          </S.NameInputField>
          <S.NameInputPrefix>
            {`https://${window.location.host}/`}
            <S.NameInputPrefixValue>
              {formValues?.name ||
                formatMessage({
                  id: "organization.url.preview.placeholder"
                })}
            </S.NameInputPrefixValue>
          </S.NameInputPrefix>
        </InputField>

        <S.CountryDropDown
          options={countryList()
            .getData()
            .map(c => ({ value: c.value, label: c.label }))}
          onChange={option => onFieldChange(option, FIELD_NAME.country)}
          clearable={false}
          fieldType={true}
          required
          label={formatMessage({ id: "select_country" })}
          defaultText={formatMessage({ id: "select_country" })}
          className="field-country"
          error={formErrors[FIELD_NAME.country]}
          inputProps={{
            autoComplete: "off",
            autoCorrect: "off",
            spellCheck: "off"
          }}
        />
        <InputField
          required={false}
          id={FIELD_NAME.companyName}
          onChange={e => onFieldChange(e.target.value, FIELD_NAME.companyName)}
          label={formatMessage({ id: FIELD_NAME.companyName })}
          value={formValues[FIELD_NAME.companyName]}
        />
        <InputField
          onChange={e =>
            onFieldChange(e.target.value, FIELD_NAME.securityContact)
          }
          required={false}
          id={FIELD_NAME.securityContact}
          label={formatMessage({ id: FIELD_NAME.securityContact })}
          value={formValues[FIELD_NAME.securityContact]}
          type="email"
          placeholder="Email Address"
        />

        <Error>{error?.detail}</Error>
        <ButtonWrapper spacing="modal">
          {isCreatingOrg || isUpdatingProfile ? (
            <Loading />
          ) : (
            <>
              <Button
                type="submit"
                id="save_organization_general_settings_button"
              >
                {formatMessage({ id: "create" })}
              </Button>
              <Button
                onClick={() => history.replace("/")}
                id="cancel-organization-name-btn"
                variant="secondary"
              >
                {formatMessage({ id: "cancel" })}
              </Button>
            </>
          )}
        </ButtonWrapper>
      </S.Form>
    </S.Layout>
  );
};

CreateOrganizationForm.propTypes = {
  onSubmit: PropTypes.func,
  createdOrganization: PropTypes.object
};

export default CreateOrganizationForm;
