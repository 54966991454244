import styled from "styled-components";
import { css, getCSSVarString, SECTION, LINK } from "Libs/themes";

const TreeContainer = styled.div`
  & & ul,
  & li {
    position: relative;
    width: 100%;
    display: inline-block;
  }

  & {
    cursor: pointer;
  }

  & ul {
    list-style: none;
    font-size: 14px;
    padding-left: 0;
    line-height: 30px;
  }

  // Remove leading from the top element of the list
  & > ul:first-child > li:first-child::after {
    border-left: none;
  }

  // Remove leading from the top element of the list
  & > ul:first-child > li:first-child::before {
    border-top: none;
  }

  & ul ul {
    // We only want padding after the first level of nesting
    padding-left: 8px;
  }

  & li::before,
  & li::after {
    content: "";
    position: absolute;
    left: -10px;
  }

  & li::before {
    top: 14px;
    width: 4px;
    height: 0;
  }

  & li::after {
    height: 100%;
    width: 0px;
    top: 4px;
  }

  /* Remove the bottom half for the last element on a list */
  & ul > li:last-child::after {
    height: 10px;
  }

  &:hover li::before {
    border-top: 1px solid
      ${getCSSVarString(
        SECTION,
        "periwinkle-grey",
        "border-color",
        "tree-container"
      )};
  }

  &:hover li::after {
    border-left: 1px solid
      ${getCSSVarString(
        SECTION,
        "periwinkle-grey",
        "border-color",
        "tree-container"
      )};
  }

  /* Set default font-weight */
  li {
    font-weight: 400;
    color:var(--link-slate-color,var(--link-slate,var(--slate)));
  }

  li:hover {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
  }

  /* Override font-weight only for the first two levels of the tree*/
  & > ul > li,
  & > ul > li > ul > li {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color:var(--link-slate-color,var(--link-slate,var(--slate)));
  }
  a.active {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
  }
`;

export default TreeContainer;
