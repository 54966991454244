import { fromJS, Map } from "immutable";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import logger from "Libs/logger";
import type Environment from "platformsh-client/types/model/Environment";
import type Variable from "platformsh-client/types/model/Variable";
import { AsyncThunkOptionType } from "Reducers/types";

type EnvironmentVariableDataType = {
  id: string;
  name: string;
  value: string;
  is_json: boolean;
  is_enabled: boolean;
  is_inheritable: boolean;
  is_sensitive: boolean;
  visible_build: boolean;
  visible_runtime: boolean;
};

type GetVariableThunkPropType = {
  environmentId: string;
  projectId: string;
  organizationId: string;
};
/**
 * Get project's variables
 * @param {string} projectId
 * @param {string} environmentId
 *
 */
export const getVariables = createAsyncThunk<
  Variable[],
  GetVariableThunkPropType,
  AsyncThunkOptionType
>(
  "app/project/environment/variables",
  async ({ environmentId, projectId }, { rejectWithValue }) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      //@ts-ignore
      //TODO needs to relax none required args in client
      const variables = await client.getEnvironmentVariables(
        projectId,
        encodeURIComponent(environmentId)
      );
      return variables;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);

type AddEnvironmentVariableThunkPropType = {
  environment: Environment;
  environmentId: string;
  projectId: string;
  organizationId: string;
  data: EnvironmentVariableDataType;
};

/**
 * Create a variable
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {object} environment
 * @param {object} data
 *
 */

export const addVariable = createAsyncThunk<
  any,
  AddEnvironmentVariableThunkPropType
>(
  "app/project/environment/variable/add",
  async ({ data, environment }, { rejectWithValue }) => {
    try {
      const result = await environment.setVariable(
        data.name,
        data.value,
        data.is_json,
        data.is_enabled,
        data.is_inheritable,
        data.is_sensitive,
        data.visible_build,
        data.visible_runtime
      );

      if ((result.data && result.data.code / 100) === 4) {
        return rejectWithValue({ error: result.message });
      }

      const variable = await result?.getEntity();
      return variable;
    } catch (err: any) {
      logger(err, {
        environment,
        action: "environmentAddVariable"
      });
      return rejectWithValue({ error: err.message });
    }
  }
);

type UpdateEnvironmentVariableThunkPropType = {
  environmentId: string;
  projectId: string;
  organizationId: string;
  variable: Variable;
  data: EnvironmentVariableDataType;
};

/**
 * Update a variable
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {string} environmentId
 * @param {object} variable
 * @param {object} data
 *
 */
export const updateVariable = createAsyncThunk<
  any,
  UpdateEnvironmentVariableThunkPropType
>(
  "app/project/environment/variable/update",
  async (props, { rejectWithValue }) => {
    const { environmentId, projectId, variable, data } = props;
    const fields = Object.assign(
      {},
      { projectId, environmentId },
      { variableId: variable.id },
      data
    );

    try {
      const result = await variable.update(fields);
      const newVariable = await result?.getEntity();
      return newVariable;
    } catch (err: any) {
      logger(err, {
        environmentId,
        projectId,
        action: "environmentUpdateVariable",
        variable,
        data
      });
      return rejectWithValue({ error: err.message });
    }
  }
);

type DeleteEnvironmentVariableThunkProptype = {
  variable: Variable;
  environmentId: string;
  projectId: string;
  organizationId: string;
};
/**
 * Delete a variable
 *
 * @param {string} organizationId
 * @param {string} projectId
 * @param {string} environmentId
 * @param {object} variable
 *
 */
export const deleteVariable = createAsyncThunk<
  any,
  DeleteEnvironmentVariableThunkProptype
>(
  "app/project/environment/variable/delete",
  async (
    { variable, environmentId, projectId, organizationId },
    { rejectWithValue }
  ) => {
    try {
      await variable.delete();
      return variable;
    } catch (err: any) {
      logger(err, {
        variable,
        environmentId,
        projectId,
        organizationId,
        action: "environmentDeleteVariable"
      });
      return rejectWithValue({ error: err.message });
    }
  }
);

const variables = createSlice({
  name: "app/project/environment/variable",
  reducers: {},
  initialState: Map(),
  extraReducers: builder =>
    builder
      // Retrieve Variable
      .addCase(getVariables.pending, state => state.set("loading", true))
      .addCase(getVariables.fulfilled, (state, action) => {
        const { environmentId, organizationId, projectId } = action.meta.arg;
        return state
          .setIn(
            ["data", organizationId, projectId, environmentId],
            action.payload.reduce((list, variable) => {
              return list.set(variable.id, variable);
            }, Map())
          )
          .set("loading", false)
          .set("status", "idle");
      })
      .addCase(getVariables.rejected, (state, action) =>
        state.set("errors", action.payload).set("loading", false)
      )

      //Add Variable
      .addCase(addVariable.pending, state =>
        state.set("status", "pending").delete("errors").set("loading", true)
      )
      .addCase(addVariable.fulfilled, (state, action) => {
        const { environment, organizationId, projectId } = action.meta.arg;
        return state
          .setIn(
            [
              "data",
              organizationId,
              projectId,
              environment.id,
              action.payload.id
            ],
            action.payload
          )
          .set("status", "added")
          .set("loading", false)
          .remove("errors");
      })
      .addCase(addVariable.rejected, (state, action) =>
        state
          .set("errors", action.payload)
          .set("status", "rejected")
          .set("loading", false)
      )

      //Update Variable
      .addCase(updateVariable.pending, state =>
        state.set("status", "pending").delete("errors").set("loading", true)
      )
      .addCase(updateVariable.fulfilled, (state, action) => {
        const { environmentId, organizationId, projectId } = action.meta.arg;
        return state
          .setIn(
            [
              "data",
              organizationId,
              projectId,
              environmentId,
              action.payload.id
            ],
            fromJS(action.payload)
          )
          .set("status", "updated")
          .set("loading", false);
      })
      .addCase(updateVariable.rejected, (state, action) =>
        state
          .set("errors", action.payload)
          .set("status", "rejected")
          .set("loading", false)
      )

      //DELETE
      .addCase(deleteVariable.pending, state =>
        state.set("status", "pending").delete("errors").set("loading", true)
      )
      .addCase(deleteVariable.fulfilled, (state, action) => {
        const { environmentId, organizationId, projectId } = action.meta.arg;
        return state
          .deleteIn([
            "data",
            organizationId,
            projectId,
            environmentId,
            action.payload.id
          ])
          .set("status", "deleted")
          .set("loading", false);
      })
      .addCase(deleteVariable.rejected, (state, action) =>
        state.set("errors", action.payload).set("loading", false)
      )
});

export default variables.reducer;
