import React, { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { withReducers } from "Hocs";

import NavBar from "Containers/NavBar";
import PageLayout from "Components/PageLayout";

import BillingPage from "./pages/Page";

const Page = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  return (
    <Fragment>
      <NavBar
        push={push}
        projectId={""}
        organizationId={""}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout>
        <BillingPage />
      </PageLayout>
    </Fragment>
  );
};

export default withReducers({
  setup: () => import("Reducers/project/setup"),
  plan: () => import("Reducers/plan")
})(Page);
