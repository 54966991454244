import React from "react";
import useUniqueId from "Libs/useUniqueId";

const ChevronIcon = () => {
  const uid = useUniqueId();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`chevron-icon-${uid}`}
      aria-hidden="true"
    >
      <title id={`chevron-icon-${uid}`}>Chevron icon</title>
      <path
        d="M6.90002 9.8999C6.90002 9.5999 7.00002 9.3999 7.20002 9.1999C7.60002 8.7999 8.20002 8.7999 8.60002 9.1999L12.1 12.6999L15.6 9.1999C16 8.7999 16.6 8.7999 17 9.1999C17.4 9.5999 17.4 10.1999 17 10.5999L12.8 14.7999C12.4 15.1999 11.8 15.1999 11.4 14.7999L7.20002 10.5999C7.00002 10.3999 6.90002 10.1999 6.90002 9.8999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronIcon;
