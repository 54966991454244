import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { LiveMessage } from "react-aria-live";

import useDecodedParams from "Hooks/useDecodedParams";

import ServicesDisplay from "../../../../common/containers/ServicesDisplay";
import ListGroup from "Components/ListGroup";
import Heading2 from "Components/styleguide/Heading2";
import ToggleTree from "Components/ToggleTree";

import { withReducers } from "Hocs";
import useLocalForage from "Hooks/useLocalForage";

import * as S from "./styles";

import NoServices from "./containers/NoServices";

const EnvironmentServices = ({ children }) => {
  const [listMode, setListMode] = useLocalForage("project_environments_layout");
  const { environmentId } = useDecodedParams();

  return (
    <S.EnvironmentServicesLayout listMode={listMode}>
      <LiveMessage message={`${environmentId} services`} aria-live="polite" />
      <NoServices>
        <ListGroup
          className="service-tree-wrapper col-3"
          aria-labelledby="services-heading"
        >
          <Heading2 id="services-heading" style={{ marginBottom: "16px" }}>
            <S.ToolBar>
              <FormattedMessage id="apps_services" />
              <ToggleTree
                toggleLayout={value => setListMode(!value)}
                gridLayout={!listMode}
              />
            </S.ToolBar>
          </Heading2>
          <ServicesDisplay
            treePositionY={10}
            maxHeight={500}
            height="450px"
            minHeight="400px"
            width="316px"
            listMode={listMode}
            hasInstanceSelector={false}
          />
        </ListGroup>
        {children}
      </NoServices>
    </S.EnvironmentServicesLayout>
  );
};

EnvironmentServices.propTypes = {
  children: PropTypes.node
};

export default withReducers({
  service: () => import("Reducers/environment/service")
})(EnvironmentServices);
