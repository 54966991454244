import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Map } from "immutable";
import { useIntl } from "react-intl";

import {
  loadSubscriptions,
  loadingListSelector,
  subscriptionsSelector
} from "Reducers/organization/subscription";
import {
  organizationByDescriptionIdSelector,
  organizationsSelector
} from "Reducers/organization";

import { withReducers } from "Hocs";

import SettingLine from "Components/SettingLine";
import SearchInput from "Components/SearchInput";
import PlanListItem from "../../components/ListItem";
import DropdownMultiPick from "Components/DropdownMultiPick";
import IconFilter from "Icons/IconFilter";
import { getCSSVarString, ICON } from "Libs/themes";

import EmptyProjects from "Components/illustrations/EmptyProjects";
import Heading2 from "Components/styleguide/Heading2";
import NewProjectButton from "Components/NewProjectButton";
import LoadingIcon from "Icons/LoadingIcon";

import PlanDetails from "../PlanDetails";
import TrialRemaining from "../../components/TrialRemaining";

import * as S from "./List.styles";

const PlanList = ({ organizationId }) => {
  const [activeFilters, updateActiveFilters] = useState([]);
  const [filterTitle, setFilterTitle] = useState("");
  const [openPreview, setOpenPreview] = useState();
  const [total, setTotal] = useState();

  const dispatch = useDispatch();
  const intl = useIntl();
  const subscriptionsLoading = useSelector(loadingListSelector);
  const subscriptions = useSelector(state =>
    subscriptionsSelector(state, { organizationId })
  );

  const planTypes = useMemo(() => {
    // collate all Plans in each subscription to create
    // a unique set of filter
    if (subscriptions.size) {
      const plans = subscriptions.valueSeq().reduce((plans, sub) => {
        plans = { ...plans, ...sub.project_options.plan_titles };
        return plans;
      }, {});
      const types = Object.keys(plans).map(value => ({
        value,
        label: plans[value]
      }));
      types.unshift({ value: "all_type", label: "All plans" });
      updateActiveFilters(types);
      return types;
    }
    return [];
  }, [subscriptions.size]);

  const organizations = useSelector(organizationsSelector);
  const user = useSelector(state => state.app?.get("me", new Map())?.toJS());
  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  useEffect(() => {
    if (organization) {
      dispatch(loadSubscriptions({ organizationId }));
    }
  }, [organizationId, organization]);

  const loadEstimate = async subscriptionId => {
    if (!subscriptions) {
      return false;
    }

    const subscription = subscriptions.find(s => s.id === subscriptionId);

    if (!subscription) {
      return false;
    }

    const { total } = await subscription.getEstimate();
    setTotal(total);
  };

  const selectFilter = ({ value, label }) => {
    const filterExists = activeFilters.some(f => f.value === value);
    if (value === "all_type") {
      filterExists ? updateActiveFilters([]) : updateActiveFilters(planTypes);
      return;
    }
    const currentFilter = activeFilters.filter(f => f.value !== "all_type");

    if (!filterExists && currentFilter.length === planTypes.length - 2) {
      updateActiveFilters(planTypes);
      return;
    }
    if (filterExists) {
      updateActiveFilters(currentFilter.filter(f => f.value !== value));
      return;
    }
    updateActiveFilters([...currentFilter, { value, label }]);
  };

  const subscriptionsFilter = item => {
    if (activeFilters.length === 0) return false;
    if (activeFilters.length === planTypes.length) {
      return true;
    }
    return activeFilters.some(
      f => f.value.toLowerCase() === item.plan?.toLowerCase()
    );
  };

  const filterTitleFilter = item => {
    if (!filterTitle) {
      return true;
    }
    return item.project_title
      ?.toLowerCase()
      .includes(filterTitle?.toLowerCase());
  };

  const expand = (subscriptionId, projectId) => {
    if (projectId === openPreview) {
      setOpenPreview();
    } else {
      setOpenPreview(projectId);
      loadEstimate(subscriptionId);
    }
  };

  if (subscriptionsLoading) return <LoadingIcon />;
  return (
    <S.Layout>
      {subscriptions?.size > 0 ? (
        <>
          <S.PlansHeaderWrapper>
            <Heading2>{intl.formatMessage({ id: "plans" })}</Heading2>
            <S.FiltersDiv>
              <SearchInput
                placeholder="Search"
                className="float"
                onChange={e => setFilterTitle(e.target.value)}
                value={filterTitle}
                id="billing-plan-search"
              />

              <DropdownMultiPick
                options={planTypes}
                title={intl.formatMessage({ id: "filter" })}
                defaultValue={planTypes.all_plans}
                value={activeFilters}
                onChange={selectFilter}
                withArrow={false}
                withBorder={false}
                iconAfter={true}
                dropdownWidth="160px"
                icon={
                  <span className="icon">
                    <IconFilter
                      color={"var(--icon-ebony-light-color,var(--icon-ebony-light,var(--ebony-light)))"}
                    />
                  </span>
                }
              />
            </S.FiltersDiv>
          </S.PlansHeaderWrapper>
          <TrialRemaining />

          {subscriptions
            .valueSeq()
            .filter(subscriptionsFilter)
            .filter(filterTitleFilter)
            .map((sub, index) => (
              <SettingLine
                key={`line-${index}-${sub.id}`}
                info={
                  <PlanListItem
                    organizationId={organizationId}
                    organizations={organizations}
                    subscription={sub}
                  />
                }
                isOpen={openPreview === sub.project_id}
                onClick={() => expand(sub.id, sub.project_id)}
              >
                {openPreview === sub.project_id && (
                  <PlanDetails
                    organizationId={organizationId}
                    organizations={organizations}
                    subscription={sub}
                    total={total}
                  />
                )}
              </SettingLine>
            ))}
        </>
      ) : (
        <S.EmptyWrapper>
          <S.EmptyImage>
            <EmptyProjects />
          </S.EmptyImage>
          <Heading2>
            {intl.formatMessage({
              id: "billing.plan.list.empty.title",
              defaultMessage: "You have no project plans"
            })}
          </Heading2>
          <p>
            {intl.formatMessage({
              id: "billing.plan.list.empty.description",
              defaultMessage:
                "Find plan information here for any projects that you own."
            })}
          </p>
          <NewProjectButton organizationId={organizationId} user={user} />
        </S.EmptyWrapper>
      )}
    </S.Layout>
  );
};

PlanList.propTypes = {
  organizationId: PropTypes.string
};

export default withReducers({
  organizationSubscription: () => import("Reducers/organization/subscription")
})(PlanList);
