import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";
import client from "Libs/platform";
import logger from "Libs/logger";

import { getCSSVarString, css, INPUT, ICON, getColor } from "Libs/themes";

import Loading from "Components/Loading";
import Button from "ds/Button";
import Label from "Components/fields/Label";
import InputField from "Components/fields/InputField";
import RequiredTag from "Components/fields/RequiredTag";
import Error from "Components/Error";

const Layout = styled.div`
  max-width: 100%;
  .StripeElement {
    background-color: ${getColor(INPUT, "ice", "background-color")};
    color:var(--input-night-color,var(--input-night,var(--night)));
    padding: 12px 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 32px;
  }
  .setting-line {
    background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
    border: 1px solid var(--input-ice-background-color,var(--input-ice,var(--ice)));
    color:var(--input-night-color,var(--input-night,var(--night)));
    border-radius: 2px;
    min-height: 32px;
    line-height: 38px;
    height: 40px;
    font-size: 15px;
  }
  .card-number input {
    letter-spacing: 4px;
  }
`;

const CardSection = ({
  number,
  edit,
  onEdit,
  onCancel,
  hideButtons,
  onSuccess,
  name_line,
  email,
  organizationId // The organization.id
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [secret, setSecret] = useState();
  const [cardError, setCardError] = useState(null);
  const [name, setName] = useState(name_line);
  const [nameError, setNameError] = useState(false);
  const [billingEmail, setBillingEmail] = useState(email);
  const [emailError, setEmailError] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const intl = useIntl();

  useEffect(() => {
    let isCanceled = false;
    const createIntent = async () => {
      try {
        const s = await client.createOrganizationPaymentSourceIntent(
          organizationId
        );
        if (isCanceled) {
          return;
        }
        setSecret(s?.client_secret);
      } catch (err) {
        // setError(err);
      }
    };

    createIntent();
    return () => (isCanceled = true);
  }, [organizationId]);

  const handleSubmit = async ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if (!billingEmail) {
      setEmailError("Email required");
      return;
    }

    if (!name) {
      setNameError("Name required");
      return;
    }

    if (edit) {
      setIsLoading(true);

      const cardElement = elements.getElement(CardElement);

      try {
        const response = await stripe.confirmCardSetup(secret, {
          payment_method: {
            billing_details: { name, email: billingEmail },
            card: cardElement
          }
        });
        if (response?.error?.message) {
          setCardError(response.error.message);
          throw response.error;
        } else {
          setCardError(null);
        }

        const cardTokenId = response?.setupIntent?.payment_method;

        const creditcard = await client.addOrganizationPaymentSource(
          organizationId,
          "credit-card",
          cardTokenId
        );
        setIsLoading(false);
        onSuccess && onSuccess(creditcard);
      } catch (err) {
        if (![404, 403, 400].includes(err.code)) {
          logger(err);
        }
        setIsLoading(false);
      }
    } else {
      onSuccess && onSuccess();
    }
  };

  return (
    <Layout>
      <form aria-labelledby="edit-card" id="stripe-payment-form">
        {isLoading && <Loading />}
        {!edit && number ? (
          <React.Fragment>
            <LiveMessage
              message="click update credit card to edit"
              aria-live="polite"
            />
            <InputField
              label={intl.formatMessage({ id: "credit_card" })}
              className="card-number"
              isDisabled={true}
              value={number.replace(/X/g, "•").replace(/-/g, " ")}
            />
            <div className="">
              <Button onClick={onEdit}>
                {intl.formatMessage({ id: "update_credit_card" })}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <div className="new-card">
            <LiveMessage
              message="enter credit card information"
              aria-live="polite"
            />
            <InputField
              id="name_line"
              name="name_line"
              label={intl.formatMessage({ id: "full_name" })}
              placeholder={intl.formatMessage({ id: "full_name" })}
              className="name_line"
              value={name}
              onChange={e => setName(e.target.value)}
              error={nameError}
            />
            <InputField
              id="email"
              name="email"
              label={intl.formatMessage({ id: "email" })}
              value={billingEmail}
              onChange={e => setBillingEmail(e.target.value)}
              error={emailError}
            />
            <Label>
              {intl.formatMessage({ id: "credit_card_number" })} <RequiredTag />
            </Label>
            <CardElement
              className="stripe-card"
              options={{
                style: {
                  base: {
                    backgroundColor: getColor(INPUT, "ice", "background-color"),
                    fontSize: "14px",
                    iconColor: getColor(ICON, "night", "color"),
                    color: getColor(INPUT, "night", "color"),
                    "::placeholder": {
                      fontSize: "14px",
                      color: getColor(INPUT, "night", "color")
                    }
                  }
                },
                iconStyle: "solid"
              }}
            />
            {cardError && <Error>{cardError}</Error>}

            {!hideButtons && (
              <React.Fragment>
                <Button
                  type="button"
                  aria-label={intl.formatMessage({ id: "save_changes" })}
                  id="stripe_cardsection_submit"
                  className="primary"
                  onClick={handleSubmit}
                  disabled={!billingEmail || !name}
                >
                  {intl.formatMessage({ id: "save_changes" })}
                </Button>
                {number && (
                  <Button
                    type="button"
                    aria-label={intl.formatMessage({ id: "save_changes" })}
                    id="stripe_cardsection_cancel"
                    className="secondary"
                    onClick={onCancel}
                  >
                    {"cancel"}
                  </Button>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </form>
    </Layout>
  );
};

CardSection.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  number: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
  organizationId: PropTypes.string,
  onCancel: PropTypes.func,
  name_line: PropTypes.string
};

export default CardSection;
