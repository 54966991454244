import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { gitProjectSelector } from "Reducers/project";
import { selectEnvironmentId } from "Reducers/settingsMenu";
import useMediaQuery from "Hooks/useMediaQuery";
import useDecodedParams from "Hooks/useDecodedParams";
import ChevronIcon from "Components/icons/ChevronIcon";
import Chevron from "./Chevron";
import useToggle from "./useToggle";
import EnvironmentSettingsMenu from "./EnvironmentSettingsMenu";
import ProjectSettingsMenu from "./ProjectSettingsMenu";
import {
  BackLinkContainer,
  BREAKPOINT,
  ExpandedMobileMenu,
  MenuTriggerContainer,
  MobileMenuContainer,
  Sidebar
} from "./Menu.styles";

const Menu = () => {
  const showMobileMenu = !useMediaQuery(BREAKPOINT);
  const intl = useIntl();
  const { organizationId, projectId } = useDecodedParams();
  const environmentId = useSelector(selectEnvironmentId);
  const { title: projectTitle } = useSelector(state =>
    gitProjectSelector(state, {
      organizationId,
      projectId
    })
  );

  const encodedEnvironmentId = encodeURIComponent(environmentId);

  const link = environmentId
    ? `/${organizationId}/${projectId}/${encodedEnvironmentId}`
    : `/${organizationId}/${projectId}`;

  const linkText = environmentId
    ? `${intl.formatMessage({ id: "back_to" })} ${environmentId}`
    : `${intl.formatMessage({ id: "back_to" })} ${projectTitle}`;

  const MenuType = showMobileMenu ? MobileMenu : SidebarMenu;

  return (
    <MenuType backLink={link} backLinkText={linkText}>
      <ProjectSettingsMenu />
      <EnvironmentSettingsMenu />
    </MenuType>
  );
};

const SidebarMenu = ({ backLink, backLinkText, children }) => {
  return (
    <Sidebar>
      <BackLinkContainer>
        <Link id="back-from-settings" to={backLink}>
          <Chevron />
          <span>{backLinkText}</span>
        </Link>
      </BackLinkContainer>
      <MenuTriggerContainer>
        <h3>Settings</h3>
      </MenuTriggerContainer>
      {children}
    </Sidebar>
  );
};

SidebarMenu.propTypes = {
  backLink: PropTypes.string,
  backLinkText: PropTypes.string,
  children: PropTypes.node
};

const MobileMenu = ({ backLink, backLinkText, children }) => {
  const [expanded, { toggle }] = useToggle(false);
  return (
    <MobileMenuContainer>
      <BackLinkContainer id="back-from-settings">
        <Link to={backLink}>
          <Chevron />
          <span>{backLinkText}</span>
        </Link>
      </BackLinkContainer>
      <MenuTriggerContainer onClick={toggle}>
        <h3>Settings</h3>
        <ChevronIcon isOpen={expanded} animate={true} />
      </MenuTriggerContainer>
      {expanded && <ExpandedMobileMenu>{children}</ExpandedMobileMenu>}
    </MobileMenuContainer>
  );
};

MobileMenu.propTypes = {
  backLink: PropTypes.string,
  backLinkText: PropTypes.string,
  children: PropTypes.node
};

export default Menu;
