import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Map, fromJS } from "immutable";

import config from "Constants/api_config";
import logger from "Libs/logger";
import { request } from "Libs/platform";
import { hasHtml, getOrganizationId } from "Libs/utils";
import { setIsLoadingState } from "Reducers/utils";

export const getOrganizationOptions = createAsyncThunk(
  "organization/setup/options",
  async ({ organizationId, params }, { getState, rejectWithValue }) => {
    const templateUrl = getState().setup?.getIn(["config", "template"]);
    let optionsParams = {
      environments: 3,
      storage: 5120,
      user_licenses: 1
    };

    if (templateUrl) optionsParams.options_url = templateUrl;

    try {
      const options = await request(
        `${config.api_url}/organizations/${getOrganizationId(
          getState,
          organizationId
        )}/setup/options`,
        "GET",
        params || optionsParams,
        {
          "Content-Type": "application/json"
        }
      );

      return options;
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "setup_load_options"
        });
      }
      return rejectWithValue({ errors: err });
    }
  }
);

export const getOrganizationCatalog = createAsyncThunk(
  "organization/setup/catalog",
  async ({ catalogUrl, organizationId }, { getState, rejectWithValue }) => {
    try {
      const url = catalogUrl
        ? catalogUrl
        : `${config.api_url}/organizations/${getOrganizationId(
            getState,
            organizationId
          )}/setup/catalog${
            process.env.VENDOR_NAME ? `?vendor=${process.env.VENDOR_NAME}` : ""
          }`;
      const catalog = await request(url, "GET");

      return catalog;
    } catch (err) {
      logger(err, { action: "setup_load_catalog" });
      return rejectWithValue({ errors: err });
    }
  }
);

const organizationSetup = createSlice({
  name: "organizationSetup",
  initialState: Map(),
  reducers: {
    resetSetup: state => state.delete("config"),
    saveConfig: (state, { payload }) => state.mergeIn(["config"], payload)
  },
  extraReducers: {
    // GET OPTIONS
    [getOrganizationOptions.pending]: (state, action) =>
      state
        .deleteIn([action.meta?.arg?.organizationId, "options", "errors"])
        .setIn([action.meta?.arg?.organizationId, "options", "loading"], true),
    [getOrganizationOptions.fulfilled]: (state, { meta, payload }) =>
      state
        .setIn([meta?.arg?.organizationId, "options", "data"], fromJS(payload))
        .setIn([meta?.arg?.organizationId, "options", "loading"], false),
    [getOrganizationOptions.rejected]: (state, { meta, payload }) =>
      state
        .setIn([meta?.arg?.organizationId, "options", "loading"], false)
        .setIn([
          meta?.arg?.organizationId,
          "options",
          "errors",
          payload.errors
        ]),

    // GET CATALOG
    [getOrganizationCatalog.pending]: (state, action) =>
      setIsLoadingState(state, action.meta?.arg?.organizationId)
        .deleteIn([action.meta?.arg?.organizationId, "catalog", "errors"])
        .setIn([action.meta?.arg?.organizationId, "catalog", "loading"], true),
    [getOrganizationCatalog.fulfilled]: (state, { meta, payload }) => {
      return setIsLoadingState(state, meta?.arg?.organizationId, false)
        .setIn([meta?.arg?.organizationId, "catalog", "data"], fromJS(payload))
        .setIn([meta?.arg?.organizationId, "catalog", "loading"], false);
    },
    [getOrganizationCatalog.rejected]: (state, { meta, payload }) =>
      setIsLoadingState(state, meta?.arg?.organizationId, false)
        .setIn([meta?.arg?.organizationId, "options", "loading"], false)
        .setIn([meta?.arg?.organizationId, "options", "errors", payload.errors])
  }
});

export const { resetSetup, saveConfig } = organizationSetup.actions;
export default organizationSetup.reducer;

export const getOrganizationOptionsSelector = (state, organizationId) =>
  state.organizationSetup.getIn([organizationId, "options", "data"]);
