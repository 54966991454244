import React, { useState } from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";
import client from "Libs/platform";

import logger from "Libs/logger";

import Button from "ds/Button";
import InputField from "Components/fields/InputField";
import Label from "Components/fields/Label";
import RequiredTag from "Components/fields/RequiredTag";
import InfoDialog from "Components/InfoDialog";
import Error from "Components/Error";

const Layout = styled.div`
  .StripeElement {
    background: #f5f7fa;
    padding: 12px 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
  }
  .setting-line {
    background: #f5f7fa;
    border: 1px solid #f5f7fa;
    border-radius: 2px;
    min-height: 32px;
    line-height: 38px;
    height: 40px;
    font-size: 15px;
  }
  .card-number input {
    letter-spacing: 4px;
  }
  .input-field,
  .stripe-card {
    width: 316px;
  }
`;

const Disclamer = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 24px;
  color: #4b6180;
  margin-bottom: 16px;
  .info-dialog {
    height: 24px;
    width: 24px;
    min-width: 24px;
    margin-right: 12px;
    .dialog > div {
      width: 520px;
    }
  }
`;

const NewCardWrapper = styled.div`
  padding: 0;
  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: 316px 316px;
    grid-column-gap: 32px;
    width: auto;
  }
`;

const SepaSection = props => {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [error, setError] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const { intl, number, onEdit, onCancel, hideButtons, edit } = props;

  const handleSubmit = async ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if (props.edit) {
      try {
        const sourceData = {
          type: "sepa_debit",
          // Hardcoded currency -- SEPA transactions must be in EUR.
          currency: "eur",
          owner: {
            name: props.name,
            email
          },
          mandate: {
            // Automatically send a mandate notification email to your customer
            // once the source is charged.
            notification_method: "email"
          }
        };

        const ibanElement = elements.getElement(IbanElement);

        const response = await stripe.createSource(ibanElement, sourceData);

        if (response.error) {
          throw response.error;
        }

        const data = await client.addPaymentSource(
          "stripe_sepa_debit",
          response.source.id
        );

        setError(null);

        props.onSuccess && props.onSuccess(data);
      } catch (err) {
        logger(err);
        setError(err);
      }
    } else {
      props.onSuccess && props.onSuccess();
    }
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const onNameChange = e => {
    setName(e.target.value);
  };

  return (
    <Layout>
      {error && (
        <Error>
          {error.message || error.description || JSON.stringify(error, null, 4)}
        </Error>
      )}
      <Disclamer>
        <InfoDialog
          title={intl.formatMessage({ id: "bank_account_payments" })}
          text={intl.formatMessage({ id: "sepa_disclamer" })}
        />
        <span>{intl.formatMessage({ id: "sepa_disclamer_short" })}</span>
      </Disclamer>
      <form aria-labelledby="edit-card" id="stripe-payment-form">
        {!edit && number ? (
          <React.Fragment>
            <LiveMessage
              message="click update credit card to edit"
              aria-live="polite"
            />
            <InputField
              label="credit card number"
              className="card-number"
              isDisabled={true}
              value={number && number.replace(/X/g, "•").replace(/-/g, " ")}
            />
            <div>
              <Button
                onClick={onEdit}
                type="button"
                aria-label={intl.formatMessage({ id: "update_bank_account" })}
              >
                {intl.formatMessage({ id: "update_bank_account" })}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <NewCardWrapper className="new-card">
            <LiveMessage
              message={intl.formatMessage({ id: "bank_account_payments" })}
              aria-live="polite"
            />
            <InputField
              id="name_line"
              name="name_line"
              label={intl.formatMessage({ id: "full_name" })}
              placeholder={intl.formatMessage({ id: "full_name" })}
              className="name_line"
              value={name}
              onChange={onNameChange}
            />
            <InputField
              label={intl.formatMessage({ id: "email" })}
              value={email}
              onChange={onChangeEmail}
            />
            <div>
              <Label>
                {intl.formatMessage({ id: "bank_account_number" })}{" "}
                <RequiredTag />
              </Label>
              <IbanElement
                className="stripe-card"
                options={{
                  supportedCountries: ["SEPA"],
                  style: {
                    base: {
                      fontSize: "14px",
                      iconColor: "rgba(0,0,0,0.3)",
                      "::placeholder": {
                        fontSize: "14px",
                        color: "rgba(56, 72, 94, 0.5)"
                      }
                    }
                  },
                  iconStyle: "solid"
                }}
              />
            </div>
            {!hideButtons && (
              <React.Fragment>
                <Button id="stripe_cardsection_submit" onClick={handleSubmit}>
                  {intl.formatMessage({ id: "save_changes" })}
                </Button>
                {number && (
                  <Button
                    id="stripe_cardsection_cancel"
                    variant="secondary"
                    onClick={onCancel}
                  >
                    {"cancel"}
                  </Button>
                )}
              </React.Fragment>
            )}
          </NewCardWrapper>
        )}
      </form>
    </Layout>
  );
};

SepaSection.propTypes = {
  name: PropTypes.string,
  number: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  intl: PropTypes.object,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func
};

export default injectIntl(SepaSection);
