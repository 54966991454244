import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import momentjs from "moment";

import useDecodedParams from "Hooks/useDecodedParams";

import { formatAsCurrency } from "Libs/utils";

import { AddButton } from "ds/Button";
import SettingLine from "Components/SettingLine";

import * as S from "./VouchersList.styles";

const formatDate = date => {
  return momentjs(date).format("HH:MM, DD/MM/YYYY");
};

function VouchersList({ vouchers }) {
  const intl = useIntl();
  const { organizationId } = useDecodedParams();

  const [expanded, setExpanded] = useState(null);

  const toggleExpanded = code => {
    expanded === code ? setExpanded(null) : setExpanded(code);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <AddButton
          css={{ float: "right" }}
          to={`/${organizationId}/-/billing/vouchers/add`}
        >
          {intl.formatMessage({ id: "vouchers.add" })}
        </AddButton>
        <S.Heading>{intl.formatMessage({ id: "vouchers" })}</S.Heading>
        <S.Intro>{intl.formatMessage({ id: "vouchers.intro" })}</S.Intro>
      </S.Header>

      <S.Headers>
        <S.HeaderItem>
          {intl.formatMessage({ id: "voucher.list.headers.code" })}
        </S.HeaderItem>
        <S.HeaderItem>
          {intl.formatMessage({ id: "voucher.list.headers.credit" })}
        </S.HeaderItem>
        <S.HeaderItem>
          {intl.formatMessage({ id: "voucher.list.headers.balance" })}
        </S.HeaderItem>
        <S.HeaderItem>
          {intl.formatMessage({ id: "voucher.list.headers.status" })}
        </S.HeaderItem>
      </S.Headers>

      {vouchers.map((voucher, index) => (
        <SettingLine
          key={`line-${index}-${voucher.code}`}
          info={
            <S.Preview>
              <S.Code>{voucher.code}</S.Code>
              <S.Data>
                {formatAsCurrency(voucher.amount, voucher.currency)}
              </S.Data>
              <S.Data>
                {formatAsCurrency(voucher.remaining_balance, voucher.currency)}
              </S.Data>
              <S.Data>
                {voucher.remaining_balance > 0 ? (
                  <S.Redeemed>
                    {intl.formatMessage({ id: "voucher.list.status.redeemed" })}
                  </S.Redeemed>
                ) : (
                  <S.Completed>
                    {intl.formatMessage({
                      id: "voucher.list.status.completed"
                    })}
                  </S.Completed>
                )}
              </S.Data>
            </S.Preview>
          }
          isOpen={expanded === voucher.code}
          onClick={() => toggleExpanded(voucher.code)}
        >
          {expanded === voucher.code && (
            <S.Orders>
              <S.Headers>
                <S.HeaderItem>
                  {intl.formatMessage({ id: "voucher.order.number" })}
                </S.HeaderItem>
                <S.HeaderItem>
                  {intl.formatMessage({ id: "voucher.order.credit" })}
                </S.HeaderItem>
                <S.HeaderItem>
                  {intl.formatMessage({ id: "voucher.order.date" })}
                </S.HeaderItem>
                <S.HeaderItem>
                  {intl.formatMessage({ id: "voucher.order.status" })}
                </S.HeaderItem>
              </S.Headers>

              {voucher.orders.map(order => (
                <S.Order key={order.order_id}>
                  <S.Data>{order.order_id}</S.Data>
                  <S.Data>
                    {formatAsCurrency(order.order_discount, voucher.currency)}
                  </S.Data>
                  <S.Data>{formatDate(order.billing_period_start)}</S.Data>
                  <S.Data>
                    {order.status === "completed" ? (
                      <S.Completed>
                        {intl.formatMessage({
                          id: "voucher.list.status.completed"
                        })}
                      </S.Completed>
                    ) : (
                      <S.Redeemed>
                        {intl.formatMessage({
                          id: "voucher.list.status.redeemed"
                        })}
                      </S.Redeemed>
                    )}
                  </S.Data>
                </S.Order>
              ))}
            </S.Orders>
          )}
        </SettingLine>
      ))}
    </S.Wrapper>
  );
}

VouchersList.propTypes = {
  vouchers: PropTypes.arrayOf(PropTypes.object)
};

export default VouchersList;
