import { fromJS, Map } from "immutable";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import { MapStoreStateType, StoreMapStateType } from "Reducers/types";

import type Region from "platformsh-client/types/model/Region";
import { SetTokenAction, RegionAction, SelectorParams } from "./types";

import { request } from "Libs/platform";
import config from "../../../constants/api_config";

export const getRegions = createAsyncThunk("app/region", async () => {
  const regionsEndpoint = `${config.api_url}/v1/regions`;
  const result = await request(regionsEndpoint, "GET");

  return {
    regions: result?.regions
  };
});

const setError = (state: StoreMapStateType, action: SetTokenAction) =>
  state.set("errors", action.error.message).set("loading", false);

const region = createSlice({
  name: "app/region",
  initialState: Map({
    data: Map(),
    loading: "idle"
  }) as StoreMapStateType,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRegions.pending, (state: StoreMapStateType) =>
        state.set("loading", true)
      )
      .addCase(
        getRegions.fulfilled,
        (state: StoreMapStateType, action: RegionAction) => {
          const regions = action.payload.regions;

          return state
            .set(
              "data",
              fromJS(
                regions.reduce(
                  (acc: { [key: string]: Region }, region: Region) => {
                    acc[region.id] = region;
                    return acc;
                  },
                  {}
                )
              )
            )
            .set(
              "byZone",
              fromJS(
                regions
                  .filter(
                    (region: Region) => region.available == true // Double equals because some available keys are strings, and some aren't.
                  )
                  .reduce(
                    (
                      accumulator: { [key: string]: Region[] },
                      current: Region
                    ) => {
                      if (!accumulator[current.zone]) {
                        accumulator[current.zone] = [current];
                        return accumulator;
                      }

                      accumulator[current.zone].push(current);
                      return accumulator;
                    },
                    {}
                  )
              )
            )
            .delete("errors")
            .set("loading", false);
        }
      )
      .addCase(
        getRegions.rejected,
        (state: StoreMapStateType, action: SetTokenAction) =>
          setError(state, action)
      );
  }
});

export default region.reducer;

const selectSelf = (state: MapStoreStateType) => {
  return state.region;
};
const getParams = (_: unknown, params: SelectorParams) => params;

export const regionsByZoneSelector = createSelector(
  selectSelf,
  getParams,
  region => region?.get("byZone", Map())
);

export const regionSelector = createSelector(
  selectSelf,
  getParams,
  (region, { regionId }) => region?.getIn(["data", regionId])
);
