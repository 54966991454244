import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Map } from "immutable";

import { hasSafeRole, getTrial, interpolateURL } from "Libs/utils";

import {
  deleteSubscription,
  loadSubscription,
  subscriptionSelector,
  loadingSelector
} from "Reducers/organization/subscription";
import { withReducers } from "Hocs";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import ErrorBoundary from "Components/ErrorBoundary";
import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import ListGroup from "Components/ListGroup";
import { BackLink, Link } from "ds/Button";
import Sticker from "ds/Stickers";

import ProjectPlanForm from "./containers/ProjectPlanForm";
import * as S from "./styles";
import { organizationProfileSelector } from "Reducers/organization/profile";
import DeleteSubscriptionModal from "./components/deleteModal/DeleteSubscriptionModal";

const PlanDetail = () => {
  const { organizationId: organizationNameId, subscriptionId } = useParams();
  const [hasPaymentSource, setPaymentSource] = useState();
  const [errors, setErrors] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const intl = useIntl();
  const dispatch = useDispatch();

  const user = useSelector(state => state.app?.get("me", new Map())?.toJS());
  const organizations = useSelector(state =>
    state.organization?.get("data", new Map())
  );

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationNameId
    })
  );
  const orgId = organization?.id;

  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId: organizationNameId,
      subscriptionId
    });
  });
  const { project_id: projectId, organization_id } = subscription;

  const organizationProfile = useSelector(state =>
    organizationProfileSelector(state, { organizationId: organizationNameId })
  );

  const { currency } = organizationProfile?.data || "";

  useEffect(() => {
    let isCanceled = false;
    const getPaymentSource = async () => {
      if (hasPaymentSource !== undefined) return;
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const ps = await client.getOrganizationPaymentSource(orgId);
      if (isCanceled) {
        return;
      }
      setPaymentSource(!!ps.type);
    };
    if (!hasSafeRole(user.roles)) getPaymentSource();

    return () => (isCanceled = true);
  }, []);

  useEffect(() => {
    if (subscriptionId && organization)
      dispatch(
        loadSubscription({
          organizationId: organizationNameId,
          id: subscriptionId
        })
      );
  }, [subscriptionId, organization]);

  const showBanner = (() => {
    if (hasSafeRole(user.roles)) return false;
    if (
      !getTrial(user, { organization_id }, organizations.toJS()) &&
      !hasPaymentSource
    )
      return true;

    const { current_trial } = user.data;
    if (
      current_trial?.active &&
      moment(current_trial?.expiration).isSameOrBefore(moment())
    )
      return true;

    return false;
  })();

  const isOnTrial = useMemo(() => {
    if (hasSafeRole(user.roles)) return false;
    return (
      getTrial(user, { organization_id }, organizations?.toJS()) &&
      !hasPaymentSource
    );
  }, [organization_id, organizations]);

  const canEdit = subscription?.hasLink && subscription.hasLink("update");
  const isLoadingSubscription = useSelector(loadingSelector, () => false);

  return (
    <ContentLayout className="settings-content">
      <ErrorBoundary>
        <S.HeaderWrapper>
          <BackLink to={`/${organizationNameId}/-/billing/plan`} from="plans" />

          <S.HeaderContent hasBackButton>
            <Heading2 id="plan-heading">{subscription.project_title}</Heading2>
          </S.HeaderContent>
        </S.HeaderWrapper>

        <S.StickerLayout>
          {showBanner && (
            <Sticker
              variant="button"
              priority="normal"
              className="sticker-message"
              button={
                <Link variant="primary" to={`/${organizationNameId}/-/billing`}>
                  {intl.formatMessage({
                    id: "settings.plan.banner.button",
                    defaultMessage: "Add billing details"
                  })}
                </Link>
              }
              text={intl.formatMessage({
                id: "settings.plan.banner.trial",
                defaultMessage:
                  "To upgrade your plan you need to add a payment method to your billing details."
              })}
            />
          )}
          {errors && (
            <Sticker
              className="sticker-message"
              priority="critical"
              text={errors}
            />
          )}
        </S.StickerLayout>

        <ListGroup>
          {subscription && (
            <ProjectPlanForm
              subscription={subscription}
              organizationNameId={organizationNameId || ""}
              organizationProfile={organizationProfile}
              isLoadingSubscription={
                isLoadingSubscription == "idle" ? false : isLoadingSubscription
              }
              setErrors={setErrors}
              isOnTrial={isOnTrial}
              currency={currency}
            />
          )}
        </ListGroup>

        {canEdit && (
          <S.ButtonWrapper>
            <S.DeleteButton
              onClick={() => {
                if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
                  window.location.href = interpolateURL(
                    process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
                    { projectId }
                  );
                } else {
                  setOpenDeleteModal(true);
                }
              }}
              variant="outline"
            >
              {intl.formatMessage({
                id: "settings.plan.project.delete.button",
                defaultMessage: "Delete project"
              })}
            </S.DeleteButton>
          </S.ButtonWrapper>
        )}

        <DeleteSubscriptionModal
          isOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          subscription={subscription}
          onDelete={() => dispatch(deleteSubscription({ subscription }))}
        />
      </ErrorBoundary>
    </ContentLayout>
  );
};

export default withReducers({
  project: () => import("Reducers/project"),
  organizationSubscription: () => import("Reducers/organization/subscription")
})(PlanDetail);
