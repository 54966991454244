import { createGlobalStyle } from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import {
  css,
  SECTION,
  getCSSVarString,
  LINK,
  MODAL,
  TOOLTIP
} from "Libs/themes";

import darkTheme from "./themes/dark";
import contrastTheme from "./themes/contrast";

export const themeSetup = `
  :root {
    --light:#86B5FF;
    --grey: #f0f2f5;
    --dark: #232235;
    --dark-shade: #1a1a2b;
    --dark-tint: #4a4a5d;
    --snow: #ffffff;
    --ebony-light: #4a495e;
    --black: #01001A;
    --ice: #f5f7fa;
    --stone: #e7e7e7;
    --periwinkle-grey: #c9d0e4;
    --granite: #98a0ab;
    --slate: #5f5e70;
    --ebony: #302f45;
    --night: #1a192b;
    --skye-light: #e7f0ff;
    --lighter-sky: #5792FF;
    --skye: #4786ff;
    --skye-dark: #1664ff;
    --gold: #eda900;
    --java: #23b7b7;
    --fire: #ff8359;
    --red: #fb3728;
    --ocean: #4c62a3;
    --purple: #9d67ff;
    --wine: #6d144f;
    --pink: #ffbdbb;
    --light-pink: #ffeeee;
    --dark-pink: #ff9f9f;
    --hot-pink: #e04aa5;
    --beige: #f6f6f1;
    --aqua: #7bd8db;
    --candy-green: #289b3f;
    --wacg-red: #e50000;
    --lemon-chiffon: #fff9c4;
    --elf: #188181;
    --romantic: #ffccbc;
    --blizzard-blue: #b2ebf2;
    --orange: #ff5d25;
    --blue-chalk: #efe7ff;
    --old-gold: #9a6f1e;
    --ebony-shadow: rgba(75, 97, 128, 0.32);
    --deep-blue: #0A5CFF;
    --blue: #145CC6;
    --light-blue: #86B5FF;
    --granite-shadow: rgba(152, 160, 171, 0.4);
    --cosmic-latte: #D6F6EF;
    --misty-rose: #FCE1E1;
    --peach-breeze: #FFEBE4;
    --tulip-pedals: #FCF4DB;
    --dark-shade-overlay: #1a1a2be5;
    --light-blue-hover: #86B5FF4C;
    --grey-light: #404041;


  --transparent: transparent;

    --button-box-shadow: 0 0 2px;
    --input-box-shadow: 0 0 2px;
    --menu-box-shadow: 0 6px 24px;

    --skeleton-gradien: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.96%, rgba(255, 255, 255, 0) 100%);

    --box-shadow: 0 6px 24px;
    --drop-shadow: 0 4px 8px;
    --drop-shadow-color: var(--granite-shadow);
    --tooltip-shadow: var(--drop-shadow);
    --tooltip-drop-shadow-color: var(--drop-shadow-color);
    --modal-shadow: 0 6px 24px;
    --modal-box-shadow: var(--modal-shadow);
    --modal-box-shadow-color: var(--drop-shadow-color);
  }

  [data-theme='dark'] {
    ${darkTheme}
  }

  [data-theme='contrast'] {
    ${contrastTheme}
  }

  ${process.env.VENDOR_THEME?.cssThemes}
`;

export default createGlobalStyle`
  ${themeSetup};
  html, body, #app {

    margin: 0;
    font-family: 'Open Sans', Helvetica,Arial,sans-serif;
    font-size: 16px;
    background-color:var(--section-grey-background-color,var(--section-grey,var(--grey)));
    color:var(--section-night-color,var(--section-night,var(--night)));
    height: 100%;
  }

  #app {
    font-family: 'Open Sans', Helvetica,Arial,sans-serif;
    min-width: 414px;
  }

  #main {
    box-sizing: border-box;
  }

  #user-widget {
    z-index: 9;
  }

  #home-navigation:first-letter {
    text-transform: uppercase;
  }

  h1, h5, h6 {
    font-weight: 400;
  }
  h2, h3 {
    font-weight: 700;
  }
  h4 {
    ${semiBoldAlias};
  }

  pre,
  code {
    font-family: "Overpass Mono", monospace;
    font-size: 12px;
  }
  code {
    color:var(--section-skye-dark-color,var(--section-skye-dark,var(--skye-dark)));
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
    line-height: 20px;
    height: 20px;
    padding: 0 8px;
    border-radius: 2px;
    display: inline-block;
    margin: 0 4px;
    height: 100%;
  }
  pre {
    background-color:var(--section-dark-background-color,var(--section-dark,var(--dark)));
    color:var(--section-snow-color,var(--section-snow,var(--snow)));
    border-radius: 2px;
    code {
      background-color:var(--section-ebony-background-color,var(--section-ebony,var(--ebony)));
      color:var(--section-snow-color,var(--section-snow,var(--snow)));
    }
  }
  label {
    font-size: 13px;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
    ${semiBoldAlias};
  }

  a {
    font-weight: 400;
    font-size: 14px;
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
    &.text-link {
      font-weight: 400;
      font-size: 14px;
      color:var(--link-skye-color,var(--link-skye,var(--skye)));
      margin: 0 -8px;
      padding: 0 8px;
      cursor: pointer;
      position: relative;
      &:hover {
        &:after {
          content: "";
          width: calc(100% - 16px);
          position: absolute;
          height: 1px;
          background-color:var(--link-skye-background-color,var(--link-skye,var(--skye)));
          bottom: 2px;
          left: 8px;
          display: inline-block;
        }
      }
      &:focus {
        outline: none;
        border: 1px solid var(--link-skye-dark-border-color,var(--link-skye-dark,var(--skye-dark)));
        border-radius: 4px;
        box-shadow: 0 0 2px  ${getCSSVarString(
          LINK,
          "skye-dark",
          "box-shadow-color"
        )};
        text-decoration: none;
        margin: -1px -9px;
      }
      &:active {
        border: none;
        box-shadow: none;
        text-decoration: underline;
        margin: 0 -8px;
      }
    }
  }
  hr {
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
    box-shadow: none;
    height: 1px;
    border: none;
    margin: 16px 0;
    &.full-width {
      margin: 16px -32px;
    }
  }
  /* Icon Font */
  @font-face {
    font-family: 'Platform';
    src:url('./webfonts/platform.eot');
    src:url('./webfonts/platform.eot?#iefix') format('embedded-opentype');
  }
  @font-face {
    font-family: 'Platform';
    src: url('./webfonts/platform.woff') format('woff'),
    url('./webfonts/platform.ttf') format('truetype'),
    url('./webfonts/platform.svg#wf') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  .platform {
    font-family: 'Platform';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }

  /* Animations */

  @keyframes slideOutEnvironementDrawerLeft {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes slideInEnvironementDrawerLeft {
    from {
      transform: translate3d(-100%, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .slideOutLeftAnimation {
    animation-name: slideOutEnvironementDrawerLeft;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .slideInLeftAnimation {
    animation-name: slideInEnvironementDrawerLeft;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes slideOutEnvironementDrawerRight {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(100%, 0, 0);
    }
  }

  @keyframes slideInEnvironementDrawerRight {
    from {
      transform: translate3d(100%, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .slideOutRightAnimation {
    animation-name: slideOutEnvironementDrawerRight;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .slideInRightAnimation {
    animation-name: slideInEnvironementDrawerRight;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .modal {
    position: absolute;
    top: 80px;
    width: 90%;
    margin: 0 5%;
    z-index: 100;
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    overflow: auto;
    outline: none;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(37,36,40,0.30);
    box-sizing: border-box;
    .modal-title-bar {
      margin-bottom: 16px;
    }
    .modal-title-bar.fixed {
      width: 70%;
    }
    #modal-body {
      font-size: 15px;
      line-height: 24px;
    }
    .title-wrapper {
      padding-top: 16px;
    }
    .modal-body {
      margin-bottom: 0;
    }

    @media(min-width: 700px) {
      width: 70%;
      margin: 0 15%;
    }
    &.modal-small {
      top: 80px;
      width: 480px;
      left: 50%;
      margin: 0 0 0 -240px;
    }
    &.modal-medium {
      top: 80px;
      @media(min-width: 768px) {
        width: 600px;
        left: 50%;
        margin: 0 0 0 -300px;
      }
    }
    &.modal-large {
      top: 80px;
      max-width: 80%;
      margin: 0 10%;
      @media(min-width: 960px) {
        width: 900px;
        left: 50%;
        margin: 0 0 0 -450px;
      }
    }
    &.modal-fullpage {
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      margin: 0;
      padding: 16px;
      max-height: 100vh;
      border-radius: 0;

      @media(min-width: 960px) {
        padding: 32px;
        top: 32px;
        bottom: 32px;
        left: 32px;
        right: 32px;
        max-width: calc(100vw - 64px);
        max-height: calc(100vh - 64px);
        border-radius: 4px;
      }

      #modal-body {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
    &.modal-plan-pricing {
      .modal-title-bar {
        margin-bottom: 24px;
      }
      .title-wrapper {
        padding-top: 16px;
      }
      .modal-body {
        margin-bottom: 0;
      }
    }
    &.modal-environment-action {
      .field {
        margin-bottom: 24px;
      }
      .toggle-label {
        text-transform: uppercase;
      }
      @media (min-width: 1000px) {
        width: 640px;
        left: 50%;
        margin-left: -325px;
      }
    }
    &.not-found {
      padding: 50px;
      max-width: 650px;
      border-radius: 4px;
      box-shadow: 0 4px 8px rgba(37, 36, 40, 0.3);
      font-size: 18px;
      .actions {
        padding-top: 30px;
      }
      @media (min-width: 1000px) {
        width: 640px;
        left: 50%;
        margin-left: -325px;
      }
    }
    &.modal-header-styles {
      padding: 0;
      .modal-title-bar {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        &.fixed {
          margin-left: 0;
          margin-top: 0;
        }
        h3 {
          margin: 0;
          text-align: left;
          .title {
            display: inline-block;
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
      .modal-content-wrapper {
        padding: 40px;
        h2 {
          text-align: center;
        }
        .modal-body {
          padding: 0;
        }
      }
    }
    &.modal-environment-sync {
      .checkbox-field {
        margin-bottom: 14px;
        label {
          margin-bottom: 0;
        }
        &:last-child {
          margin-bottom: 32px;
        }
      }
    }
    &.modal-plan-pricing {
      width: 700px;
    }
    &.modal-add-template {
      background:${getCSSVarString(
        MODAL,
        "snow",
        "background-color"
      )} !important;
    }

 &.modal-announcements {
      padding: 0;
      max-width: 600px;
      top: 68px;
      left: 0;
      right: 0;
      margin-left: 8px;
      margin-right: 8px;
      overflow: visible;
      border: 0;
      position: relative;
      width: auto;
      background: ${getCSSVarString(
        MODAL,
        "snow",
        "background-color"
      )} !important;

      @media screen and (min-width: 600px) {
        top: 99px;
        margin-right: auto;
        margin-left: auto;
        height: 528px;
      }
    }
    footer {
      margin-top: 40px;
      text-align: right;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:var(--modal-dark-shade-overlay-background-color,var(--modal-dark-shade-overlay,var(--dark-shade-overlay)));
    z-index: 99;

    &.overlay-announcements {
      background-color: rgba(48, 47, 69, 0.8);
    }
  }

  img {
    max-width: 100%;
  }
  span[data-tip="true"] {
    position: relative;
  }
  .type-dark {
    background: ${getCSSVarString(
      TOOLTIP,
      "ebony",
      "background-color"
    )} !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', Helvetica,Arial,sans-serif !important;
    padding: 8px 16px !important;
    line-height: 18px;
    border-radius: 2px !important;
    opacity: 1 !important;
    max-width: 480px;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${semiBoldAlias};
    &:after {
      border-width: 8px !important;
    }
    &.place-bottom:after {
      border-bottom-color: ${getCSSVarString(
        TOOLTIP,
        "ebony",
        "border-color"
      )} !important;
    }
    &.place-top:after {
      border-top-color: ${getCSSVarString(
        TOOLTIP,
        "ebony",
        "border-color"
      )} !important;
    }
    &.place-left {
      position: absolute;
      top: 100% !important;
      left: auto !important;
      right: 0;
      margin-top: 8px !important;
      &:after {
        top: -8px !important;
        right: 16px !important;
        border-left-color: ${getCSSVarString(
          TOOLTIP,
          "ebony",
          "border-color"
        )} !important;
        transform: rotate(-90deg);
      }
    }
    &.place-right {
      position: absolute;
      top: 100% !important;
      left: 0 !important;
      margin-top: 8px !important;
      margin-left: 0 !important;
      &:after {
        top: -8px !important;
        left: 16px !important;
        border-right-color: ${getCSSVarString(
          TOOLTIP,
          "ebony",
          "border-color"
        )} !important;
        transform: rotate(90deg);
      }
    }
  }

  .setup-modal {
    height: calc(100vh - 40px);
  }

  .setup-modal main {
    min-height: 0px !important;
  }

  .setup-modal .modal-content-wrapper {
    height: 100%;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .pb-0{
    padding-bottom:0;
  }
`;
