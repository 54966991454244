import { fromJS, Map } from "immutable";

import { push } from "Reducers/app";
import logger from "Libs/logger";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MapStoreStateType } from "Reducers/types";

type BranchEnvironmentActionPropType = {
  organizationId: string;
  projectId: string;
  environmentId: string;
  options: {
    branchName: string;
    type: string;
  };
};

export const branch = createAsyncThunk<any, BranchEnvironmentActionPropType>(
  "Environment/Branch/Load",
  async (
    { options, organizationId, environmentId, projectId },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const environment = (getState() as MapStoreStateType).environment.getIn(
        ["data", organizationId, projectId, environmentId],
        {}
      );

      const branch = await environment.branch(options.branchName, options.type);

      dispatch(
        push({
          path: "/:organizationDescriptionId/:projectDescriptionId/:branchName",
          args: {
            organizationDescriptionId: organizationId,
            projectDescriptionId: projectId,
            branchName: branch.parameters.environment
          }
        })
      );
      return branch;
    } catch (err) {
      logger(err as Error, {
        action: "branch",
        payload: branch,
        options,
        meta: {
          organizationId,
          environmentId,
          projectId
        }
      });
      return rejectWithValue(err);
    }
  }
);

const environmentBranchSlice = createSlice({
  name: "branch",
  initialState: Map(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(branch.pending, state =>
        state.set("loading", true).remove("errors")
      )
      .addCase(branch.fulfilled, (state, { meta, payload }) =>
        state
          .set("loading", false)
          .remove("errors")
          .setIn(
            [
              "data",
              meta.arg.organizationId,
              meta.arg.projectId,
              meta.arg.environmentId
            ],
            fromJS(payload)
          )
      )
      .addCase(branch.rejected, (state, { payload }) =>
        state.set("loading", false).set("errors", fromJS(payload))
      );
  }
});

export default environmentBranchSlice.reducer;
