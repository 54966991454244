import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import {
  toggleEnvironmentsPanel,
  selectEnvironmentsPanelExpanded
} from "Reducers/settingsMenu";

import ChevronIcon from "Components/icons/ChevronIcon";
import { MenuChildrenContainer, MenuHeader, Submenu } from "./Menu.styles";
import EnvironmentTree from "./EnvironmentTree";

const EnvironmentSettingsMenu = () => {
  const expanded = useSelector(selectEnvironmentsPanelExpanded);
  const dispatch = useDispatch();
  const intl = useIntl();
  return (
    <MenuChildrenContainer expanded={expanded}>
      <MenuHeader
        onClick={() => {
          dispatch(toggleEnvironmentsPanel());
        }}
      >
        <h1>
          {intl.formatMessage({ id: "settings.menu.environment_settings" })}
        </h1>
        <ChevronIcon color="currentColor" isOpen={expanded} animate={true} />
      </MenuHeader>
      {expanded && (
        <Submenu>
          <EnvironmentTree />
        </Submenu>
      )}
    </MenuChildrenContainer>
  );
};

export default EnvironmentSettingsMenu;
