import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import { capitalize } from "Libs/utils";
import ModalWrapper from "Components/Modal";
import ModalBody from "Components/ModalBody";
import Heading3 from "Components/styleguide/Heading3";
import { Button, ButtonWrapper } from "ds/Button";
import {
  ModalChangeMethodWrapper,
  SmallNote
} from "./ModalChangeMethod.styles";

const ModalChangeMethod = ({ cancel, confirm, isOpen, intl }) => (
  <ModalWrapper modalClass={`modal-small`} isOpen={isOpen} closeModal={cancel}>
    <ModalChangeMethodWrapper>
      <ModalBody>
        <Heading3>
          {intl.formatMessage({ id: "change_payment_method_header" })}
        </Heading3>
        <p>{intl.formatMessage({ id: "change_payment_method_body" })}</p>
        <SmallNote>
          {intl.formatMessage({ id: "change_payment_method_note" })}
        </SmallNote>
        <ButtonWrapper spacing="modal" justifyContent="end">
          <Button
            variant="secondary"
            id="change-method-modal-cancel"
            onClick={cancel}
          >
            {capitalize(intl.formatMessage({ id: "cancel" }))}
          </Button>
          <Button
            id="change-method-modal-confirm"
            type="submit"
            onClick={confirm}
          >
            {capitalize(
              intl.formatMessage({ id: "change_payment_method_confirm" })
            )}
          </Button>
        </ButtonWrapper>
      </ModalBody>
    </ModalChangeMethodWrapper>
  </ModalWrapper>
);

ModalChangeMethod.propTypes = {
  isOpen: PropTypes.bool,
  cancel: PropTypes.func,
  confirm: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(ModalChangeMethod);
