import styled, { css as styledCSS } from "styled-components";

import { Button as UnstyledButton, ButtonWrapper as Buttons } from "ds/Button";

export const Wrapper = styled.div`
  display: inline-block;
`;

export const Content = styled.div`
  display: inline-block;
  width: 264px;
  margin: 0 auto;
  text-align: left;
`;

export const Button = styled(UnstyledButton)`
  width: 264px;
  margin: 0 auto;
`;

export const ButtonWrapper = styled(Buttons)`
  margin-top: 40px;
  width: 100%;

  ${({ hasCancel }) =>
    hasCancel &&
    styledCSS`
    ${Button} {
      width: auto;
      margin: 0;
    }
  `}
`;
