import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { withReducers } from "Hocs";
import useDecodedParams from "Hooks/useDecodedParams";
import useInput from "Hooks/useInput";

import { organizationByDescriptionIdSelector } from "Reducers/organization";
import {
  addVoucher,
  getVouchers,
  initForm,
  voucherErrorsSelector,
  voucherLoadingSelector,
  voucherStatusSelector
} from "Reducers/voucher";

import { Button } from "ds/Button";
import InputField from "Components/fields/InputField";

import * as S from "./Form.styles";

const Form = ({ cancelable = true, reloadList = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { organizationId } = useDecodedParams();

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const loading = useSelector(state =>
    voucherLoadingSelector(state, { organizationId })
  );
  const errors = useSelector(state =>
    voucherErrorsSelector(state, { organizationId })
  );
  const status = useSelector(state =>
    voucherStatusSelector(state, { organizationId })
  );

  const { inputs, handleInputChange } = useInput({
    code: ""
  });

  useEffect(() => {
    dispatch(initForm({ organizationId }));
  }, []);

  useEffect(() => {
    if (status === "added") {
      if (reloadList) {
        dispatch(getVouchers({ organization }));
      } else {
        push(`/${organizationId}/-/billing/vouchers`);
      }
    }
  }, [organization, status]);

  const handleSave = e => {
    e?.preventDefault();
    if (!inputs.code && !organization) return;
    dispatch(addVoucher({ organization, code: inputs.code }));
  };

  return (
    <S.Wrapper>
      <S.Content>
        <InputField
          autofocus
          id="code"
          label={intl.formatMessage({ id: "voucher.form.code" })}
          onChange={elt => handleInputChange(elt)}
          required={true}
          error={errors}
          value={inputs.code}
        />

        <S.ButtonWrapper hasCancel={cancelable}>
          <S.Button
            onClick={handleSave}
            disabled={loading || inputs?.code.length < 3}
          >
            {intl.formatMessage({ id: "voucher.form.submit" })}
          </S.Button>
          {cancelable && (
            <Button
              variant="secondary"
              to={`/${organizationId}/-/billing/vouchers`}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
          )}
        </S.ButtonWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

Form.propTypes = {
  cancelable: PropTypes.bool,
  reloadList: PropTypes.bool
};

export default withReducers({
  voucher: () => import("Reducers/voucher")
})(Form);
