import styled, { css as styledCSS } from "styled-components";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import * as LabelPrimitive from "@radix-ui/react-label";

import { css, SECTION, INPUT, getCSSVarString } from "Libs/themes";

export const Group = styled(RadioGroupPrimitive.Root)`
  display: flex;
  flex-direction: column;
`;

export const Indicator = styled(RadioGroupPrimitive.Indicator)<{
  isvalid?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
 
  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color:var(--input-skye-background-color,var(--input-skye,var(--skye)));
    ${({ isvalid }) =>
      !isvalid &&
      styledCSS`
    background-color:var(--section-red-background-color,var(--section-red,var(--red)));

    `}
`;

export const Item = styled(RadioGroupPrimitive.Item)<{ isvalid?: boolean }>`
  all: unset;
  background-color:var(--input-snow-background-color,var(--input-snow,var(--snow)));
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid;
  border-color:var(--input-granite-border-color,var(--input-granite,var(--granite)));

  &[data-state="checked"] {
    border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
  }
  :disabled {
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  ${({ isvalid }) =>
    isvalid &&
    styledCSS`
    &:focus-visible {
    border-color:var(--input-skye-dark-border-color,var(--input-skye-dark,var(--skye-dark)));
    box-shadow: 0px 0px 2px
        var(--input-skye-dark-box-shadow-color,var(--input-skye-dark,var(--skye-dark))),
      inset 0 0 2px var(--input-skye-dark-box-shadow-color,var(--input-skye-dark,var(--skye-dark)));
  }
  
`};

  ${({ isvalid }) =>
    !isvalid &&
    styledCSS`
    border-color:var(--input-red-border-color,var(--input-red,var(--red)));

    &[data-state="checked"] {
      border-color:var(--input-red-border-color,var(--input-red,var(--red)));
    }
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`;

interface LabelProps {
  disabled?: boolean;
}

export const Label = styled(LabelPrimitive.Root)<LabelProps>`
  margin-left: 12px;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    styledCSS`
      cursor: not-allowed;
      color:var(--input-granite-color,var(--input-granite,var(--granite)));
    `};
`;
