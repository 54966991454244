import React from "react";
import PropTypes from "prop-types";

import EnvironmentError from "./common/containers/EnvironmentError";
import { withProjectRedirect } from "Hocs";

const Environment = ({ children }) => {
  return <EnvironmentError>{children}</EnvironmentError>;
};

Environment.propTypes = {
  children: PropTypes.node.isRequired
};

export default withProjectRedirect(Environment);
