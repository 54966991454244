import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Map } from "immutable";

import { withReducers } from "Hocs";

import {
  addAccess as addEnvAccess,
  deleteAccess as deleteEnvAccess,
  getAccesses as getEnvAccesses,
  updateAccess as updateEnvAccess
} from "Reducers/environment/access";

import AccessForm from "../../../../../../common/components/AccessForm";

const EnvironmentAccessListFields = ({
  access,
  createInvitation,
  enabled,
  environments,
  projectErrors,
  onCancel,
  onDelete,
  updateProjectAccess
}) => {
  const dispatch = useDispatch();
  const { organizationId, projectId } = useParams();

  const [accessesAreLoading, setAccessesAreLoading] = useState(false);

  const editedLine = useSelector(state => state.projectAccess?.get("edited"));

  const invitations = useSelector(state =>
    state.invitation?.getIn(["data", organizationId, projectId], new Map())
  );
  const invitationError = useSelector(state =>
    state.invitation?.getIn(
      ["errors", organizationId, projectId, editedLine],
      {}
    )
  );

  const envAccesses = useSelector(state => {
    return state.environmentAccess?.getIn(
      ["data", organizationId, projectId],
      new Map()
    );
  });
  const errors = useSelector(state =>
    state.environmentAccess?.get("errors", {})
  );
  const isLoading = useSelector(state =>
    state.environmentAccess?.get("loading")
  );

  useEffect(() => {
    environments.forEach(environment =>
      dispatch(getEnvAccesses({ environment, organizationId, projectId }))
    );
  }, [environments?.size]);

  useEffect(() => {
    if (accessesAreLoading) return;
    const accessesKeys = envAccesses.keySeq().toArray();
    const fullLoaded = !environments
      .keySeq()
      .toArray()
      .some(elt => !accessesKeys.includes(elt));
    if (fullLoaded) setAccessesAreLoading(true);
  }, [envAccesses]);

  const handleSave = data => {
    const role = data.superUser ? "admin" : "viewer";

    if (access) {
      // update project access
      if (access.role !== role) updateProjectAccess(access, role);

      // don't update env  if user is admin
      if (role === "admin") return;

      // update access for each environments
      data.accesses.forEach(acc => {
        if (acc.id) {
          const envAccess = envAccesses.getIn([acc.environmentId, acc.id]);
          // don't call api if role hasn't changed
          if (envAccess.role === acc.role) return;
          if (acc.role === "no_access") {
            dispatch(deleteEnvAccess({ access: envAccess }));
          } else {
            dispatch(
              updateEnvAccess({
                organizationId,
                projectId,
                environmentId: acc.environmentId,
                access: envAccess,
                role: acc.role
              })
            );
          }
        } else {
          const env = environments.get(acc.environmentId);
          createEnvAccess(data.email, acc.role, env);
        }
      });
    } else {
      const accesses = data.accesses.reduce((acc, cu) => {
        if (cu.role && ["admin", "viewer", "contributor"].includes(cu.role)) {
          acc.push({ id: cu.environmentId, role: cu.role });
        }
        return acc;
      }, []);
      createInvitation(data.email, role, accesses);
    }
  };

  const createEnvAccess = (email, role, environment) => {
    if (!environment || environment.status === "inactive") return;
    dispatch(
      addEnvAccess({
        data: { email, role },
        environment,
        organizationId,
        projectId
      })
    );
  };

  return (
    <AccessForm
      key={`${access ? access.user : "new"}-access-edit`}
      access={access}
      environmentAccesses={envAccesses}
      accessesAreLoading={accessesAreLoading}
      enabled={enabled}
      environments={environments}
      isLoading={isLoading === true}
      onCancel={onCancel}
      onDelete={onDelete}
      onSave={handleSave}
      errors={
        (errors || projectErrors || invitationError) && {
          ...errors,
          ...projectErrors,
          ...invitationError
        }
      }
      invitation={invitations.get(editedLine, false)}
    />
  );
};

EnvironmentAccessListFields.propTypes = {
  access: PropTypes.object,
  createInvitation: PropTypes.func,
  enabled: PropTypes.bool,
  environments: PropTypes.object,
  projectErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  updateProjectAccess: PropTypes.func,
  updateProjectAccesses: PropTypes.func
};

export default withReducers({
  environmentAccess: () => import("Reducers/environment/access"),
  invitation: () => import("Reducers/invitation"),
  projectAccess: () => import("Reducers/project/access")
})(EnvironmentAccessListFields);
