import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Map } from "immutable";
import moment from "moment";

import LargeInlineError from "Components/LargeInlineError";

import TicketInfo from "../../components/TicketInfo";
import Description from "../../components/Description";
import AddComment from "../../components/AddComment";
import { BackLink, Button, Link } from "ds/Button";
import Status from "../../../../common/components/Status";

import useUserIsSupport from "../../../../common/useUserIsSupport";

import Comments from "../Comments";
import AllAttachments from "../AllAttachments";

import { loadSubscription, subscriptionSelector } from "Reducers/subscription";
import { loadSubscription as loadOrganizationSubscription } from "Reducers/organization/subscription";
import {
  load as loadTicket,
  updateTicketStatus
} from "Reducers/tickets/ticket";
import { send as sendComment } from "Reducers/tickets/comments";
import * as S from "./TicketView.style";

import useEnvironments from "Hooks/useEnvironments";
import {
  getOrganizationDescriptionIdFromProject,
  getOwnerInfoName,
  getRegionLabel
} from "Libs/utils";
import transform from "../../../../common/toAttachmentEndpointFormat";
import Sticker from "ds/Stickers";

const TicketView = () => {
  const { ticketId } = useParams();
  const dispatch = useDispatch();

  const me = useSelector(({ app }) => app?.get("me"));
  const ticket = useSelector(({ view }) => view.ticket?.get("content"));
  const error = useSelector(({ view }) => view.ticket?.get("error"));
  const isLoadingTicket = useSelector(({ view }) =>
    view.ticket?.get("isLoading")
  );
  const project = useSelector(state =>
    state.app
      .getIn(["me", "projects"])
      .toJS()
      .find(project => project.subscription_id == ticket?.subscription_id)
  );
  const organizations = useSelector(({ organization }) =>
    organization?.get("data", Map())
  )?.toJS();

  const organizationId = getOrganizationDescriptionIdFromProject(
    project,
    organizations
  );

  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId: project?.id,
      id: project?.subscription_id
    });
  });

  const region = getRegionLabel(subscription?.project_region_label);

  const currentUserId = useSelector(({ app }) => app?.getIn(["me", "id"]));
  const currentUserUsername = useSelector(({ app }) =>
    app?.getIn(["me", "id"])
  );
  const currentUserIsSupport = useUserIsSupport(currentUserId);

  useEffect(() => {
    dispatch(loadTicket(ticketId));
  }, []);

  useEffect(() => {
    if (
      !process.env.ENABLE_ORGANIZATION &&
      project?.subscription_id &&
      me?.get("id") === project?.owner
    ) {
      setTimeout(() =>
        dispatch(
          loadSubscription({
            organizationId,
            projectId: project.id,
            id: project.subscription_id
          })
        )
      );
    } else if (process.env.ENABLE_ORGANIZATION) {
      dispatch(
        loadOrganizationSubscription({
          organizationId,
          id: project?.subscription_id
        })
      );
    }
  }, [
    project?.subscription_id,
    getOwnerInfoName(project, organizations),
    me?.get("id")
  ]);

  const onSubmit = data => {
    transform(data.attachments).then(attachments =>
      dispatch(
        sendComment({
          ...data,
          attachments,
          ticket_id: ticket.ticket_id,
          author_id: currentUserId
        })
      )
    );
  };

  const environments = useEnvironments(project?.id);

  const getEnvironmentName = () => {
    if (isLoadingTicket) {
      return "-";
    }
    if (ticket?.environment) {
      return ticket.environment;
    }

    if (ticket?.description && environments) {
      const lastLine = ticket.description.split("\n").pop();
      const environmentIds = Object.keys(environments);
      return environmentIds.includes(lastLine) ? lastLine : "-";
    }

    return "-";
  };

  const isClosedOrSolved = ["solved", "closed"].includes(ticket?.status);
  const isReopenVisible = isClosedOrSolved && currentUserIsSupport;
  const isCreateFollowUpVisible = ticket?.status === "closed";
  const isSolveVisible = !isClosedOrSolved;
  const environmentName = getEnvironmentName();
  const ticketDescription =
    environmentName === "-"
      ? ticket?.description
      : ticket?.description?.split("\n").slice(0, -1).join("\n");

  return (
    <S.TicketLayout>
      <S.Actions>
        <BackLink to={`/-/users/${currentUserUsername}/tickets`} />

        {!error && !isLoadingTicket && (
          <S.Buttons>
            {isCreateFollowUpVisible && (
              <Link
                to={`/-/users/${currentUserUsername}/tickets/open?followup=${ticket?.ticket_id}&subscription=${ticket?.subscription_id}`}
                variant="primary"
              >
                <FormattedMessage id="tickets.view.followup" />
              </Link>
            )}

            {isReopenVisible && (
              <Button
                onClick={() =>
                  dispatch(updateTicketStatus(ticket?.ticket_id, "open"))
                }
              >
                <FormattedMessage id="tickets.view.reopen" />
              </Button>
            )}

            {isSolveVisible && (
              <Button
                onClick={() =>
                  dispatch(updateTicketStatus(ticket?.ticket_id, "solved"))
                }
              >
                <FormattedMessage id="tickets.view.solve" />
              </Button>
            )}
          </S.Buttons>
        )}
      </S.Actions>

      {error && <LargeInlineError>{error.message}</LargeInlineError>}

      {!error && (
        <S.MainContent>
          {isClosedOrSolved && (
            <Sticker
              priority="low"
              text={
                <FormattedMessage
                  id="tickets.view.markedas"
                  values={{
                    // eslint-disable-next-line react/display-name, react/no-multi-comp
                    status: () => <Status plain status={ticket?.status} />,
                    date: moment(ticket?.updated).format("MMMM D")
                  }}
                />
              }
            />
          )}
          <Description
            subject={ticket?.subject}
            description={ticketDescription}
            date={ticket?.created}
            attachments={ticket?.attachments}
            userId={ticket?.requester_id}
          />
          <S.Spacer />
          <AddComment onSubmit={onSubmit} />

          <Comments ticketId={ticketId} />
        </S.MainContent>
      )}

      {!error && (
        <S.Sidebar>
          <TicketInfo
            ticketId={ticket?.ticket_id}
            status={ticket?.status}
            projectId={project?.id}
            environment={environmentName}
            region={region}
            priority={ticket?.priority}
            url={ticket?.affected_url}
          />

          <S.Spacer />
          <AllAttachments ticketId={ticketId} />
        </S.Sidebar>
      )}
    </S.TicketLayout>
  );
};

export default TicketView;
