import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";

import ContentLayout from "Components/ContentLayout";
import Loading from "Components/Loading";
import Layout from "../../components/Layout";

import EmptyVouchers from "../components/EmptyVouchers";
import VouchersList from "../components/VouchersList";

import { organizationByDescriptionIdSelector } from "Reducers/organization";
import {
  getVouchers,
  vouchersSelector,
  vouchersLoadingSelector
} from "Reducers/voucher";

const List = () => {
  const dispatch = useDispatch();
  const { organizationId } = useDecodedParams();

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const vouchers = useSelector(state =>
    vouchersSelector(state, { organizationId })
  );
  const loading = useSelector(state =>
    vouchersLoadingSelector(state, { organizationId })
  );

  useEffect(() => {
    if (organization) {
      dispatch(getVouchers({ organization }));
    }
  }, [organization]);
  const hasVouchers = useMemo(() => vouchers?.length > 0, [vouchers]);

  return (
    <Layout>
      <ContentLayout>
        {loading !== false ? (
          <Loading />
        ) : (
          <>
            {hasVouchers ? (
              <VouchersList vouchers={vouchers} />
            ) : (
              <EmptyVouchers />
            )}
          </>
        )}
      </ContentLayout>
    </Layout>
  );
};

export default withReducers({
  voucher: () => import("Reducers/voucher")
})(List);
